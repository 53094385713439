<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 bgcolor-1">
      
      <div class="ns-check msp-multi-check mb20 w50"> 
        <mo-checkbox v-model="showPension" class="ls--15" :disabled="isBtnYn" @input="fn_wlifeAnutCnvr(true)">종신보험연금전환</mo-checkbox>
        <!-- <mo-checkbox v-model="showPension1" class="ls--15" :disabled="isBtnYn" @input="fn_wlifeAnutCnvr(false)">생애설계자금전환</mo-checkbox> -->
      </div>

      <div class="calculate-expand w100">
        <div class="fexTy3 ">
          <span class="fs16rem">65세 예상연금액</span>
          <span class="fs16rem crTy-blue4 underline" @click="open1">나의 노후지수는?</span>
        </div>
        <span class="dsD fs26rem fwb">월<span class="fs30rem ml6 mr2"> {{ $commonUtil.numberWithCommas(allTot) }}</span>만원</span>


        <!-- status_value01~06 까지의 합은 100% 이여야 합니다. 소숫점때문에 100%가 넘어가거나 모질라면 틀어질것입니다. -->
        <!--데이터있음-->
        <div v-if="allTot !== 0" class="calculate-chart">
          <div class="chart-box status-box">
            <div class="bar fexTy5">
              <div v-if="tcompPercent !== '0%'" class="status-con01" :style="{ width: tcompPercent }"/>
              <div v-if="gtpNpensPercent !== '0%'" class="status-con04" :style="{ width: gtpNpensPercent }" />
              <div v-if="exptPpsnPercent !== '0%'" class="status-con05" :style="{ width: exptPpsnPercent }" />
              <div v-if="otcompPercent !== '0%'" class="status-con06" :style="{ width: otcompPercent }" />
            </div>
          </div>

          <span class="dsD fs18rem fwm mb6">당사연금</span>
          <div class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt01"></span>연금보험</span>
            <span class="fs14rem fwm">{{tcompPercent}}<span class="dsInline min90 txt-right">{{ $commonUtil.numberWithCommas(tcompTot) }} 만원</span></span>
          </div>
          <div v-if="false" class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt02"></span>종신보험연금전환</span>
            <span class="fs14rem fwm">{{status_value02}}<span class="dsInline min90 txt-right">200 만원</span></span>
          </div>
          <div v-if="false" class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt03"></span>생애설계자금전환</span>
            <span class="fs14rem fwm">{{status_value03}}<span class="dsInline min90 txt-right">200 만원</span></span>
          </div>

          <span v-if="gtpNpensTot > 0 || exptPpsnTot > 0 || otcompTot > 0" class="dsD fs18rem fwm mt20 mb6">연금/타사</span>
          <div v-if="gtpNpensTot > 0" class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt04"></span>국민연금</span>
            <span class="fs14rem fwm">{{gtpNpensPercent}}<span class="dsInline min90 txt-right">{{ gtpNpensTot }} 만원</span></span>
          </div>
          <div v-if="exptPpsnTot > 0" class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt05"></span>퇴직연금</span>
            <span class="fs14rem fwm">{{exptPpsnPercent}}<span class="dsInline min90 txt-right">{{ exptPpsnTot }} 만원</span></span>
          </div>
          <div v-if="otcompTot > 0" class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt06"></span>(타사)개인연금</span>
            <span class="fs14rem fwm">{{otcompPercent}}<span class="dsInline min90 txt-right">{{ otcompTot }} 만원</span></span>
          </div>
          
        </div>
        <!--데이터 없음-->
        <div v-else class="calculate-chart">
          <span class="dsD fs18rem fwm mb6">당사연금</span>
          <div class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt01"></span>연금보험</span>
            <span class="fs14rem fwm">0 %<span class="dsInline min90 txt-right">0 만원</span></span>
          </div>
        </div>

      </div>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-s mt30 bgcolor-1">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_openMSPCT305P" :disabled="isBtnYn">다른연금추가</mo-button> <!-- disabled -->
        </div>
      </ur-box-container>
    </ur-box-container>
    <!-- 나의 노후지수 안내 -->
    <mo-bottom-sheet ref="MSPCT304D_B01" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        나의 노후지수
      </template>
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
          <div class="w100 fexTy3">
            <div class="bgcolor-8 bd-radius-10 mr5 pt20 pb20 txt-center w100">

              <div class="fexTy1">
                <span class="fs18rem fwm mr4">평균가계지출</span>
                <mo-icon id="location1" icon="msp-tool-tip" class="bgwhite"></mo-icon>
                <mo-tooltip target-id="location1" class="tool-tip-box">
                  노후 생활 소요비용 : 65세 이상 2인 부부기준
                  <br>※ 통계출처: 통계청 
                  <br>(마이크로데이터 가계동향조사, 2021)
                </mo-tooltip>                  
              </div>

              <span class="dsD fs22rem fwb crTy-orange2 mh57px">{{ $commonUtil.numberWithCommas(averagePension) }} 만원</span>
            </div>
            <div class="bgcolor-9 bd-radius-10 ml5 pt18 pb18 txt-center w100">
              <span class="dsD fs18rem fwm">나의노후예상금액</span>
              <span class="dsD fs22rem fwb crTy-green1 mh57px">   <!--퍼블 mh57px 추가 -->
                <span class="dsD">{{ $commonUtil.numberWithCommas(allTot) }} 만원</span>
                <span class="dsD fs16rem fexTy1">
                  <span :class="allTot - 216 > 0 ? 'crTy-blue3' : 'crTy-orange2'">{{ $commonUtil.numberWithCommas(Math.abs(allTot - 216)) }} 만원</span>  <!--퍼블 crTy-orange2 ---> 
                  <mo-icon :icon="allTot - 216 > 0 ? 'msp-arrow-increase' : 'msp-arrow-decrease'" />  <!--퍼블 msp-arrow-decrease --->
                </span>
              </span>
            </div>
          </div>

          <div class="chart-area mt20">
            <div class="chart-bgimg">
              <img src="@/assets/img/consulting/chart_sample.png" class="w100"/>
              <div class="chart-bgbar">
                <li class="orange-dashed" :style="{width: averagePensionCalc}"></li> <!-- 퍼블 216 / 750 * 100 -->
                <li class="green-solid" :style="{width: myPensionCalc}"></li> <!-- 퍼블 1,239 / 750 * 100  100 초과시 100까지만 -->
              </div>
            </div>
            <span class="dsD fs14rem crTy-bk7 w100 mt5 txt-right">단위 : 만원</span>
          </div>
          
          <ul class="terms-list-area crTy-bk7 fs14rem mt20">
            <li>본 화면은 분석 시점의 삼성생명 계약 중 보험료를 정상적으로 납입하신 계약과 보험료의 납입을 연체한 계약 중 해지되지 않은 계약을 대상으로 합니다. </li>
          </ul>
        </ur-box-container> 
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="close1" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import UrBoxContainer from '~/src/_uidev/containers/box/urBoxContainer.vue'
  import MSPCT305P from '@/ui/ct/MSPCT305P'
  import ctConstants from '@/config/constants/ctConstants'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {UrBoxContainer},
    props: {
      pPensData: {
        type: Object,
        default: {}
      },
      pBtnDisable: {
        type: Boolean,
        default: true
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      console.log('노후지수 시작!!!')
      this.pensData = this.pPensData
      this.isBtnYn = this.pBtnDisable
      this.fn_CalPensData()
    },
    mounted() {
      let wlifeAnutCnvr = this.getStore('ctStore').getters.getWlifeAnutCnvr
      console.log('종신보험 연금전환 여부>>', wlifeAnutCnvr)
      if (wlifeAnutCnvr.showPension || wlifeAnutCnvr.showPension1) {
        if (wlifeAnutCnvr.showPension) this.showPension = true
        if (wlifeAnutCnvr.showPension1) this.showPension1 = true
        this.wlifeAnutCnvrData = wlifeAnutCnvr.wlifeAnutCnvrList
        this.fn_wlifeAnutCnvr()
      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        showPension : false,
        showPension1 : false,
        tcompPercent : '',
        gtpNpensPercent : '',
        exptPpsnPercent : '',
        otcompPercent : '',

        contData: [],
        pensData: {},
        isMSPCT305P: {},
        averagePension: 216, // 평균 가계지출금액
        wlifeAnutCnvrData: [],
        isBtnYn: true,
        tcompTot: 0,
        otcompTot: 0,
        gtpNpensTot: 0,
        exptPpsnTot: 0,
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      lv_BaseData: function () { // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
      allTot() {
        return this.tcompTot + this.otcompTot + this.gtpNpensTot + this.exptPpsnTot
      },
      myPensionCalc() { // 나의 노후 예상금액
        let maxWidth = 623
        let maxPensionValue = 750
        let myPensionValue = this.allTot // 346
        if (myPensionValue > maxPensionValue) myPensionValue = maxPensionValue
        if (myPensionValue < 0) myPensionValue = 0
        let myGradeWidth = Math.round((myPensionValue / maxPensionValue) * maxWidth) 
        let myGrade = Math.round(myGradeWidth / maxWidth * 100) 
        //let myGrade = Math.round((maxWidth * myPensionValue) / maxPensionValue)
        // 수정, 내 연금액이 평균연금액보다 많으면 초록색 2022.04.19 :chan
        console.log('myGradeWidth>>', myGradeWidth)
        console.log('myGrade>>', myGrade)
        return `${myGrade}%`
      },
      averagePensionCalc () { // 평균 연금액
      let maxWidth = 623
      let maxPensionValue = 750
      let averagePensionValue = this.averagePension
      if (averagePensionValue > maxPensionValue) averagePensionValue = maxPensionValue
      if (averagePensionValue < 0) averagePensionValue = 0
      let averageGradeWidth = Math.round((averagePensionValue / maxPensionValue) * maxWidth) //- 63
      let averageGrade = Math.round((averageGradeWidth / maxWidth) * 100) //- 63
      //let averageGrade = Math.round(averagePensionValue / maxPensionValue * 100) //- 63
      console.log('averageGradeWidth>>>', averageGradeWidth)
      console.log('averageGrade>>>', averageGrade)
      return `${averageGrade}%`
    },
    },
    watch: {
      pPensData() {
        this.pensData = this.pPensData
        this.fn_CalPensData()
        
      },
      pBtnDisable() {
        this.isBtnYn = this.pBtnDisable
      },
      lv_BaseData () {
        this.showPension = false
        this.showPension1 = false
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      open1() { this.$refs.MSPCT304D_B01.open();  },
      close1() { this.$refs.MSPCT304D_B01.close(); },

      fn_CalPensData() {
        this.tcompTot = 0
        this.otcompTot = 0,
        this.gtpNpensTot = 0
        this.exptPpsnTot = 0
        if (this.pensData.tcomAnutList !== undefined) { // 당사
          this.pensData.tcomAnutList.forEach(e => {
            this.tcompTot += e.anpsn
          })
          this.tcompTot = Math.floor(this.tcompTot / 12)
          console.log('당사 월 보험료 합계결과>>', this.tcompTot)
        }
        /* if (this.pensData.otcompList !== undefined) { // 타사
          this.pensData.otcompList.forEach(e => {
            this.otcompTot += e.anpsn
          })
        }
        if (this.pensData.gtpNpens !== undefined) { // 국민
          this.pensData.gtpNpens.forEach(e => {
            this.gtpNpensTot += e.anpsn
          })
        }
        if (this.pensData.exptPpsn !== undefined) { // 퇴직
          this.pensData.exptPpsn.forEach(e => {
            this.exptPpsnTot += e.anpsn
          })
        } */
        if (this.pensData.otherAnutList !== undefined) { // 추가등록
          this.pensData.otherAnutList.forEach(e => {
            if (e.prdtNm === '타사연금') { this.otcompTot += e.anpsn }
            if (e.prdtNm === '국민연금') { this.gtpNpensTot += e.anpsn }
            if (e.prdtNm === '퇴직연금') { this.exptPpsnTot += e.anpsn }
          })
        }
        this.otcompTot = Math.floor(this.otcompTot / 12)
        this.gtpNpensTot = Math.floor(this.gtpNpensTot / 12)
        this.exptPpsnTot = Math.floor(this.exptPpsnTot / 12)
        console.log('타사 월 보험료 합계결과>>', this.otcompTot)
        console.log('국민 월 보험료 합계결과>>', this.gtpNpensTot)
        console.log('퇴직 월 보험료 합계결과>>', this.exptPpsnTot)
        this.fn_calPercent()
      },

      fn_calPercent() {
        this.tcompPercent = `${Math.round((this.tcompTot / this.allTot) * 100)}%`
        this.gtpNpensPercent = `${Math.round((this.gtpNpensTot / this.allTot) * 100)}%`
        this.exptPpsnPercent = `${Math.round((this.exptPpsnTot / this.allTot) * 100)}%`
        this.otcompPercent = `${Math.round((this.otcompTot / this.allTot) * 100)}%`
        console.log('퍼센트 계산결과!!!!!!', this.tcompPercent, this.gtpNpensPercent, this.exptPpsnPercent, this.otcompPercent)
        //this.fn_makeChartData()
      },
      
       /******************************************************************************
      * Function명  : fn_wlifeAnutCnvr
      * params     : flag :: 버튼구분(true : 종신, false: 생애)
      * 설명        : 종신보험 연금전환 버튼 클릭
      ******************************************************************************/
      fn_wlifeAnutCnvr: function (flag) {
        let lv_Vm = this
        let wlifeAnutCnvrList = this.getStore('ctStore').getters.getWlifeAnutCnvr.data
        console.log('getWlifeAnutCnvr.data1>>>', this.getStore('ctStore').getters.getWlifeAnutCnvr.data)
        if (flag) {
          if (lv_Vm.showPension) {
            lv_Vm.showPension1 = true
          } else {
            lv_Vm.showPension1 = false
          }
        }
        if (wlifeAnutCnvrList === undefined || Object.keys(wlifeAnutCnvrList).length === 0) {
          console.log('초기초기초기>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
          // 화면 로드시 연금데이터 서비스 호출
          this.wlifeAnutCnvrData = []
          lv_Vm.contData = lv_Vm.getStore('ctStore').getters.getContData.data // 보장분석 기계약 vuex 데이터 로드
          let reqParam = {
            inqrScCd: '1', // (조회구분 1 연금계약리스트  2 연금전환 예시
            chnlCustId: lv_Vm.lv_BaseData.data.chnlCustId,
            custNm: lv_Vm.lv_BaseData.data.custNm
          }
          this.$bizUtil.ctUtils.fn_wlifeAnutCnvr(lv_Vm, reqParam).then(function (response) {
            console.log('종신보험 연금전환 리스트 호출 결과', response)
            let tr_wlifeAnutCnvrArr = []
            // 연금전환 예시를 위한 조건검사와 Promise배열을 획득한다.
            let wlifeAncvContVOList
            let rsltCd = -1

            if ( !lv_Vm.$bizUtil.isEmpty(response.body) ) {
              wlifeAncvContVOList = response.body.wlifeAncvContVO
              rsltCd = Number(response.body.rsltCd)
            }
            console.log('contData>>>>', lv_Vm.contData)
            if ( wlifeAncvContVOList !== undefined && rsltCd === 0 ) {
              for ( let wlifeAncvContIdx in wlifeAncvContVOList ) {
                let tcomAnutList = lv_Vm.contData.filter( x => { return x.contNo === wlifeAncvContVOList[wlifeAncvContIdx].contNo })
                console.log('tcomAnutList1', tcomAnutList)
                // 조회된 계약 정보가 당사기계약 (계/피동일) 목록에 있는지 체크 해서 유효한 계약번호만 연금전환 예시정보를 조회한다.
                if ( tcomAnutList.length > 0 ) {
                  console.log('tcomAnutList2', tcomAnutList)
                  reqParam = {
                    inqrScCd: '2', // (조회구분 1 연금계약리스트  2 연금전환 예시
                    chnlCustId: lv_Vm.lv_BaseData.data.chnlCustId,
                    custNm: lv_Vm.lv_BaseData.data.custNm,
                    contNo: wlifeAncvContVOList[wlifeAncvContIdx].contNo, // 연금전환 계약리스트 계약번호
                    ancvAge: '65' // 연금전환연령
                  }
                  tr_wlifeAnutCnvrArr.push(lv_Vm.$bizUtil.ctUtils.fn_wlifeAnutCnvr(lv_Vm, reqParam))
                }
              }
            }
            Promise.all(tr_wlifeAnutCnvrArr).then(function (response) {
              console.log('종신보험 response>>', response)
              lv_Vm.fn_SetVuexWlifeAnutCnvr('', '', response, '') // 종신보험 전환가능 데이터 스토어 저장
              // 계약번호가 정상적으로 조회되면 (유효 계약번호 조회)
              lv_Vm.fn_wlifeAnutCnvrToTcompLayout(true, response)
              })
              .catch(function (error) {
                window.vue.error(error)
              })
          }).catch(function (error) {
            window.vue.error(error)
          })
        } else {
          console.log('기존기존기존>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.getStore('ctStore').getters.getWlifeAnutCnvr.wlifeAnutCnvrList)
          lv_Vm.contData = lv_Vm.getStore('ctStore').getters.getContData.data
          console.log('fn_wlifeAnutCnvrToTcompLayout 종신 스토어 저장 확인!!', this.getStore('ctStore').getters.getWlifeAnutCnvr)
          // 기존에 조회했던 종신보험연금전환 데이터를 세팅
          this.fn_wlifeAnutCnvrToTcompLayout(false, this.getStore('ctStore').getters.getWlifeAnutCnvr.data)
        }
      },

      /************************************************************************************************
      * Function명 : fn_SetVuexWlifeAnutCnvr
      * params     : pShowPension :: 종신보험연금전환 버튼 클릭여부
      *              pShowPension1 :: 생애설계자금전환 클릭여부
      *              resp :: 종신보험연금전환 IF 응답값(원본 그대로)
      *              wlifeAnutCnvrList :: 032D 화면에서 사용될 형태로 가공 된 종신보험 연금전환 데이터
      * 설명       : 종신보험 연금전환 vuex 데이터 세팅
      ************************************************************************************************/
      fn_SetVuexWlifeAnutCnvr (pShowPension, pShowPension1, resp, wlifeAnutCnvrList) {
        if (resp === '') {
          resp = this.getStore('ctStore').getters.getWlifeAnutCnvr.data
        }
        /* if (pShowPension === false) {
          pShowPension = this.getStore('ctStore').getters.getWlifeAnutCnvr.showPension
        } */
        if (wlifeAnutCnvrList === '') {
          wlifeAnutCnvrList = this.getStore('ctStore').getters.getWlifeAnutCnvr.wlifeAnutCnvrList
        }
        this.getStore('ctStore').dispatch('WLIFEANUTCNVRREG', {showPension: pShowPension, showPension1: pShowPension1, data: resp, wlifeAnutCnvrList: wlifeAnutCnvrList})
        console.log('fn_SetVuexWlifeAnutCnvr 종신 스토어 저장 확인!!', this.getStore('ctStore').getters.getWlifeAnutCnvr)
      },

      /************************************************************************************************
      * Function명 : fn_wlifeAnutCnvrToTcompLayout
      * 설명       : 종신보험 연금전환 데이터를 당사 데이터 형태로 변환
      ************************************************************************************************/
      fn_wlifeAnutCnvrToTcompLayout (isFirst, response) {
        this.wlifeAnutCnvrData = [] // 초기화
        console.log('fn_wlifeAnutCnvrToTcompLayout>>>>>>>>>>1', response)
        let acnvContInfoList = response
        for ( let inxResp in acnvContInfoList ) {
          let ageAnpsnVO = acnvContInfoList[inxResp].body.anutIlstInfoVO.ageAnpsnVO
          let contNo = acnvContInfoList[inxResp].body.contNo
          console.log('this.contData>>>', this.contData)
          let tcomAnutList = this.contData.filter( x => { return x.contNo === contNo })
          console.log('fn_wlifeAnutCnvrToTcompLayout>>>>>>>>>>2', tcomAnutList)
          // 65세 연금액 정보를 가져온다.
          if ( ageAnpsnVO != null && ageAnpsnVO.length > 0 &&
            tcomAnutList != null && tcomAnutList.length > 0 ) {
            // debugger
            let wlifeAcnvContInfo = {}
            // let lv_anpsn = {}
            let entireAgeAnpsnVO = ageAnpsnVO
            ageAnpsnVO = ageAnpsnVO.filter( x => { return x.age === 65 })
            wlifeAcnvContInfo.contNo = contNo // 계약번호
            wlifeAcnvContInfo.insrCoNm = '삼성생명 (연금전환)' // 가입회사
            wlifeAcnvContInfo.prdtNm = tcomAnutList[0].prdtNm // 보험명
            wlifeAcnvContInfo.contYmd = tcomAnutList[0].contYmd // 계약년월
            wlifeAcnvContInfo.rcvPrd = ctConstants.anutRcvPrd + ' 년' // 당사 수령기간은 20년 고정형
            wlifeAcnvContInfo.smtotPrm = tcomAnutList[0].smtotPrm // 보험료
            wlifeAcnvContInfo.alpadTotPrm = tcomAnutList[0].alpadTotPrm // 기납입총보험료
            // 월납입 보험료 계산을 위해 '납입주기', '향후납입보험료' 항목 추가
            wlifeAcnvContInfo.padCylCd = tcomAnutList[0].padCylCd // 납입주기
            wlifeAcnvContInfo.padParngTotPrm = tcomAnutList[0].padParngTotPrm // 향후납입보험료
            wlifeAcnvContInfo.anpsn = ageAnpsnVO[0].anpsn01 // 연금연액
            wlifeAcnvContInfo.anpsn01 = ageAnpsnVO[0].anpsn12 // 연금월액
            wlifeAcnvContInfo.wlifeAnutCnvrYN = 'Y' // 종신보험연금전환 여부
            wlifeAcnvContInfo.entireAgeAnpsnVO = entireAgeAnpsnVO // 20년 동안 매년 연금 정보
            wlifeAcnvContInfo.prpslStatCd = '01'
            wlifeAcnvContInfo.tcomYn = true
            wlifeAcnvContInfo.anutBgnMnisdAge = 65
            wlifeAcnvContInfo.anpsnStartAmount = ageAnpsnVO[0].anpsn01
            this.wlifeAnutCnvrData.push(wlifeAcnvContInfo)
          }
        }
        console.log('뭐야!!!!!', this.wlifeAnutCnvrData)
        // 종신보험 연금전환 상품 목록셋팅
        this.fn_wlifeAnutCnvrViewSet()
      },

      /******************************************************************************
      * Function명  : fn_wlifeAnutCnvrViewSet
      * 설명        : 종신보험 연금전환 상품 목록셋팅
      ******************************************************************************/
      fn_wlifeAnutCnvrViewSet () {
        let tcompAcontList = this.getStore('ctStore').getters.getTcomAnut.data
        tcompAcontList = tcompAcontList === undefined ? [] : tcompAcontList
        console.log('this.wlifeAnutCnvrData>>>', this.wlifeAnutCnvrData)
        console.log('종신보험 플래그 체크>>>', this.showPension)
        console.log('생애설계 플래그 체크>>>', this.showPension1)
        if ( this.wlifeAnutCnvrData.length > 0 ) { // 종신보험 연금전환 데이터가 존재하면
          if (this.showPension && this.showPension1) { // 종신보험 연금전환 선택 시
            console.log('종신 true / 생애 true', tcompAcontList)
            for (let idx in this.wlifeAnutCnvrData) {
              let tcompAcont = this.wlifeAnutCnvrData[idx]
              let pensIdx = tcompAcontList.findIndex(item => { return item.contNo === tcompAcont.contNo })
              if ( pensIdx < 0 ) {
                tcompAcontList.push(tcompAcont)
              }
            }
            this.fn_SetVuexWlifeAnutCnvr(true, true, '', tcompAcontList)
          } else if (!this.showPension && this.showPension1) { // 생애설계만 선택시
            console.log('종신 false / 생애 true', tcompAcontList)
            for (let idx in this.wlifeAnutCnvrData) {
              let tcompAcont = this.wlifeAnutCnvrData[idx]
              let tcompAcontListCnt = tcompAcontList.filter(e => e.prdtNm.indexOf('생애설계') > -1)
              console.log('tcompAcontCnt>>', tcompAcontListCnt)
              console.log('tcompAcontCnt.length>>', tcompAcontListCnt.length)
              if (tcompAcont.prdtNm.indexOf('생애설계') > -1 && tcompAcontListCnt.length === 0) {
                  tcompAcontList.push(tcompAcont)
              }
            }
            this.fn_SetVuexWlifeAnutCnvr(false, true,'', tcompAcontList)
          } else if (this.showPension && !this.showPension1) { // 종신보험 전환 선택 후 생애설계 해제
            console.log('종신 true 생애 false>>', tcompAcontList)
            for (let idx in this.wlifeAnutCnvrData) {
              let tcompAcont = this.wlifeAnutCnvrData[idx]
              let pensIdx = tcompAcontList.findIndex((item) => { return item.contNo === tcompAcont.contNo })
              if (tcompAcontList[idx] && tcompAcontList[idx].prdtNm.indexOf('생애설계') > -1) {
                console.log('생애설계 pensIdx>>', pensIdx)
                tcompAcontList.splice(pensIdx, 1)
              }
            }
            this.fn_SetVuexWlifeAnutCnvr(true, false, '', tcompAcontList)
          } else { // 종신보험 연금전환 해제 시
            console.log('종신 false / 생애 false', tcompAcontList)
            for (let idx in this.wlifeAnutCnvrData) {
              let tcompAcont = this.wlifeAnutCnvrData[idx]
              let pensIdx = tcompAcontList.findIndex((item) => { return item.contNo === tcompAcont.contNo })
              if ( pensIdx > -1 ) {
                tcompAcontList.splice(pensIdx, 1)
              }
            }
            this.fn_SetVuexWlifeAnutCnvr(false, false, '', tcompAcontList)
          }
        }
        console.log('최종 확인!!', this.getStore('ctStore').getters.getWlifeAnutCnvr)
        console.log('최종 확인1!!', tcompAcontList)
        this.getStore('ctStore').dispatch('TCOMANUTREG', {'data': tcompAcontList})
        this.$emit('pensSet')
      },

      /************************************************************************************************
      * Function명 : fn_openMSPCT305P
      * 설명       : 다른연금 추가 버튼 클릭
      ************************************************************************************************/
      fn_openMSPCT305P() {
        console.log('다른연금 추가')
        this.isMSPCT305P = this.$bottomModal.open(MSPCT305P, {
            properties: {
            },
            listeners: {
              confirmMSPCT305P: (param) => {
                this.$bottomModal.close(this.isMSPCT305P)
                this.$emit('reLoad', param)
              },
              closeMSPCT305P: () => {
                console.log('팝업닫기!!!')
                this.$bottomModal.close(this.isMSPCT305P);
              },
            }
          },
          {
            properties: {
              noHeader: true
            }
          })
      },
    }
  };
</script>
<style scoped>
</style>