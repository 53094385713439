<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 bgcolor-1">

      <div class="ns-check msp-multi-check mb20 w50"> 
        <mo-checkbox v-model="showPension" @input="fn_wlifeAnutCnvr(true)" class="ls--15" :disabled="isBtnYn">종신보험연금전환</mo-checkbox>
        <!-- <mo-checkbox v-model="showPension1" @input="fn_wlifeAnutCnvr(false)" class="ls--15" :disabled="isBtnYn">생애설계자금전환</mo-checkbox> -->
      </div>
      
      <!--당사연금-->
      <template v-if="pensData.tcomAnutList && pensData.tcomAnutList.length > 0">
        <ur-box-container v-for="(list, idx) in pensData.tcomAnutList" :key="`${list.prdtNm}`+ idx" alignV="start" componentid="" direction="column">        
          <msp-expand btn-area-first expanded class="mo-list-expand calculate-expand mb20" btn-area-class="" :class="{'nodata' : list.name === 'nodata'}"> 
            <template #btn>
              <div class="fexTy3">
                <div class="fexTy5">
                  <span class="dsD fs18rem fwm">당사연금</span>
                  <span class="ns-tool-tip ml10" @click.stop="open2(list.prdtNm)">
                    <mo-icon id="location0" icon="msp-tool-tip"></mo-icon>                  
                  </span>
                </div>
                <mo-button class="link-arrow down"></mo-button>
              </div>
              <div class="w100 subdata">
                <div class="fs19rem fwb mt10 txtSkip2" :class="{'dsN' : list.prdtNm === ''}">{{list.prdtNm}}</div>
                <div class="mt10">
                  <div class="fs16rem mb4" :class="{'dsN' : list.contYmd === ''}"><span class="dsInline fs14rem crTy-bk7 min90">계약년월</span>{{ replaceDayText(list.contYmd) }}</div>
                  <div class="fs16rem mb4" :class="{'dsN' : list.rcvPrd === ''}"><span class="dsInline fs14rem crTy-bk7 min90">수령기간</span>{{list.rcvPrd}}</div>
                  <div class="fs16rem mb4" :class="{'dsN' : list.smtotPrm.longAmount === ''}"><span class="dsInline fs14rem crTy-bk7 min90">보험료</span>{{ `${$commonUtil.numberWithCommas(list.smtotPrm.longAmount)} 원` }}</div>
                  <div class="fs16rem"     :class="{'dsN' : list.anpsn === ''}"><span class="dsInline fs14rem crTy-bk7 min90">연 예상연금</span>{{ `${$commonUtil.numberWithCommas(list.anpsn)} 만원` }}</div>
                </div>
              </div>
            </template>
            <template #content>
              <div class="mt10">
                <span class="dsD fs14rem crTy-bk7 w100 mb4 txt-right">단위 : 만원</span>
                <!-- 당사연금 -->
                <div class="calculate-chart fexTy5">
                  <div v-for="(data, j) in ageList" :key="j" class="chart-box" :class="{'start' : list.startGraphIdx === j, 'end' : list.endGraphIdx === j, 'on' : (list.endGraphIdx >= j ) && list.startGraphIdx <= j}"><!-- on 은 파란색 bar, start/end 는 radius -->
                    <span class="dsD count"><span v-if="list.startGraphIdx === j">{{ $commonUtil.numberWithCommas(Math.floor(list.anpsn / 12)) }}</span></span>
                      <div class="bar"/>
                      <span class="dsD txt">{{ data }}</span>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </ur-box-container>
      </template>
      <!-- 데이터가 없을 경우 list.name === 'nodata' --> 
      <ur-box-container v-else-if="Object.keys(pensData).length === 0 || (Object.keys(pensData).length > 0 && (pensData.tcomAnutList.length + pensData.otcomAnutList.length + pensData.gtpNpens.length + pensData.exptPpsn.length + pensData.otherAnutList.length) === 0)" alignV="start" componentid="" direction="column" class="no-data">
        <div class="fexTy3">
          <div class="fexTy5">
            <span class="dsD fs18rem fwm">당사연금</span>
          </div>
        </div>
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info hauto">  <!-- 영역이 작은곳에서 활용 small-space -->
              <span class="ns-ftcr-gray mt0 mb10">가입된 연금상품이 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <!--다른연금 추가 버튼 통한 연금-->
      <template v-if="pensData.otherAnutList">
        <ur-box-container v-for="(list, idx) in pensData.otherAnutList" :key="`${list.prdtNm}`+ idx" alignV="start" componentid="" direction="column">        
          <msp-expand btn-area-first expanded class="mo-list-expand calculate-expand mb20" btn-area-class="" :class="{'nodata' : list.name === 'nodata'}"> 
            <template #btn>
              <div class="fexTy3">
                <div class="fexTy5">
                  <span class="dsD fs18rem fwm">{{list.prdtNm}}</span>
                </div>
                <mo-button class="link-arrow down"></mo-button>
              </div>
              <div class="w100 subdata">
                <!-- <div class="fs19rem fwb mt10 txtSkip2" :class="{'dsN' : list.prdtNm === ''}">{{list.prdtNm}}</div> -->
                <div class="mt10">
                  <div class="fs16rem mb4" :class="{'dsN' : list.contYmd === ''}"><span class="dsInline fs14rem crTy-bk7 min90">계약년월</span>{{ list.contYmd === '0' ? '-' : list.contYmd }}</div>
                  <div class="fs16rem mb4" :class="{'dsN' : list.rcvPrd === ''}"><span class="dsInline fs14rem crTy-bk7 min90">수령기간</span>{{ list.rcvPrd }}</div>
                  <div class="fs16rem mb4" :class="{'dsN' : list.smtotPrm === ''}"><span class="dsInline fs14rem crTy-bk7 min90">보험료</span>{{ list.smtotPrm === 0 ? '-' : `${$commonUtil.numberWithCommas(list.smtotPrm)} 원` }}</div>
                  <div class="fs16rem"     :class="{'dsN' : list.anpsn === ''}"><span class="dsInline fs14rem crTy-bk7 min90">연예상연금</span>{{ `${$commonUtil.numberWithCommas(list.anpsn)} 만원` }}</div>
                </div>
              </div>
            </template>
            <template #content>
              <div class="mt10">
                <span class="dsD fs14rem crTy-bk7 w100 mb4 txt-right">단위 : 만원</span>
                <div class="calculate-chart fexTy5" :class="{'type01_step' : list.chartType === '1'}">
                  <template v-if="list.chartType === '1'">
                    <div v-for="(data, j) in ageList" :key="j" class="chart-box" :class="list.cssIdx[j] === 0 ? 'step01' : list.cssIdx[j] === 1 ? 'step02' : list.cssIdx[j] === 2 ? 'step03' : list.cssIdx[j] === 3 ? 'step04' : list.cssIdx[j] === 4 ? 'step05' : list.cssIdx[j] === 5 ? 'step06' : ''">
                      <span class="dsD count">
                        <span>
                          {{ list.anpsnList[j] !== 0 ? $commonUtil.numberWithCommas(Math.floor(list.anpsnList[j] / 120000)) : '' }}
                        </span>
                      </span>
                        <div class="bar"/>
                        <span class="dsD txt">{{ data }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div v-for="(data, j) in ageList" :key="j" class="chart-box" :class="{'start' : list.startGraphIdx === j, 'end' : list.endGraphIdx === j, 'on' : (list.endGraphIdx >= j ) && list.startGraphIdx <= j}">
                      <span class="dsD count">
                        <span v-if="list.startGraphIdx === j" >
                          <template v-if="list.prdtNm === '퇴직연금'">
                            {{ $commonUtil.numberWithCommas(Math.floor(list.anpsnList[j] / 120000)) }}
                          </template>
                          <template v-else-if="list.prdtNm === '타사연금'">
                            {{ $commonUtil.numberWithCommas(Math.floor(list.anpsn / 12)) }}
                          </template>
                        </span>
                      </span>
                        <div class="bar"/>
                        <span class="dsD txt">{{ data }}</span>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </msp-expand>
        </ur-box-container>
      </template>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-s mt30 bgcolor-1">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_openMSPCT305P" :disabled="isBtnYn">다른연금추가</mo-button> <!-- disabled -->
        </div>
      </ur-box-container>
    </ur-box-container>

    <!-- 퇴직연금 안내 -->
    <mo-bottom-sheet ref="MSPCT302D_B01" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        퇴직연금
      </template>
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
          <ul class="terms-list-area fs16rem">
            <li>현재적립금을 기준으로 만 55세부터 20년간 수령하는 것으로 가정하여 산출한 금액입니다.</li>
            <li>고객님께서 만 55세 이상인 경우, 현재 연령부터 20년간 수령하는 것으로 가정하여 산출하였습니다.</li>
            <li>예상수익률 : 1.95% 적용</li>
          </ul>
          <span class="dsD mt20 fs16rem indent20 crTy-bk7">
            ※ 혹시 퇴직연금 중 연금개시건이 있으시다면, 연금지급주기(월,3개월,6개월,연 등)와 관계없이, 연간 연금액 기준으로 조회됩니다.
          </span>
        </ur-box-container> 
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="close1" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 개인 안내 -->
    <mo-bottom-sheet ref="MSPCT302D_B02_1" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        당사연금
      </template>
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
          <ul class="terms-list-area fs16rem">
            <li>고객님께서 가입시 설정한 연금개시연령 및 지급유형으로 가정하여 산출한 금액입니다</li>
          </ul>
          <span class="dsD mt20 fs16rem indent20 crTy-bk7">
            ※ 장수축하금과 같이 특정연령에만 지급되는 연금은 제외한 기본연금액만 산출한 내용으로, 자세한 내용은 해당 상품의 약관을 참조하시기 바랍니다.
          </span>
        </ur-box-container> 
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="close2" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <mo-bottom-sheet ref="MSPCT302D_B02_2" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        당사연금
      </template>
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
          <ul class="terms-list-area fs16rem">
            <li>고객님께서 가입시 설정한 연금개시연령 및 지급유형으로 가정하여 산출한 금액입니다</li>
          </ul>
          <span class="dsD mt20 fs16rem indent20 crTy-bk7">
            ※ 장수축하금과 같이 특정연령에만 지급되는 연금은 제외한 기본연금액만 산출한 내용으로, 자세한 내용은 해당 상품의 약관을 참조하시기 바랍니다.
          </span>
          <span class="dsD mt20 fs16rem indent20 crTy-bk7">
            ※ 생애설계자금전환 상품은 생활자금개시나이에 상관없이 수령하는 연예상금액을 반영하고 있습니다
          </span>
        </ur-box-container> 
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="close3" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>
import UrBoxContainer from '~/src/_uidev/containers/box/urBoxContainer.vue'
import MSPCT305P from '@/ui/ct/MSPCT305P'
import ctConstants from '@/config/constants/ctConstants'
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT302D",
    screenId: "MSPCT302D",
    components: {UrBoxContainer},
    props: {
      pPensData: {
        type: Object,
        default: {}
      },
      pBtnDisable: {
        type: Boolean,
        default: true
      },
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      console.log('준비현황 시작!!', this.pPensData)
      this.pensData = this.pPensData
      this.isBtnYn = this.pBtnDisable
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      //this.fn_initPensLoadData()
      // 종신보험연금전환 체크되어 있으면 데이터 출력
      let wlifeAnutCnvr = this.getStore('ctStore').getters.getWlifeAnutCnvr
      console.log('종신보험 연금전환 여부>>', wlifeAnutCnvr)
      if (wlifeAnutCnvr.showPension || wlifeAnutCnvr.showPension1) {
        if (wlifeAnutCnvr.showPension) this.showPension = true
        if (wlifeAnutCnvr.showPension1) this.showPension1 = true
        this.wlifeAnutCnvrData = wlifeAnutCnvr.wlifeAnutCnvrList
        console.log('확인해봅시다~!!!!!', wlifeAnutCnvr.showPension, wlifeAnutCnvr.showPension1, this.wlifeAnutCnvrData)
        this.fn_wlifeAnutCnvr()
        //this.fn_wlifeAnutCnvrViewSet()
      }
      let lv_Vm = this
      // 현재 년도를 현재 Vue객체에 세팅함
      this.$bizUtil.ctUtils.fn_SyncSrvTime(lv_Vm).then(function (resTime) {
      lv_Vm.curYear = resTime.toString().replace('-', '').substr(0, 4)
      })
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isLoading: false,
        firstItem1: '1',
        curYear: '',
        showPension : false,
        showPension1: false,
        value2 : false,
        wlifeAnutCnvrData: [],
        contData: [],
        pensData: {},
        addPInfo: [],
        acontInqrYn: '',
        ageList: [55, 60, 65, 70, 75, 80],
        isMSPCT305P: {},
        isBtnYn: true
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      lv_BaseData: function () { // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
    },
    watch: {
      pPensData () {
        this.pensData = this.pPensData
        console.log('watch pensData>>>', this.pensData)
      },
      pBtnDisable () {
        this.isBtnYn = this.pBtnDisable
        console.log('watch isBtnYn>>>', this.isBtnYn)
      },
      lv_BaseData () {
        this.showPension = false
        this.showPension1 = false
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      open1() { this.$refs.MSPCT302D_B01.open();  },
      close1() { this.$refs.MSPCT302D_B01.close(); },
      open2(item) {
        let flag = false
        console.log('item>>>>', item)
        if (item.indexOf('생애설계') > -1) {
          flag = true
        }
        console.log('flag확인>>>', flag)
        if (flag) {
          //종신
          this.$refs.MSPCT302D_B02_2.open()
        } else {
          this.$refs.MSPCT302D_B02_1.open()
        }
      },
      close2() { this.$refs.MSPCT302D_B02_1.close() },
      close3() { this.$refs.MSPCT302D_B02_2.close() },

      /************************************************************************************************
      * Function명 : replaceNumText
      * 설명       : 금액 포맷팅
      ************************************************************************************************/
      replaceNumText (numberText = '') {
        if (numberText === '0' || numberText === 0) {
          return '-'
        } else {
          return this.$commonUtil.numberWithCommas(numberText) + ' 원'
        }
      },

      /************************************************************************************************
      * Function명 : replaceDayText
      * 설명       : 날짜 포맷팅
      ************************************************************************************************/
      replaceDayText (dateText = '') {
        if (dateText === '0' || dateText === 0) {
          return '-'
        } else {
          return this.$bizUtil.ctUtils.fn_dateYyMmFormat(dateText)
        }
      },

      /************************************************************************************************
      * Function명 : fn_openMSPCT305P
      * 설명       : 다른연금 추가 버튼 클릭
      ************************************************************************************************/
      fn_openMSPCT305P() {
        console.log('다른연금 추가')
        this.isMSPCT305P = this.$bottomModal.open(MSPCT305P, {
            properties: {
            },
            listeners: {
              confirmMSPCT305P: (param) => {
                this.$bottomModal.close(this.isMSPCT305P)
                this.$emit('reLoad', param)
              },
              closeMSPCT305P: () => {
                console.log('팝업닫기!!!')
                this.$bottomModal.close(this.isMSPCT305P);
              },
            }
          },
          {
            properties: {
              noHeader: true
            }
          })
      },

      /******************************************************************************
      * Function명  : fn_wlifeAnutCnvr
      * params     : flag :: 버튼구분(true : 종신, false: 생애)
      * 설명        : 종신보험 연금전환 버튼 클릭
      ******************************************************************************/
      fn_wlifeAnutCnvr: function (flag) {
        let lv_Vm = this
        let wlifeAnutCnvrList = this.getStore('ctStore').getters.getWlifeAnutCnvr.data
        console.log('getWlifeAnutCnvr.data1>>>', this.getStore('ctStore').getters.getWlifeAnutCnvr.data)
        if (flag) {
          if (this.showPension) {
            this.showPension1 = true
          } else {
            this.showPension1 = false
          }
        }
        if (wlifeAnutCnvrList === undefined || Object.keys(wlifeAnutCnvrList).length === 0) {
          console.log('초기초기초기>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
          // 화면 로드시 연금데이터 서비스 호출
          this.wlifeAnutCnvrData = []
          lv_Vm.contData = lv_Vm.getStore('ctStore').getters.getContData.data // 보장분석 기계약 vuex 데이터 로드
          let reqParam = {
            inqrScCd: '1', // (조회구분 1 연금계약리스트  2 연금전환 예시
            chnlCustId: this.lv_BaseData.data.chnlCustId,
            custNm: this.lv_BaseData.data.custNm
          }
          this.$bizUtil.ctUtils.fn_wlifeAnutCnvr(lv_Vm, reqParam).then(function (response) {
            console.log('종신보험 연금전환 리스트 호출 결과', response)
            let tr_wlifeAnutCnvrArr = []
            // 연금전환 예시를 위한 조건검사와 Promise배열을 획득한다.
            let wlifeAncvContVOList
            let rsltCd = -1

            if ( !lv_Vm.$bizUtil.isEmpty(response.body) ) {
              wlifeAncvContVOList = response.body.wlifeAncvContVO
              rsltCd = Number(response.body.rsltCd)
            }
            console.log('contData>>>>', lv_Vm.contData)
            if ( wlifeAncvContVOList !== undefined && rsltCd === 0 ) {
              for ( let wlifeAncvContIdx in wlifeAncvContVOList ) {
                let tcomAnutList = lv_Vm.contData.filter( x => { return x.contNo === wlifeAncvContVOList[wlifeAncvContIdx].contNo })
                console.log('tcomAnutList1', tcomAnutList)
                // 조회된 계약 정보가 당사기계약 (계/피동일) 목록에 있는지 체크 해서 유효한 계약번호만 연금전환 예시정보를 조회한다.
                if ( tcomAnutList.length > 0 ) {
                  console.log('tcomAnutList2', tcomAnutList)
                  reqParam = {
                    inqrScCd: '2', // (조회구분 1 연금계약리스트  2 연금전환 예시
                    chnlCustId: lv_Vm.lv_BaseData.data.chnlCustId,
                    custNm: lv_Vm.lv_BaseData.data.custNm,
                    contNo: wlifeAncvContVOList[wlifeAncvContIdx].contNo, // 연금전환 계약리스트 계약번호
                    ancvAge: '65' // 연금전환연령
                  }
                  tr_wlifeAnutCnvrArr.push(lv_Vm.$bizUtil.ctUtils.fn_wlifeAnutCnvr(lv_Vm, reqParam))
                }
              }
            }
            Promise.all(tr_wlifeAnutCnvrArr).then(function (response) {
              console.log('종신보험 response>>', response)
              lv_Vm.fn_SetVuexWlifeAnutCnvr('', '', response, '') // 종신보험 전환가능 데이터 스토어 저장
              // 계약번호가 정상적으로 조회되면 (유효 계약번호 조회)
              lv_Vm.fn_wlifeAnutCnvrToTcompLayout(true, response)
              })
              .catch(function (error) {
                window.vue.error(error)
              })
          }).catch(function (error) {
            window.vue.error(error)
          })
        } else {
          console.log('기존기존기존>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.getStore('ctStore').getters.getWlifeAnutCnvr.wlifeAnutCnvrList)
          lv_Vm.contData = lv_Vm.getStore('ctStore').getters.getContData.data
          console.log('fn_wlifeAnutCnvrToTcompLayout 종신 스토어 저장 확인!!', this.getStore('ctStore').getters.getWlifeAnutCnvr)
          // 기존에 조회했던 종신보험연금전환 데이터를 세팅
          this.fn_wlifeAnutCnvrToTcompLayout(false, this.getStore('ctStore').getters.getWlifeAnutCnvr.data)
        }
      },

      /************************************************************************************************
      * Function명 : fn_SetVuexWlifeAnutCnvr
      * params     : pShowPension :: 종신보험연금전환 버튼 클릭여부
      *              pShowPension1 :: 생애설계자금전환 클릭여부
      *              resp :: 종신보험연금전환 IF 응답값(원본 그대로)
      *              wlifeAnutCnvrList :: 032D 화면에서 사용될 형태로 가공 된 종신보험 연금전환 데이터
      * 설명       : 종신보험 연금전환 vuex 데이터 세팅
      ************************************************************************************************/
      fn_SetVuexWlifeAnutCnvr (pShowPension, pShowPension1, resp, wlifeAnutCnvrList) {
        if (resp === '') {
          resp = this.getStore('ctStore').getters.getWlifeAnutCnvr.data
        }
        /* if (pShowPension === false) {
          pShowPension = this.getStore('ctStore').getters.getWlifeAnutCnvr.showPension
        } */
        if (wlifeAnutCnvrList === '') {
          wlifeAnutCnvrList = this.getStore('ctStore').getters.getWlifeAnutCnvr.wlifeAnutCnvrList
        }
        this.getStore('ctStore').dispatch('WLIFEANUTCNVRREG', {showPension: pShowPension, showPension1: pShowPension1, data: resp, wlifeAnutCnvrList: wlifeAnutCnvrList})
        console.log('fn_SetVuexWlifeAnutCnvr 종신 스토어 저장 확인!!', this.getStore('ctStore').getters.getWlifeAnutCnvr)
      },

      /************************************************************************************************
      * Function명 : fn_wlifeAnutCnvrToTcompLayout
      * 설명       : 종신보험 연금전환 데이터를 당사 데이터 형태로 변환
      ************************************************************************************************/
      fn_wlifeAnutCnvrToTcompLayout (isFirst, response) {
        this.wlifeAnutCnvrData = [] // 초기화
        console.log('fn_wlifeAnutCnvrToTcompLayout>>>>>>>>>>1', response)
        let acnvContInfoList = response
        for ( let inxResp in acnvContInfoList ) {
          let ageAnpsnVO = acnvContInfoList[inxResp].body.anutIlstInfoVO.ageAnpsnVO
          let contNo = acnvContInfoList[inxResp].body.contNo
          console.log('this.contData>>>', this.contData)
          let tcomAnutList = this.contData.filter( x => { return x.contNo === contNo })
          console.log('fn_wlifeAnutCnvrToTcompLayout>>>>>>>>>>2', tcomAnutList)
          // 65세 연금액 정보를 가져온다.
          if ( ageAnpsnVO != null && ageAnpsnVO.length > 0 &&
            tcomAnutList != null && tcomAnutList.length > 0 ) {
            // debugger
            let wlifeAcnvContInfo = {}
            // let lv_anpsn = {}
            let entireAgeAnpsnVO = ageAnpsnVO
            ageAnpsnVO = ageAnpsnVO.filter( x => { return x.age === 65 })
            wlifeAcnvContInfo.contNo = contNo // 계약번호
            wlifeAcnvContInfo.insrCoNm = '삼성생명 (연금전환)' // 가입회사
            wlifeAcnvContInfo.prdtNm = tcomAnutList[0].prdtNm // 보험명
            wlifeAcnvContInfo.contYmd = tcomAnutList[0].contYmd // 계약년월
            wlifeAcnvContInfo.rcvPrd = ctConstants.anutRcvPrd + ' 년' // 당사 수령기간은 20년 고정형
            wlifeAcnvContInfo.smtotPrm = tcomAnutList[0].smtotPrm // 보험료
            wlifeAcnvContInfo.alpadTotPrm = tcomAnutList[0].alpadTotPrm // 기납입총보험료
            // 월납입 보험료 계산을 위해 '납입주기', '향후납입보험료' 항목 추가
            wlifeAcnvContInfo.padCylCd = tcomAnutList[0].padCylCd // 납입주기
            wlifeAcnvContInfo.padParngTotPrm = tcomAnutList[0].padParngTotPrm // 향후납입보험료
            wlifeAcnvContInfo.anpsn = ageAnpsnVO[0].anpsn01 // 연금연액
            wlifeAcnvContInfo.anpsn01 = ageAnpsnVO[0].anpsn12 // 연금월액
            wlifeAcnvContInfo.wlifeAnutCnvrYN = 'Y' // 종신보험연금전환 여부
            wlifeAcnvContInfo.entireAgeAnpsnVO = entireAgeAnpsnVO // 20년 동안 매년 연금 정보
            wlifeAcnvContInfo.prpslStatCd = '01'
            wlifeAcnvContInfo.tcomYn = true
            wlifeAcnvContInfo.anutBgnMnisdAge = 65
            wlifeAcnvContInfo.anpsnStartAmount = ageAnpsnVO[0].anpsn01
            this.wlifeAnutCnvrData.push(wlifeAcnvContInfo)
          }
        }
        console.log('뭐야!!!!!', this.wlifeAnutCnvrData)
        // 종신보험 연금전환 상품 목록셋팅
        this.fn_wlifeAnutCnvrViewSet()
      },

      /******************************************************************************
      * Function명  : fn_wlifeAnutCnvrViewSet
      * 설명        : 종신보험 연금전환 상품 목록셋팅
      ******************************************************************************/
      fn_wlifeAnutCnvrViewSet () {
        let tcompAcontList = this.getStore('ctStore').getters.getTcomAnut.data
        tcompAcontList = tcompAcontList === undefined ? [] : tcompAcontList
        console.log('this.wlifeAnutCnvrData>>>', this.wlifeAnutCnvrData)
        console.log('종신보험 플래그 체크>>>', this.showPension)
        console.log('생애설계 플래그 체크>>>', this.showPension1)
        if ( this.wlifeAnutCnvrData.length > 0 ) { // 종신보험 연금전환 데이터가 존재하면
          if (this.showPension && this.showPension1) { // 종신보험 연금전환 선택 시
            console.log('종신 true / 생애 true', tcompAcontList)
            for (let idx in this.wlifeAnutCnvrData) {
              let tcompAcont = this.wlifeAnutCnvrData[idx]
              let pensIdx = tcompAcontList.findIndex(item => { return item.contNo === tcompAcont.contNo })
              if ( pensIdx < 0 ) {
                tcompAcontList.push(tcompAcont)
              }
            }
            this.fn_SetVuexWlifeAnutCnvr(true, true, '', tcompAcontList)
          } else if (!this.showPension && this.showPension1) { // 생애설계만 선택시
            console.log('종신 false / 생애 true', tcompAcontList)
            for (let idx in this.wlifeAnutCnvrData) {
              let tcompAcont = this.wlifeAnutCnvrData[idx]
              let tcompAcontListCnt = tcompAcontList.filter(e => e.prdtNm.indexOf('생애설계') > -1)
              console.log('tcompAcontCnt>>', tcompAcontListCnt)
              console.log('tcompAcontCnt.length>>', tcompAcontListCnt.length)
              if (tcompAcont.prdtNm.indexOf('생애설계') > -1 && tcompAcontListCnt.length === 0) {
                  tcompAcontList.push(tcompAcont)
              }
            }
            this.fn_SetVuexWlifeAnutCnvr(false, true,'', tcompAcontList)
          } else if (this.showPension && !this.showPension1) { // 종신보험 전환 선택 후 생애설계 해제
            console.log('종신 true 생애 false>>', tcompAcontList)
            for (let idx in this.wlifeAnutCnvrData) {
              let tcompAcont = this.wlifeAnutCnvrData[idx]
              let pensIdx = tcompAcontList.findIndex((item) => { return item.contNo === tcompAcont.contNo })
              if (tcompAcontList[idx] && tcompAcontList[idx].prdtNm.indexOf('생애설계') > -1) {
                console.log('생애설계 pensIdx>>', pensIdx)
                tcompAcontList.splice(pensIdx, 1)
              }
            }
            this.fn_SetVuexWlifeAnutCnvr(true, false, '', tcompAcontList)
          } else { // 종신보험 연금전환 해제 시
            console.log('종신 false / 생애 false', tcompAcontList)
            for (let idx in this.wlifeAnutCnvrData) {
              let tcompAcont = this.wlifeAnutCnvrData[idx]
              let pensIdx = tcompAcontList.findIndex((item) => { return item.contNo === tcompAcont.contNo })
              if ( pensIdx > -1 ) {
                tcompAcontList.splice(pensIdx, 1)
              }
            }
            this.fn_SetVuexWlifeAnutCnvr(false, false, '', tcompAcontList)
          }
        }
        console.log('최종 확인!!', this.getStore('ctStore').getters.getWlifeAnutCnvr)
        console.log('최종 확인1!!', tcompAcontList)
        this.getStore('ctStore').dispatch('TCOMANUTREG', {'data': tcompAcontList})
        this.$emit('pensSet')
      },
    }
  };
</script>
<style scoped>
</style>