<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 bgcolor-1">

      <!--개인연금 -->
      <div class="calculate-expand w100 mb20">
        <div class="fexTy3 fs18rem">
          <span class="fwm">개인연금</span>
          <!-- <span v-if="isPeriedFlag" class="fwb fexTy5">{{ toPeriedDiff }}</span>
          <span v-else class="fwb fexTy5" :class="Number(toPeriedDiff) > 0 ?'crTy-blue3' : 'crTy-orange2'">{{`${Math.abs(Number(toPeriedDiff))}년`}}<mo-icon :icon="Number(toPeriedDiff) > 0 ? 'msp-arrow-increase' :'msp-arrow-decrease'" /></span> -->
        </div>

        <div class="mt20 fs14rem fexTy3">
          <span class="crTy-bk7">설계전</span>
          <span v-if="beforeToAgeDataList.length > 0" class="fwb">
            {{ 55 + beforeToStartIdx * 5}}
            ~
            {{ 55 + beforeToEndIdx * 5 }} 세</span>
        </div>
        <div class="calculate-chart status-con00 fexTy5"><!-- on 은 색상이 있는 bar, start/end 는 radius -->
          <template v-for="(list, idx) in beforeToAgeDataList">
            <div class="chart-box" :class="{
                'on' : list
              , 'start': beforeToStartIdx === idx
              , 'end': beforeToEndIdx === idx} " :key="idx">
              <div class="bar"/>
              <span class="dsD txt">{{ `${55 + idx * 5}` }}</span>
            </div>
          </template>
        </div>
        <div class="mt20 fs14rem fexTy3">
          <span class="fwb">설계후</span>
          <span v-if="afterToAgeDataList.length > 0" class="fwb">
            {{ 55 + afterToStartIdx * 5}}
            ~
            {{ 55 + afterToEndIdx * 5 }} 세</span>
        </div>
        <div class="calculate-chart status-con06 fexTy5">
          <template v-for="(list, idx) in afterToAgeDataList">
            <div class="chart-box" :class="{
                'on' : list
              , 'start': afterToStartIdx === idx
              , 'end': afterToEndIdx === idx} " :key="idx">
              <div class="bar"/>
              <span class="dsD txt">{{ `${55 + idx * 5}` }}</span>
            </div>
          </template>
        </div>

        <mo-button class="ns-btn-round w100 mt20 rounded h40 blackW fs16rem" @click="fn_OpenPrdRtvModal('1')">기간별 수령금액</mo-button>
      </div>
      <!--국민연금 -->
      <div v-if="npensSumList.length > 0" class="calculate-expand w100 mb20">
        <div class="fexTy3 fs18rem">
          <span class="fwm">국민연금</span>
        </div>
        <div class="mt20 fs14rem fexTy3">
          <span class="fwb">예상기간</span>
          <span class="fwb">{{ `${55 + npensStartIdx * 5}` }} ~ {{ `${55 + npensEndIdx * 5}` }} 세</span>
        </div>
        <div class="calculate-chart status-con04 fexTy5">
          <template v-for="(list, idx) in npensSumList">
            <div class="chart-box" :class="{
                'on' : list.price > 0
              , 'start': npensStartIdx === idx
              , 'end': npensEndIdx === idx} " :key="idx">
              <div class="bar"/>
              <span class="dsD txt">{{ `${55 + idx * 5}` }}</span>
            </div>
          </template>
        </div>
        <mo-button class="ns-btn-round w100 mt20 rounded h40 blackW fs16rem" @click="fn_OpenPrdRtvModal('2')">기간별 수령금액</mo-button>
      </div>
      <!--퇴직연금 -->
      <div v-if="rtpsSumList.length > 0" class="calculate-expand w100 mb20">
        <div class="fexTy3 fs18rem">
          <span class="fwm">퇴직연금</span>
        </div>
        <div class="mt20 fs14rem fexTy3">
          <span class="fwb">예상기간</span>
          <span class="fwb">{{ `${55 + rtpsStartIdx * 5}` }} ~ {{ `${55 + rtpsEndIdx * 5}` }} 세</span>
        </div>
        <div class="calculate-chart status-con05 fexTy5">
          <template v-for="(list, idx) in rtpsSumList">
            <div class="chart-box" :class="{
                'on' : list.price > 0
              , 'start': rtpsStartIdx === idx
              , 'end': rtpsEndIdx === idx} " :key="idx">
              <div class="bar"/>
              <span class="dsD txt">{{ `${55 + idx * 5}` }}</span>
            </div>
          </template>
        </div>
        <mo-button class="ns-btn-round w100 mt20 rounded h40 blackW fs16rem" @click="fn_OpenPrdRtvModal('3')">기간별 수령금액</mo-button>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-s mt30 bgcolor-1">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_productEdit">상품수정</mo-button> <!-- disabled -->
        </div>
      </ur-box-container>
    </ur-box-container>

    <!-- 개인연금 -->
    <mo-bottom-sheet ref="refPAnut" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        {{ modalTitle }}
        <!--개인연금-->
        <template v-if="modalFlag">
          <div class="mt20 fexTy5">
              <!-- <span v-if="isPeriedFlag" class="fwb dsD fs30rem">
                {{ toPeriedDiff }}
              </span>
              <span v-else class="fwb dsD fs30rem" :class="Number(toPeriedDiff) > 0 ?'crTy-blue3' : 'crTy-orange2'">{{`${Math.abs(Number(toPeriedDiff))}년`}}
                <mo-icon class="fs28" :icon="Number(toPeriedDiff) > 0 ? 'msp-arrow-increase' :'msp-arrow-decrease'" />
              </span> -->
          </div>
          <div class="mt4 fexTy5 sub-text">
            <span v-if="beforeToAgeDataList.length > 0">
              <span class="fs14rem crTy-bk7 min50 mr4 dsInline">설계 전</span>
              {{ 55 + beforeToStartIdx * 5}}
              ~
              {{ 55 + beforeToEndIdx * 5 }} 세
            </span>
            <em>|</em>
            <span v-if="afterToAgeDataList.length > 0">
              <span class="fs14rem crTy-bk7 min50 mr4 dsInline">설계 후</span>
              {{ 55 + afterToStartIdx * 5}}
              ~
              {{ 55 + afterToEndIdx * 5 }} 세
              </span>
          </div>
        </template>
        <!--퇴직 / 국민연금-->
        <div v-else class="mt20 fexTy5 sub-text">
          <span>
            <span class="fs14rem crTy-bk7 min50 mr4 dsInline">예상기간</span>
            <template v-if="modalTitle === '국민연금'">
            {{ `${55 + npensStartIdx * 5}` }} ~ {{ `${55 + npensEndIdx * 5}` }} 세
            </template>
            <template v-else>
            {{ `${55 + rtpsStartIdx * 5}` }} ~ {{ `${55 + rtpsEndIdx * 5}` }} 세
            </template>
          </span>
        </div>
      </template>
      <div class="content-area maxh-300 pb30">
        <span class="dsD txt-right crTy-bk7 fs14rem mb10">(단위 : 만원)</span>
        <div class="table-area">
          <!--개인연금-->
          <table v-if="modalFlag" class="table row-type">
            <colgroup>
              <col width="33.333%" />
              <col width="33.333%" />
              <col width="33.333%" />
            </colgroup>
            <thead>
              <tr>
                <th>연력</th><th>설계전</th><th>설계후</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(data,idx) in mockData">
                <tr :key="idx">
                  <td>{{`${55 + idx * 5}세`}}</td>
                  <td class="txt-right pr10">{{ data.price }}</td>
                  <td class="txt-right pr10 bgcolor-6">{{ data.showAfterText }}</td>
                </tr>
              </template>
            </tbody>
          </table>
          <!--국민/퇴직연금-->
          <table v-else class="table row-type">
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <thead>
              <tr>
                <th>연령</th><th>예상금액</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(data,idx) in mockData">
                <tr :key="idx">
                  <td>{{`${55 + idx * 5}세`}}</td><td class="txt-right pr10 bgcolor-6">{{ data.showAfterText }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="close1" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>


    <!-- 상품수정 -->
    <mo-bottom-sheet ref="refProductEdit" class="ns-bottom-sheet" :key="editCnt"  :close-btn="true">
      <template v-slot:title>
        상품수정
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt20">
          <mo-segment-wrapper solid primary v-model="editTab" class="">
            <mo-segment-button value="1">상품 제외</mo-segment-button>
            <mo-segment-button value="2">상품 추가</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </template>
      <div class="content-area maxh-300 pb30 pl0 pr0 bd-T-Ty1">
        <!-- 상품 제외-->
        <template v-if="editTab === '1'">
          <template v-if="allRetProductList.length > 0">
            <ur-box-container v-for="(list, idx) in allRetProductList" :key="list.name + idx" alignV="start" componentid="" direction="column">
              <div class="w100 pal2024 bd-b-Ty1">
                <!-- <div class="fexTy5"> -->
                <div class="fexTy3-1">
                  <mo-checkbox v-model="list.checked" class="ns-check flex-1" :disabled="!list.canDelete">{{list.name}}</mo-checkbox>
                  <span class="badge-type pl10 pr10 fs12 h20 mt4" :class="{'lightgreen2' : list.hasProduct === true, 'lightred' : list.isRecommendProduct === true, 'lightgray' : list.isOtherProduct === true }">{{ list.isOtherProduct ? '타사' : (list.hasProduct ? '보유' : (list.isRecommendProduct ? '추천' : '')) }}</span>
                </div>
                <div class="fs17rem mt10 fwb  crTy-blue3 ml36">{{ list.monthPrice !== '-' ? `월 ${$commonUtil.numberWithCommas(list.monthPrice)} 원` : '-' }}</div>
              </div>
              <!-- no-data 조건설정 및 데이타 없음 --> 
            </ur-box-container>
          </template>
            <ur-box-container v-else alignV="start" componentid="" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info small-space"> <!-- 영역이 작은곳에서 활용 small-space -->
                    <span class="ns-ftcr-gray"><p class="mt10">보유 상품이 없습니다.</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- no-data end -->
        </template>
        <!--상품 추가-->
        <template v-if="editTab === '2'">
          <template v-if="productConfigList.length > 0">
            <ur-box-container v-for="(list, idx) in productConfigList" :key="list.name + idx" alignV="start" componentid="" direction="column">
              <div class="w100 pal2024 bd-b-Ty1">
                <!-- <div class="fexTy5"> -->
                <div class="fexTy3-1">
                  <mo-checkbox v-model="list.checked" class="ns-check flex-1">{{list.name}}</mo-checkbox>
                  <span class="badge-type pl10 pr10 fs12 h20 mt4" :class="{'lightgreen2' : list.hasProduct === true, 'lightred' : list.isRecommendProduct === true, 'lightgray' : list.isOtherProduct === true }">{{ list.isOtherProduct ? '타사' : (list.hasProduct ? '보유' : (list.isRecommendProduct ? '추천' : '')) }}</span>
                </div>
                <div class="fs17rem mt10 fwb  crTy-blue3 ml36">{{list.monthPrice !== '-' ? `월 ${$commonUtil.numberWithCommas(list.monthPrice)} 원` : '-' }}</div>
              </div>
              <!-- no-data 조건설정 및 데이타 없음 --> 
            </ur-box-container>
          </template>
            <ur-box-container v-else alignV="start" componentid="" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info small-space"> <!-- 영역이 작은곳에서 활용 small-space -->
                    <span class="ns-ftcr-gray"><p class="mt10">최근 가입설계 상품이 없습니다.</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- no-data end -->
        </template>  
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_MoveMSPPI001M" class="ns-btn-round white">새로설계</mo-button>
            <mo-button v-if="editTab === '1'" componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_EditConfirm" class="ns-btn-round blue">제외</mo-button>
            <mo-button v-if="editTab === '2'" componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_EditConfirm" class="ns-btn-round blue">추가</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
import UrBoxContainer from '~/src/_uidev/containers/box/urBoxContainer.vue';
import ctConstants from '@/config/constants/ctConstants'
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {UrBoxContainer},
    props: {
      pPensData: {
        type: Object,
        default: {}
      },
      pComplete: {
        type: Boolean,
        default: false
      },
      slctTabIdx: {
        type: Number,
        default: 0
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.$emit('totAmountData', {}) // 헤더 초기화를 위하여
      console.log('this.slctTabIdx>>>', this.slctTabIdx)
      console.log('created 온로드')
      this.pensData = this.pPensData
      this.fn_periodOnload()
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        editTab : '1',
        pensData: {},
        beforeList: {},
        afterList: {},
        mockData: [],
        bothPAnutList: [],
        npensSumList: [],
        rtpsSumList: [],
        variableFlag: false, // 변액연금 존재 여부 확인 플래그
        beforeToAgeDataList: [], // 개인연금 설계전 나이 리스트
        afterToAgeDataList: [], // 개인연금 설계후 나이 리스트
        npensStartIdx: 0, //국민연금 시작 위치
        npensEndIdx: 0, //국민연금 종료 위치
        rtpsStartIdx : 0, // 퇴직연금 시작 위치
        rtpsEndIdx : 0, // 퇴직연금 종료 위치
        beforeToStartIdx: 0, // 개인연금 설계전 시작위치
        beforeToEndIdx: 0, // 개인연금 설계전 종료위치
        afterToStartIdx: 0, // 개인연금 설계후 시작위치
        afterToEndIdx: 0, // 개인연금 설계후 종료위치
        toPeriedDiff: '', // 개인연금 기간비교값
        isPeriedFlag: true, // 개인연금 기간비교값 구분 플래그(true : String, false : Number)
        modalFlag: false, // 기간별 수령금액 버튼 모달 구분 플래그
        modalTitle: '',
        editCnt: 0,
        totAmountData: {},
        allRetProductList: [],
        productConfigList: [], 
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      custAge () { 
        return this.getStore('ctStore').getters.getCustBaseInfo.data
      },
      foreg: function () { // 개인추가정보
        return this.getStore('ctStore').getters.getMeminfoData.data
      },
      lv_BaseData: function () {
        // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
      lv_CovanData: function () {
        // 고객 계약 데이터
        let returnValue = this.getStore('ctStore').getters.getCovanData.data
        return returnValue
      },
    },
    watch: {
      pComplete() {
        if (this.pComplete) {
          this.$emit('totAmountData', {})
          this.pensData = this.pPensData
          console.log('300M 완료되어서 308 로직 시작!!', this.pensData)
          this.fn_periodOnload()
        } else {
          console.log('308 Onload 완료!!')
        }
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      close1()  { this.$refs.refPAnut.close(); },
      open2()   { this.$refs.refProductEdit.open();  },
      close2()  { this.$refs.refProductEdit.close(); },

      /************************************************************************************************
      * Function명 : fn_periodOnload
      * 설명       : 
      ************************************************************************************************/
      fn_periodOnload() {
        let lv_vm = this
        lv_vm.visibleLivingCost = false // 희망생활비
        lv_vm.pensData = lv_vm.pPensData
        console.log('초기화 제대로 안되니??', lv_vm.pensData)
        window.vue.getStore('progress').dispatch('FULL')
        window.vue.getStore('progress').dispatch('SHOW')
        Promise.all([lv_vm.$bizUtil.ctUtils.fn_PrpslCovrAnly(lv_vm)]) // 가입설계리스트 조회
        .then(function (response) {
          lv_vm.productList = []
          lv_vm.productConfigList = []
          lv_vm.allRetProductList = []
          lv_vm.npensProductList = []
          lv_vm.exptProductList = []
          console.log('온로드1>>>', response)
          let resp = lv_vm.foreg
          lv_vm.hopeLivingCost = Number(resp.rtmAftLvgct.longAmount)
          let promiseArray = []
          // 가입설계 제안 처리
          let pAnutFlag = true // 연금계산기 기준 가입설계 목록 flag ( 1. 계피동일 건만 & 2. 월보험료가 1억이상 상품 가설목록에서 배재 )
          lv_vm.$bizUtil.ctUtils.fn_PrpslCovrAnlyRcg(lv_vm, pAnutFlag)
          // configList에 가입설계제안만 남아있을때 변액처리를 한다.
          // configList에 있는 가입설계(가입설계제안상품)만큼 가입설계 해약 정보 프로미스를 획득한다.
          for (let promiseIndex in lv_vm.productList) {
            let promiseInnerItem = lv_vm.productList[promiseIndex]
            // 화면 로딩시 비어있게 만들기 위해 detail을 강제로 셋팅
            let itemArray = [] // 카드
            itemArray.push(['-', '-', '-', '-'].slice())
            console.log('itemArray>>>>', itemArray)
            promiseInnerItem.details = itemArray
            promiseArray.push(lv_vm.$bizUtil.ctUtils.fn_GtEntpLanutRegProc(lv_vm, promiseInnerItem)) // 가입설계의 해약환급금(연금정보)를 가공
          }
          Promise.all(promiseArray).then( function (response) {
            console.log('이값은 뭐니??>>>', response)
            // 입설계제안의 해약정보를 모두획득하면 카드 그리기를 마무리 짓는다.
            for (let dtlChkIndx in lv_vm.productList) {
              let chkItem = response[dtlChkIndx]
                lv_vm.productList[dtlChkIndx].yearPrice = chkItem// 상품에 대한 금액처리 부분을 만들어 적용해야 한다.
                lv_vm.productList[dtlChkIndx].checked = true// 상품에 대한 금액처리 부분을 만들어 적용해야 한다.
              //}
            }
            console.log('lv_vm.productList', lv_vm.productList)
            console.log('lv_vm.pensData>>>>>', lv_vm.pensData)
            // 자사 계약 셋팅
            for (let tcomIdx in lv_vm.pensData.tcomAnutList) {
              let innrTcomAnut = lv_vm.pensData.tcomAnutList[tcomIdx]
              console.log('pensData.tcomAnutList>>', innrTcomAnut)
              if (innrTcomAnut.prpslStatCd === '01') {
                let rstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, innrTcomAnut, true, false, true)
                rstItem.checked = true
                rstItem.sortIdx = 1
                lv_vm.productList.push(rstItem)
              } else {
                let rstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, innrTcomAnut, true, false, true)
                rstItem.checked = false
                rstItem.sortIdx = 1
                lv_vm.productConfigList.push(rstItem)
              }
            }
            console.log('lv_vm.productList>>>', lv_vm.productList)
            console.log('lv_vm.productConfigList>>>', lv_vm.productConfigList)
            // 타사계약 셋팅
            for (let otcompIdx in lv_vm.pensData.otcomAnutList) {
              let InnrOtcomAnut = lv_vm.pensData.otcomAnutList[otcompIdx]
              if (InnrOtcomAnut.prpslStatCd === '01') {
                let rstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, InnrOtcomAnut, true, false, false)
                //lv_vm.fn_RSttingItem(InnrOtcomAnut, rstItem)
                rstItem.checked = true
                rstItem.sortIdx = 3
                lv_vm.productList.push(rstItem)
              } else {
                let rstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, InnrOtcomAnut, true, false, false)
                //lv_vm.fn_RSttingItem(InnrOtcomAnut, rstItem)
                rstItem.checked = false
                rstItem.sortIdx = 3
                lv_vm.productConfigList.push(rstItem)
              }
            }
            lv_vm.allRetProductList = lv_vm.allRetProductList.concat(lv_vm.productList)
            if (lv_vm.pensData.gtpNpens !== undefined) {
              // 국민연금 셋팅
              if ( lv_vm.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 국민 정보를 구한다.
                lv_vm.fn_SetNpensCard()
              }
            }

            if (lv_vm.pensData.exptPpsn !== undefined) {
              // 퇴직연금 셋팅
              if ( lv_vm.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 퇴직 정보를 구한다.
                lv_vm.fn_SetRtpsCard()
              }
            }
            // 헤더셋팅
            lv_vm.fn_CalcAmount(lv_vm)
            if (lv_vm.productList.length > 0) {
              lv_vm.privateDisable = false
            } else {
              lv_vm.privateDisable = true
            }
            lv_vm.allRetProductList.sort(function (a, b) {
              if (a.sortIdx < b.sortIdx) return -1
              if (a.sortIdx === b.sortIdx) return 0
              if (a.sortIdx > b.sortIdx) return 1
            })
            console.log('allRetProductList>>>', lv_vm.allRetProductList)
            lv_vm.fn_graphAnutDataProc()
          }).catch(function (error) {
            window.vue.getStore('progress').dispatch('HIDE')
            window.vue.error(error)
          })
        }).catch(function (error) {
            window.vue.getStore('progress').dispatch('HIDE')
          console.log('307D 온로드 에러???', error)
          window.vue.error(error)
        })
      },

      // 모든 배열값을 55,60,65,70,75,80 기준으로 생성
      // 설계전 상품별 연금액 (당사, 타사, 국민, 퇴직)
      // 설계후 상품별 연금액 (당사, 타사, 국민, 퇴직)
      // 설계전 합계 panut: [], npens: [], rtps: [], sumMonthAnut: []
      // 설계후 합계 panut: [], npens: [], rtps: [], sumMonthAnut: []
      // 과부족 합계 diffAmount: []
      /************************************************************************************************
      * Function명 : fn_graphAnutDataProc
      * 설명       : 
      ************************************************************************************************/
      fn_graphAnutDataProc () {
        let lv_Vm = this
        // let beforeList =  {} // 설계전 연금 목록
        // let afterList = {} // 설계후 연금 목록
        let beforePAnutSum = [0, 0, 0, 0, 0, 0] // 당사,타사,가설 연령별 합산금액 55,60,65,70,75,80
        let afterPAnutSum = [0, 0, 0, 0, 0, 0] // 당사,타사,가설 연령별 합산금액 55,60,65,70,75,80
        let afterPAnutMinSum = [0, 0, 0, 0, 0, 0] // 가설 변액연금 최소값들의 합
        let afterPAnutMaxSum = [0, 0, 0, 0, 0, 0] // 가설 변액연금 최대값들의 합
        let bothPAnutSum = [] // 당,타사 연령별 금액 합친 object
        let npens = [] // 국민연금 데이터
        let rtps = [] // 퇴직연금 데이터
        let npensSum = [] // 국민연금 같은값끼리 셀 합친 데이터
        let rtpsSum = [] // 퇴직연금 같은값끼리 셀 합친 데이터

        lv_Vm.fn_PrpslList(lv_Vm, 'before').then(function (response1) {
          lv_Vm.beforeList = response1
          lv_Vm.fn_PrpslList(lv_Vm, 'after').then(function (response2) {
            lv_Vm.afterList = response2
            console.log('lv_Vm.beforeList  :: ', lv_Vm.beforeList)
            console.log('lv_Vm.afterList  :: ', lv_Vm.afterList)
            lv_Vm.beforeToAgeDataList = [0, 0, 0, 0, 0, 0]
            lv_Vm.afterToAgeDataList = [0, 0, 0, 0, 0, 0]
            lv_Vm.beforeToStartIdx = 0
            lv_Vm.beforeToEndIdx = 0
            lv_Vm.afterToStartIdx = 0
            lv_Vm.afterToEndIdx = 0

            // [설계전] 연령별 연금액 합산(당사, 타사)
            for (let h in lv_Vm.beforeList) {
              let itm = lv_Vm.beforeList[h]
              if (itm.type === 'tcomp' || itm.type === 'otcomp') {
                for (let j in beforePAnutSum) {
                  beforePAnutSum[j] += itm.anpsn[j].prm
                  lv_Vm.beforeToAgeDataList[j] += itm.anpsn[j].prm
                }
              } else if (itm.type === 'npens') {
                npens = itm.anpsn
              } else if (itm.type === 'rtps') {
                rtps = itm.anpsn
              }
            }

            // [설계후] 연령별 연금액 합산(당사, 타사, 가설)
            for (let i in lv_Vm.afterList) {
              let itm = lv_Vm.afterList[i]
              if (itm.type === 'tcomp' || itm.type === 'otcomp') {
                for (let j in afterPAnutSum) {
                  afterPAnutSum[j] += itm.anpsn[j].prm
                  lv_Vm.afterToAgeDataList[j] += afterPAnutSum[j]
                }
                console.log('afterPAnutSum>>', afterPAnutSum)
              } else if (itm.type === 'entpl') { // 가입설계
                if (itm.anutType === ctConstants.annuityInfoMap.variable.code ||
                  (itm.anutType === ctConstants.annuityInfoMap.conversion.code && itm.maxPrm !== undefined)) {// 변액일 경우 (종신보험연금전환 변액 포함)
                  lv_Vm.variableFlag = true
                  for (let j in afterPAnutSum) {
                    afterPAnutMinSum[j] += (itm.anpsn[j].minPrm ? Math.floor(itm.anpsn[j].minPrm / 10000) : 0)
                    // afterPAnutSum[j] += itm.anpsn[j].prm // 변액은 중간 값 넣지 않음.
                    afterPAnutMaxSum[j] += (itm.anpsn[j].maxPrm ? Math.floor(itm.anpsn[j].maxPrm / 10000) : 0)
                    lv_Vm.afterToAgeDataList[j] += afterPAnutMaxSum[j]
                  }
                  console.log('변액2>>>', afterPAnutMinSum )
                  console.log('변액3>>>', afterPAnutMaxSum)
                // 변액외의 경우는 합산 (변액은 화면에서 합산)
                } else {
                  console.log('너??')
                  for (let j in afterPAnutSum) {
                    console.log('itm.anpsn[j].prm111>>', itm.anpsn[j].prm)
                    console.log('itm.anpsn[j].prm222>>', Math.floor(itm.anpsn[j].prm / 10000))
                    afterPAnutSum[j] += Math.floor(itm.anpsn[j].prm / 10000)
                  }
                }
              }
            }

            for (let i in beforePAnutSum) {
              let showAfterText = '' // 화면에 표시될 값
              if (lv_Vm.variableFlag) { // 변액일 경우
                let minValue = lv_Vm.$bizUtil.ctUtils.fn_SetNumber(afterPAnutMinSum[i]) + afterPAnutSum[i]
                let maxValue = lv_Vm.$bizUtil.ctUtils.fn_SetNumber(afterPAnutMaxSum[i]) + afterPAnutSum[i]
                console.log('1313131313', afterPAnutMinSum[i], afterPAnutMaxSum[i], afterPAnutSum[i])
                if (minValue === maxValue) {
                  showAfterText = lv_Vm.$commonUtil.numberWithCommas(minValue)
                } else {
                  showAfterText = lv_Vm.$commonUtil.numberWithCommas(minValue) + '~' + lv_Vm.$commonUtil.numberWithCommas(maxValue)
                }
              } else { // 변액 아닐경우
                showAfterText = lv_Vm.$commonUtil.numberWithCommas(afterPAnutSum[i])
              }
              bothPAnutSum[i] = {
                price: lv_Vm.$commonUtil.numberWithCommas(beforePAnutSum[i]),
                age: 55 + (i * 5),
                afterPrice: afterPAnutSum[i],
                afterPAnutMinPrice: lv_Vm.$bizUtil.ctUtils.fn_SetNumber(afterPAnutMinSum[i]),
                afterPAnutMaxPrice: lv_Vm.$bizUtil.ctUtils.fn_SetNumber(afterPAnutMaxSum[i]),
                showAfterText: showAfterText
              }
            }


            for (let j in npens) {
              npensSum[j] = {price: npens[j].prm, afterPrice: npens[j].prm, showAfterText: lv_Vm.$commonUtil.numberWithCommas(npens[j].prm)}
            }
            for (let k in rtps) {
              rtpsSum[k] = {price: rtps[k].prm, afterPrice: rtps[k].prm, showAfterText: lv_Vm.$commonUtil.numberWithCommas(rtps[k].prm)}
            }
            // monthSum = lv_Vm.fn_SumByAge(lv_Vm, beforePAnutSum, afterPAnutSum, npensSum, rtpsSum, afterPAnutMinSum, afterPAnutMaxSum)

            lv_Vm.beforeToStartIdx = lv_Vm.beforeToAgeDataList.findIndex(item => item > 0)
            lv_Vm.beforeToAgeDataList.forEach((e, idx) => {
              if (idx > lv_Vm.beforeToStartIdx) {
                if (e === 0) {
                  lv_Vm.beforeToEndIdx = idx - 1
                } else {
                  lv_Vm.beforeToEndIdx = idx
                }
              }
            })
            lv_Vm.afterToStartIdx = lv_Vm.afterToAgeDataList.findIndex(item => item > 0)
            lv_Vm.afterToAgeDataList.forEach((e, idx) => {
              if (idx > lv_Vm.afterToStartIdx) {
                if (e === 0) {
                  lv_Vm.afterToEndIdx = idx - 1
                } else {
                  lv_Vm.afterToEndIdx = idx
                }
              }
            })
            let t_beforeStartAge = lv_Vm.beforeToAgeDataList[lv_Vm.beforeToStartIdx]
            let t_beforeEndAge = lv_Vm.beforeToAgeDataList[lv_Vm.beforeToEndIdx]
            let t_beforeAgeDiff = t_beforeEndAge - t_beforeStartAge
            let t_afterStartAge = lv_Vm.afterToAgeDataList[lv_Vm.afterToStartIdx]
            let t_afterEndAge = lv_Vm.afterToAgeDataList[lv_Vm.afterToEndIdx]
            let t_afterAgeDiff = t_afterEndAge - t_afterStartAge

            if (lv_Vm.beforeToStartIdx === lv_Vm.afterToStartIdx && lv_Vm.beforeToEndIdx === lv_Vm.afterToEndIdx) { // 기간동일
              lv_Vm.isPeriedFlag = true
              lv_Vm.toPeriedDiff = '-'
            } else if (t_afterAgeDiff- t_beforeAgeDiff === 0 
            && (lv_Vm.beforeToStartIdx !== lv_Vm.afterToStartIdx || lv_Vm.beforeToEndIdx !== lv_Vm.afterToEndIdx) ) { // 수령기간 동일하나 시작/종료년월이 다른경우
              lv_Vm.isPeriedFlag = true
              lv_Vm.toPeriedDiff = '기간상이'
            } else {
              lv_Vm.isPeriedFlag = false
              lv_Vm.toPeriedDiff = t_afterAgeDiff- t_beforeAgeDiff
            }

            console.log('beforePAnutSum>>>', beforePAnutSum)
            console.log('afterPAnutSum>>>', afterPAnutSum)
            console.log('beforeToAgeDataList>>>', lv_Vm.beforeToAgeDataList)
            console.log('afterToAgeDataList>>>', lv_Vm.afterToAgeDataList)
            console.log('afterPAnutMinSum>>>', afterPAnutMinSum)
            console.log('afterPAnutMaxSum>>>', afterPAnutMaxSum)
            console.log('lv_Vm.toPeriedDiff>>>', lv_Vm.toPeriedDiff)
            console.log('bothPAnutSum>>>', bothPAnutSum)
          
            lv_Vm.bothPAnutList = bothPAnutSum
            lv_Vm.npensSumList = npensSum
            lv_Vm.rtpsSumList = rtpsSum
            console.log('npensSum>>>', lv_Vm.npensSumList)
            console.log('rtpsSum>>>', lv_Vm.rtpsSumList)
            lv_Vm.npensStartIdx = lv_Vm.npensSumList.findIndex(item => item.price > 0)
            lv_Vm.npensSumList.forEach((e, idx) => {
              if (idx > lv_Vm.npensStartIdx) {
                if (e === 0) {
                  lv_Vm.npensEndIdx = idx - 1
                } else {
                  lv_Vm.npensEndIdx = idx
                }
              }
            })
            lv_Vm.rtpsStartIdx = lv_Vm.rtpsSumList.findIndex(item => item.price > 0)
            lv_Vm.npensSumList.forEach((e, idx) => {
              if (idx > lv_Vm.npensStartIdx) {
                if (e === 0) {
                  lv_Vm.rtpsEndIdx = idx - 1
                } else {
                  lv_Vm.rtpsEndIdx = idx
                }
              }
            })
            console.log('lv_Vm.npensStartIdx', lv_Vm.npensStartIdx)
            console.log('lv_Vm.npensEndIdx', lv_Vm.npensEndIdx)
            console.log('rtpsStartIdx>>', lv_Vm.rtpsStartIdx)
            console.log('lv_Vm.rtpsEndIdx', lv_Vm.rtpsEndIdx)
            window.vue.getStore('progress').dispatch('HIDE')
          }).catch(function (error2) {
            window.vue.getStore('progress').dispatch('HIDE')
            console.log(error2)
          })
        }).catch(function (error1) {
            window.vue.getStore('progress').dispatch('HIDE')
          console.log(error1)
        })
      },

      /************************************************************************************************
      * Function명 : fn_SetNpensCard
      * 설명       : 국민연금 셋팅
      ************************************************************************************************/
      fn_SetNpensCard() {
        //let anpsn = Math.floor(this.pensData.gtpNpens.anpsn) // 연금 연액
        //let itemArray = []
        this.pensData.gtpNpens.forEach(data => {
          let anpsnItem = {}
          anpsnItem.name = '국민연금'
          anpsnItem.isRecommendProduct = false
          anpsnItem.isOtherProduct = true // 타사
          anpsnItem.hasProduct = true // 보유
          anpsnItem.canDelete = false
          anpsnItem.status = '타사' // 추가하기 등록건은 타사, 모니모 조회건은 보유
          anpsnItem.monthFlag = 'M'
          anpsnItem.monthPrice = '-'
          anpsnItem.checked = true
          // itemArray.push(['-', anpsn, '-', '-'].slice()) // 개인연금란을 제외하곤 셋팅하지 않는다.
          // anpsnItem.details = itemArray
          anpsnItem.yearPrice = data.anpsn
          //anpsnItem.anpsn = data.anpsn
          anpsnItem.NpenFlag = true
          this.npensProductList.push(anpsnItem)
        })
        this.allRetProductList = this.allRetProductList.concat(this.npensProductList)
      },

      /************************************************************************************************
      * Function명 : fn_SetRtpsCard
      * 설명       : 퇴직연금 셋팅
      ************************************************************************************************/
      fn_SetRtpsCard () {
        //let rtps = Math.floor(this.pensData.exptPpsn.anpsn) // 연금 연액
        //let itemArray = []
        this.pensData.exptPpsn.forEach(data => {
          let anpsnItem = {}
          anpsnItem.name = '퇴직연금'
          anpsnItem.isRecommendProduct = false
          anpsnItem.isOtherProduct = true // 타사
          anpsnItem.hasProduct = true // 보유
          anpsnItem.canDelete = false
          anpsnItem.status = '타사' // 추가하기 등록건은 타사, 모니모 조회건은 보유
          anpsnItem.monthFlag = 'M'
          anpsnItem.monthPrice = '-'
          anpsnItem.checked = true
          // itemArray.push(['-', '-', rtps, '-'].slice()) // 개인연금란을 제외하곤 셋팅하지 않는다.
          // anpsnItem.details = itemArray
          anpsnItem.yearPrice = data.anpsn
          //anpsnItem.anpsn = data.anpsn
          anpsnItem.RtpsFlag = true
          this.exptProductList.push(anpsnItem)
        })
        this.allRetProductList = this.allRetProductList.concat(this.exptProductList)
      },

      /************************************************************************************************
      * Function명 : fn_PrpslList
      * 설명       : 당사, 타사, 국민, 퇴직 연금 합치기(가설포함)
      * type       before(제안전), after(제안후)
      ************************************************************************************************/
      fn_PrpslList (lv_Vm, type) {
        return new Promise( function (resolve, reject) {
          let tcompList = lv_Vm.pensData.tcomAnutList // 당사
          let otcompList = lv_Vm.pensData.otcomAnutList // 타사
          let npens = lv_Vm.pensData.gtpNpens // 국민
          let rtps = lv_Vm.pensData.exptPpsn // 퇴직
          let rtnList = []
          let anutBgnAge = 0
          let anpsn = [] // 기간별 그래프 영역에서 사용하도록 연금액 형태변환
          console.log('tcompList>>>', tcompList)
          console.log('lv_Vm.pensData>>>', lv_Vm.pensData)

          // 당사연금 세팅
          for (let i in tcompList) {
            if (!(type === 'after' && tcompList[i].prpslStatCd === '02')) { // 제안후에서 제외되었을 경우 값을 담지 않음
              console.log('>>>>>>', tcompList[i])
              let tmpObj = {}
              anutBgnAge = tcompList[i].anutBgnMnisdAge
              tmpObj.type = 'tcomp'
              tmpObj.compNm = tcompList[i].insrCoNm
              tmpObj.prdtNm = tcompList[i].prdtNm
              tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'tcomp', anutBgnAge, tcompList[i].anpsnStartAmount)
              rtnList.push(tmpObj)
            }
          }

          // 타사연금 세팅
          for (let j in otcompList) {
            if (!(type === 'after' && otcompList[j].prpslStatCd === '02')) { // 제안후에서 제외되었을 경우 값을 담지 않음
              let tmpObj = {}
              anutBgnAge = otcompList[j].otcomAnutBgnAge
              //anutBgnAge = ctConstants.otcomAnutBgnAge // 타사연금개시나이 고정값
              tmpObj.type = 'otcomp'
              tmpObj.compNm = otcompList[j].insrCoNm
              tmpObj.prdtNm = otcompList[j].prdtNm
              tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'otcomp', anutBgnAge, otcompList[j].anpsn * 10000, otcompList[j].otcomAnutEndAge)
              // tmpObj.anpsn = this.$bizUtil.ctUtils.fn_FirstColor(tmpObj.anpsn, 'prm')
              rtnList.push(tmpObj)
            }
          }

          // 국민연금 세팅
          let npensAnpsn = []
          if (npens.length > 0) {
            npensAnpsn = npens[0].anpsnList
            anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'npens', 0, npensAnpsn)
            //anpsn = lv_Vm.$bizUtil.ctUtils.fn_FirstColor(anpsn, 'prm')
            let npensObj = {
              type: 'npens',
              compNm: '-',
              prdtNm: '국민연금',
              anpsn: anpsn
            }
            if ( lv_Vm.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 국민연금 정보를 구한다
              rtnList.push(npensObj)
            }
          }
          // 퇴직연금 세팅
          let rtpsAnpsn = []
          if (rtps.length > 0 ) {
            rtpsAnpsn = rtps[0].anpsnList
            anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'rtps', 0, rtpsAnpsn)
            //anpsn = lv_Vm.$bizUtil.ctUtils.fn_FirstColor(anpsn, 'prm')
            let rtpsObj = {
              type: 'rtps',
              compNm: '-',
              prdtNm: '퇴직연금',
              anpsn: anpsn
            }
            if ( lv_Vm.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 퇴직 정보를 구한다
              rtnList.push(rtpsObj)
            }
          }
          if (type !== 'after') { // 설계전일때는 여기서 resolve로 넘겨주고, 설계후일때는 가설로직 마지막 부분에 resolve로 넘겨줌.
            resolve(rtnList)
          }

          // 1. 가입설계VO에 들어있는 상품 모두를 해약환급금조회IF 에 태운다.
          // 2. IF에서 나온 값에 따라서 연금유형을 알아낸다.
          // 2-1. 연금유형에 따라 금액을 산출한다.
          // 2-2. 종신보험 연금전환 가능 상품일 경우에는 나이를 세팅하여 연금 여부 함수(?)에 넣는다
          // 2-2-1. 나온 연금 유형에 따라서 금액을 산출한다. (일반,저축,변액 연금이 나옴)
          if (type === 'after') {
            let covanDt = lv_Vm.getStore('ctStore').getters.getCovanData.data
            let custVO = covanDt.gtplCllgHoshVO.gtplCllgCustVO
            let custInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
            let entplPrpslPrdtArray = []
            let param = {inEntplVO: {planId: ''}}
            let promiseArray = []
            let countResp = 0
            let entplList = lv_Vm.getStore('ctStore').getters.getCustcovrAnly.data.entplVO // 가입설계리스트 인터페이스(txTSSCT24S1) 에서 조회한 값
            let entplPlanIdList = []

            // 현재고객의 가설제안상품 추출
            for (let i in custVO) {
              if (custVO[i].chnlCustId === custInfo.chnlCustId) {
                entplPrpslPrdtArray = custVO[i].entplPrpslPrdtVO
              }
            }
            // 계피동일건만 && 월보험료가 1억미만 상품만 추린다.(연금계산기 가설목록 기준)
            entplPrpslPrdtArray = entplPrpslPrdtArray.filter( x => { return x.mnisdChnlCustId === custInfo.chnlCustId && x.contrNm === custInfo.custNm })
            entplPrpslPrdtArray = entplPrpslPrdtArray.filter( x => { return x.prm.longAmount < 100000000 })
            console.log('entplPrpslPrdtArray>>>>', entplPrpslPrdtArray)
            // 가입설계리스트(txTSSCT24S1)에 존재하는 설계ID만 해약환금급을 보낸다.
            // (현재고객의 기계약VO에 들어있지만, 가설이 되지 않는(가설목록에없는) 상품을 제외하기 위한 작업)
            for (let j in entplList) {
              entplPlanIdList.push(entplList[j].planId)
            }
            for (let k in entplPrpslPrdtArray) {
              if (entplPlanIdList.indexOf(entplPrpslPrdtArray[k].planId) > -1) {
                param.inEntplVO.planId = entplPrpslPrdtArray[k].planId
                promiseArray.push(lv_Vm.$bizUtil.ctUtils.fn_GtEntpLanutReg(lv_Vm, param))
              }
            }
            Promise.all(promiseArray).then(function (response) {
              console.log('Promise.all RESPONSE :: ', response)
              for (let i in response) {
                countResp++
                // 해약환급금 조회를 제대로 호출했지만, 정상코드 외의 값으로 오는 것들은 제외.
                if (response[i] === 'NONE' || response[i].msgComm.msgCd !== 'EFWS003') {
                  continue
                }
                let entplPrpslPrdt = entplPrpslPrdtArray[i]
                let trg = response[i].body
                let lanutType = lv_Vm.$bizUtil.ctUtils.fn_GtLanutType(trg) // 연금종류판단
                console.log('해약환급금 IF (가설 id) :: ', entplPrpslPrdt.planId, lanutType, response[i])
                let rtnAnutObj = {}
                let tmpObj = {}
                switch (lanutType) {
                  case ctConstants.annuityInfoMap.conversion.code : // 종신보험 연금전환
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_GtConversionVal(trg, false)
                    break

                  case ctConstants.annuityInfoMap.power.code : // 파워즉시
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_PowerAnnuityPro(trg, false)
                    break
                  case ctConstants.annuityInfoMap.normal.code : // 일반연금
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_NrmAnnuityPro(trg, false)
                    break

                  case ctConstants.annuityInfoMap.anutAmt20.code : // 저축연금
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_Anut20AnnuityPro(trg, false)
                    break

                  case ctConstants.annuityInfoMap.variable.code : // 변액연금
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_VariableAnnuityPro(trg, false)
                    break
                  case '' : // 공백
                  default : // 해당없음
                  // fn_GtLanutType에서 해당없음은 공백('')으로 보내므로 모두 잡음
                    console.log('해당없음')
                    break
                }
                console.log('rtnAnutObj>>>', rtnAnutObj)
                if (lanutType !== '') {
                  tmpObj.type = 'entpl'
                  tmpObj.compNm = '삼성생명'
                  tmpObj.prdtNm = entplPrpslPrdt.prdtNm
                  tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'entpl', rtnAnutObj.openAge, rtnAnutObj.val)
                  tmpObj.anutType = lanutType
                  rtnList.push(tmpObj)
                }
              }
              if (countResp === entplPrpslPrdtArray.length) { // 설계후일때는 가설로직 마지막 부분에 resolve로 넘겨줌. 설계전일때는 fn_PrpslList 함수마지막에 resolve로 넘겨줌.
                resolve(rtnList)
              }
            }).catch(function (error) {
              window.vue.error(error)
              console.log('error ::: ' + error)
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('CO')['ESYSC002'])
              reject(error)
            })
          }
        })
      },

      /************************************************************************************************
      * Function명 : fn_DoConversionProc
      * 설명       : 종신보험 연금전환에 대한 55세 기준의 예상연금액을 화면에 셋팅한다.
      ************************************************************************************************/
      fn_DoConversionProc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_GtConversionVal(trgItem, true)
        // 종신보험 연금 전환은 변액형과 일반형으로 올수 있으므로 회신받은 값을 확인후 분기처리한다.
        let yearPrice = 0
        if (Object.keys(trgVl).length === 0) {
          // 해약처리가 비정상일때
          return yearPrice
        } else {
          // 값이 정상일때 변액형인지 아닌지를 구별한다.
          if (Array.isArray(trgVl.val)) { // 변액형일때
            let itemArrayInner = [] // 연금 한줄(개인연금)
            for (let trgVlIdx in trgVl.val) {
              let innrItem = trgVl.val[trgVlIdx]
              itemArrayInner.push({percentage: innrItem.lvyIrt, value: Math.floor(innrItem.anutAmt / 10000)})// 변액에 따른 수익율하나
            }
            yearPrice = itemArrayInner
            return yearPrice
          } else { // 변액형이 아닐때
            yearPrice = Math.floor(trgVl.val / 10000)
            return yearPrice
          }
        }
      },

      /************************************************************************************************
      * Function명 : fn_DoPowerProc
      * 설명       : 파워즉시연금에 대한 화면을 셋팅한다.
      ************************************************************************************************/
      fn_DoPowerProc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_PowerAnnuityPro(trgItem, true) // 65세 미만임을 체크한다.
        // console.log(trgVl)
        // 파워즉시연금 정보가 없으면 0 처리
        let pensAmt = 0
        if ( !this.$bizUtil.isEmpty(trgVl) ) {
          if ( !this.$bizUtil.isEmpty(trgVl.val) ) {
            pensAmt = trgVl.val
          }
        }
        let yearPrice = Math.floor(pensAmt / 10000)
        return yearPrice

      },

      /************************************************************************************************
      * Function명 : fn_DoNormalProc
      * 설명       : 일반연금에 대한 화면을 셋팅한다.
      ************************************************************************************************/
      fn_DoNormalProc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_NrmAnnuityPro(trgItem, true) // 65세 미만임을 체크한다.
        // console.log(trgVl)
        // 일반연금 정보가 없으면 0 처리
        let pensAmt = 0
        if ( !this.$bizUtil.isEmpty(trgVl) ) {
          if ( !this.$bizUtil.isEmpty(trgVl.val) ) {
            pensAmt = trgVl.val
          }
        }
        let yearPrice = Math.floor(pensAmt / 10000)
        return yearPrice
      },
     /************************************************************************************************
     * Function명 : fn_DoPlus20Proc
     * 설명       : 저축연금에 대한 화면을 셋팅한다.
     ************************************************************************************************/
      fn_DoAnutAmt20Proc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_Anut20AnnuityPro(trgItem, true) // 65세 미만임을 체크한다.
        // console.log(trgVl)
        // 저축연금 정보가 없으면 0 처리
        let pensAmt = 0
        if ( !this.$bizUtil.isEmpty(trgVl) ) {
          if ( !this.$bizUtil.isEmpty(trgVl.val) ) {
            pensAmt = trgVl.val
          }
        }
        let yearPrice = Math.floor(pensAmt / 10000)
        return yearPrice
      },

      /************************************************************************************************
      * Function명 : fn_DoVariableProc
      * 설명       : 변액연금에 대한 화면을 셋팅한다.
      ************************************************************************************************/
      fn_DoVariableProc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_VariableAnnuityPro(trgItem, true)
        let itemArray = [] // 카드
        let itemArrayInner = [] // 연금 한줄(개인연금)
        for (let trgVlIdx in trgVl.val) {
          let innrItem = trgVl.val[trgVlIdx]
          itemArrayInner.push({percentage: innrItem.lvyIrt, value: Math.floor(innrItem.anutAmt / 10000)})// 변액에 따른 수익율하나
        }
        let yearPrice = itemArrayInner
        return yearPrice
      },

     /************************************************************************************************
     * Function명 : fn_CalcAmount
     * 설명       : 개인연금 전후 확인
     ************************************************************************************************/
      fn_CalcAmount (lv_vm) {
        let cfgList = []
        cfgList = cfgList.concat(lv_vm.productList.slice()) // 개인
        cfgList = cfgList.concat(lv_vm.npensProductList.slice()) // 국민
        cfgList = cfgList.concat(lv_vm.exptProductList.slice()) // 퇴직
        let totalList = []
        totalList = totalList.concat(cfgList.slice()) // 개인연금내역 전부담기
        totalList = totalList.concat(lv_vm.productConfigList.slice()) // 가설+제외 내용 전부담기
        console.log('가설제외 기존!!!', cfgList)
        console.log('가설포함 기존!!!', totalList)
        let prePrivateAmountLow = 0 // 설계전 개인연금 최소
        let prePrivateAmountHigh = 0 // 설계전 개인연금 최대
        let preNpenLow = 0 // 설계전 국민연금 최소
        let preNpenHigh = 0 // 설계전 국민연금 최대
        let preRtpsLow = 0 // 설계전 퇴직연금 최소
        let preRtpsHigh = 0 // 설계전 퇴직연금 최대
        let preTotalLow = 0 // 설계전 총합 최소
        let preTotalHigh = 0 // 설계전 총합 최대
        let postPrivateAmountLow = 0 // 설계후 개인연금 최소
        let postPrivateAmountHigh = 0 // 설계후 개인연금 최대
        let postNpenLow = 0 // 설계후 국민연금 최소
        let postNpenHigh = 0 // 설계후 국민연금 최대
        let postRtpsLow = 0 // 설계후 퇴직연금 최소
        let postRtpsHigh = 0 // 설계후 퇴직연금 최대
        let postTotalLow = 0 // 설계후 총합 최소
        let postTotalHigh = 0 // 설계후 총합 최대
        for (let totalListIdx in totalList) {
          let totalItem = totalList[totalListIdx]
          let hasProduct = totalItem.hasProduct // 보유여부
          let npenFlag = totalItem.NpenFlag // 국민연금 여부
          let rtpsFlag = totalItem.RtpsFlag // 퇴직연금 여부
          if (npenFlag) {
            // 국민연금이면 다른 로직을 탈필요가 없음
            // 국민연금은 최소/최대가 없지만 양식을맞춰둔다.
            console.log('국민 totalItem.yearPrice>>>', totalItem.yearPrice)
            preNpenLow += totalItem.yearPrice
            preNpenHigh += totalItem.yearPrice
          } else if (rtpsFlag) {
            console.log('퇴직 totalItem.yearPrice>>>', totalItem.yearPrice)
            // 퇴직연금이면 다른 로직을 탈필요가 없음
            // 퇴직연금이면 최소/최대가 없지만 양식을맞춰둔다.
            preRtpsLow += totalItem.yearPrice
            preRtpsHigh += totalItem.yearPrice
          } else {
            // 그외 상품일때는 개인연금(설계전으로 계산하기 위해 보유 상품만 더한다.)
            if (hasProduct) {
              // 변액인경우와 아닌경우를 나누어 처리한다.
              if (Array.isArray(totalItem.yearPrice)) {
                let inrTotalItemLow = 0
                let inrTotalItemHigh = 0
                for (let iInrTotalItemIdx in totalItem.yearPrice) {
                  let inrTotalItemVal = totalItem.yearPrice[iInrTotalItemIdx]
                  if (inrTotalItemLow === 0) {
                    inrTotalItemLow = inrTotalItemVal
                  }
                  if (inrTotalItemHigh === 0) {
                    inrTotalItemHigh = inrTotalItemVal
                  }
                  if (inrTotalItemVal < inrTotalItemLow) {
                    inrTotalItemLow = inrTotalItemVal
                  }
                  if (inrTotalItemVal > inrTotalItemHigh) {
                    inrTotalItemHigh = inrTotalItemVal
                  }
                }
                prePrivateAmountLow += inrTotalItemLow
                prePrivateAmountHigh += inrTotalItemHigh
              } else {
                let trgVal = totalItem.yearPrice
                // if (trgVal === '-') {
                //   trgVal = 0
                // }
                prePrivateAmountLow += trgVal
                prePrivateAmountHigh += trgVal
              }
            }
          }
        }
        // 설계전을 구했다면 설계전 총합도 구한다.
        preTotalLow = prePrivateAmountLow + preNpenLow + preRtpsLow
        preTotalHigh = prePrivateAmountHigh + preNpenHigh + preRtpsHigh

        for (let cfgListIdx in cfgList) {
          let cfgItem = cfgList[cfgListIdx]
          let npenFlag = cfgItem.NpenFlag // 국민연금 여부
          let rtpsFlag = cfgItem.RtpsFlag // 퇴직연금 여부
          if (npenFlag) {
            // 국민연금이면 다른 로직을 탈필요가 없음
            // 국민연금은 최소/최대가 없지만 양식을맞춰둔다.
            postNpenLow += cfgItem.yearPrice
            postNpenHigh += cfgItem.yearPrice
          } else if (rtpsFlag) {
            // 퇴직연금이면 다른 로직을 탈필요가 없음
            // 퇴직연금이면 최소/최대가 없지만 양식을맞춰둔다.
            postRtpsLow += cfgItem.yearPrice
            postRtpsHigh += cfgItem.yearPrice
          } else {
            // 그외 상품일때는 개인연금(설계후로 제안상품 모두를 더한다.)
            console.log('cfgItem>>>', cfgItem)
            let trgItem = cfgItem.yearPrice
            console.log('trgItem>>>', trgItem)
            // 변액인경우와 아닌경우를 나누어 처리한다.
            if (Array.isArray(trgItem)) {
              let inrCfgItemLow = 0
              let inrCfgItemHigh = 0
              for (let inrCfgItemIdx in trgItem) {
                let inrCfgItem = trgItem[inrCfgItemIdx]
                let inrCfgItemVal = inrCfgItem.value
                if (inrCfgItemLow === 0) {
                  inrCfgItemLow = inrCfgItemVal
                }
                if (inrCfgItemHigh === 0) {
                  inrCfgItemHigh = inrCfgItemVal
                }
                if (inrCfgItemVal < inrCfgItemLow) {
                  inrCfgItemLow = inrCfgItemVal
                }
                if (inrCfgItemVal > inrCfgItemHigh) {
                  inrCfgItemHigh = inrCfgItemVal
                }
              }
              postPrivateAmountLow += inrCfgItemLow
              postPrivateAmountHigh += inrCfgItemHigh
            } else {
              let trgVal = trgItem
              // if (trgVal === '-') {
              //   trgVal = 0
              // }
              postPrivateAmountLow += trgVal
              postPrivateAmountHigh += trgVal
            }
          }
        }
        console.log('aaaaaaaaaassss',postPrivateAmountLow, postPrivateAmountHigh)
        // 설계후을 구했다면 설계전 총합도 구한다.
        postTotalLow = postPrivateAmountLow + postNpenLow + postRtpsLow
        postTotalHigh = postPrivateAmountHigh + postNpenHigh + postRtpsHigh

        this.totAmountData.prePrivateAmount = this.fn_VldCmbBefore(prePrivateAmountLow, prePrivateAmountHigh) // 설계전 개인연금
        this.totAmountData.postPrivateAmount = this.fn_VldCmbBefore(postPrivateAmountLow, postPrivateAmountHigh) // 설계후 개인연금
        this.totAmountData.preNpen = this.fn_VldCmbBefore(preNpenLow, preNpenHigh) // 국민연금
        //this.totAmountData.postNpen = this.fn_VldCmbBefore(postNpenLow, postNpenHigh) // 국민연금
        this.totAmountData.preRtps = this.fn_VldCmbBefore(preRtpsLow, preRtpsHigh) // 퇴직연금
        //this.totAmountData.postRtps = this.fn_VldCmbBefore(postRtpsLow, postRtpsHigh) // 퇴직연금
        let t_totAmount = 0
        let t_allTot = 0

        let tmp_allByPrivatePercent = 0
        let tmp_allByNpenPercent = 0
        let tmp_allByRtpsPercent = 0

        if ((preTotalLow === preTotalHigh) && (postTotalLow === postTotalHigh)) { // 변액 아님
          let t_pre = Number(this.totAmountData.prePrivateAmount.replaceAll(',', ''))
          let t_post = Number(this.totAmountData.postPrivateAmount.replaceAll(',', ''))
          t_totAmount = Number(this.totAmountData.postPrivateAmount.replaceAll(',', ''))
          t_allTot = Number(this.totAmountData.preNpen.replaceAll(',', '')) + Number(this.totAmountData.preRtps.replaceAll(',', '')) + t_totAmount
          console.log('t_allTot>>', t_allTot)
          this.totAmountData.privateAmountDiff = t_post - t_pre
          this.totAmountData.prePrivateAmountPercent = t_pre >= t_post ? '100%' : `${Math.floor((t_pre / t_post) * 100)}%`
          this.totAmountData.postPrivateAmountPercent = t_post >= t_pre  ? '100%' : `${Math.floor((t_post / t_pre) * 100)}%`

          tmp_allByPrivatePercent = (Math.floor((t_post / t_allTot) * 100))
          tmp_allByNpenPercent = (Math.floor((Number(this.totAmountData.preNpen.replaceAll(',', '')) / t_allTot) * 100))
          tmp_allByRtpsPercent = (Math.floor((Number(this.totAmountData.preRtps.replaceAll(',', '')) / t_allTot) * 100))
          if (t_post > 0){
            tmp_allByPrivatePercent = 100 - (tmp_allByNpenPercent+tmp_allByRtpsPercent)
          }
          
          /* this.totAmountData.allByPrivatePercent = t_allTot !== 0 ? `${(Math.floor((t_post / t_allTot) * 100))}%` : '0%'
          this.totAmountData.allByNpenPercent = t_allTot !== 0 ? `${(Math.floor((Number(this.totAmountData.preNpen.replaceAll(',', '')) / t_allTot) * 100))}%` : '0%'
          this.totAmountData.allByRtpsPercent = t_allTot !== 0 ? `${(Math.floor((Number(this.totAmountData.preRtps.replaceAll(',', '')) / t_allTot) * 100))}%` : '0%' */
          this.totAmountData.allByPrivatePercent = t_allTot !== 0 ? `${tmp_allByPrivatePercent}%` : '0%'
          this.totAmountData.allByNpenPercent = t_allTot !== 0 ? `${tmp_allByNpenPercent}%` : '0%'
          this.totAmountData.allByRtpsPercent = t_allTot !== 0 ? `${tmp_allByRtpsPercent}%` : '0%'
        } else { // 변액
          // 변액은 최대값을 기준으로 계산한다
          let t_pre = Number(this.totAmountData.prePrivateAmount.replaceAll(',', ''))
          let t_post = Number(this.totAmountData.postPrivateAmount.split('~')[1].replaceAll(',', ''))
          t_totAmount = Number(this.totAmountData.postPrivateAmount.split('~')[1].replaceAll(',', ''))
          t_allTot = Number(this.totAmountData.preNpen.replaceAll(',', '')) + Number(this.totAmountData.preRtps.replaceAll(',', '')) + t_totAmount
          
          this.totAmountData.privateAmountDiff = t_post - t_pre
          this.totAmountData.prePrivateAmountPercent = t_pre >= t_post ? '100%' : `${Math.floor((t_pre / t_post) * 100)}%`
          this.totAmountData.postPrivateAmountPercent = t_post >= t_pre  ? '100%' : `${Math.floor((t_post / t_pre) * 100)}%`

          tmp_allByPrivatePercent = (Math.floor((t_post / t_allTot) * 100))
          tmp_allByNpenPercent = (Math.floor((Number(this.totAmountData.preNpen.replaceAll(',', '')) / t_allTot) * 100))
          tmp_allByRtpsPercent = (Math.floor((Number(this.totAmountData.preRtps.replaceAll(',', '')) / t_allTot) * 100))
          if (t_post > 0){
            tmp_allByPrivatePercent = 100 - (tmp_allByNpenPercent+tmp_allByRtpsPercent)
          }

          /* this.totAmountData.allByPrivatePercent = t_allTot !== 0 ? `${(Math.floor((t_post / t_allTot) * 100))}%` : '0%'
          this.totAmountData.allByNpenPercent = t_allTot !== 0 ? `${(Math.floor((Number(this.totAmountData.preNpen.replaceAll(',', '')) / t_allTot) * 100))}%` : '0%'
          this.totAmountData.allByRtpsPercent = t_allTot !== 0 ? `${(Math.floor((Number(this.totAmountData.preRtps.replaceAll(',', '')) / t_allTot) * 100))}%` : '0%' */
          this.totAmountData.allByPrivatePercent = t_allTot !== 0 ? `${tmp_allByPrivatePercent}%` : '0%'
          this.totAmountData.allByNpenPercent = t_allTot !== 0 ? `${tmp_allByNpenPercent}%` : '0%'
          this.totAmountData.allByRtpsPercent = t_allTot !== 0 ? `${tmp_allByRtpsPercent}%` : '0%'
        }
        
        let t_total = Number(this.totAmountData.preNpen.replaceAll(',', '')) + Number(this.totAmountData.preRtps.replaceAll(',', '')) + Number(t_totAmount)
        this.totAmountData.preTotal = this.fn_VldCmbBefore(preTotalLow, preTotalHigh)
        this.totAmountData.postTotal = this.fn_VldCmbBefore(postTotalLow, postTotalHigh)
        console.log('this.totAmountData>>>', this.totAmountData)
        this.$forceUpdate()
        this.$emit('totAmountData', this.totAmountData)
      },

      /************************************************************************************************
      * Function명 : fn_VldCmbBefore
      * 설명       : 개인연금 전후 확인
      ************************************************************************************************/
      fn_VldCmbBefore (low, high) {
        console.log('low>>>', low)
        console.log('high>>>', high)
        if (low === high) {
          // 설계전 -> 최저와 최고가 같으면 변액이 아님-> 1개의 값 -> 임시적으로 낮은값으로 결정
          return this.$bizUtil.numberWithCommasByNumber(low)
        } else {
          if (low > high) {
            // 설계전 -> 유효성검사로 최저가와 최고가가 맞지 않으면 최고가로 리턴 -> 만약 유효성정책이 따로 생긴다면 적용해야 한다.
            return this.$bizUtil.numberWithCommasByNumber(low)
          } else {
            // 정상적으로 범위를 표현할수 있을때
            return this.$bizUtil.numberWithCommasByNumber(low).concat('~').concat(this.$bizUtil.numberWithCommasByNumber(high))
          }
        }
      },

      /************************************************************************************************
      * Function명 : fn_productEdit
      * 설명       : 상품수정 버튼 클릭
      ************************************************************************************************/
      fn_productEdit() {
        this.editTab = '1'
        for (let i in this.allRetProductList) {
          let tmp = this.allRetProductList[i]
          tmp.checked = true
        }
        for (let j in this.productConfigList) {
          let tmp = this.productConfigList[j]
          tmp.checked = false
        }
        this.$forceUpdate()
        this.$refs.refProductEdit.open()
      },

      /************************************************************************************************
      * Function명 : fn_EditConfirm
      * 설명       : 상품수정 확인
      ************************************************************************************************/
      fn_EditConfirm() {
        let lv_Vm = this
        window.vue.getStore('progress').dispatch('FULL')
        window.vue.getStore('progress').dispatch('SHOW')
        for (let i in this.allRetProductList) { // 보유탭 상품제거 case
          let t_product = this.allRetProductList[i]
          console.log('t_product>>>', t_product)
          if (!t_product.checked) { // 상품제거
            if (t_product.hasProduct) { // 보유상품 삭제처리
              this.$bizUtil.ctUtils.fn_DelAcontPrpslPrdt(lv_Vm, t_product).then(function (response) { // 기계약 제거(txTSSCT20D1)
                console.log('기계약 삭제 응답값>>', response)
                window.vue.getStore('progress').dispatch('HIDE')
                lv_Vm.$emit('reLoad') // 화면 리로드 & 데이터 갱신
                lv_Vm.$refs.refProductEdit.close()
              }).catch(function (error) {
                window.vue.getStore('progress').dispatch('HIDE')
                window.vue.error(error)
              })
            } else { // 추천상품 삭제처리
              this.$bizUtil.ctUtils.fn_DelEntplPrpslPrdt(lv_Vm, t_product).then(function (response) { // 가입설계제안 제거(txTSSCT22D1)
                console.log('가입설계 삭제 응답값은?>>', response)
                window.vue.getStore('progress').dispatch('HIDE')
                lv_Vm.$emit('reLoad') // 화면 리로드 & 데이터 갱신
                lv_Vm.$refs.refProductEdit.close()
              }).catch(function (error) {
                window.vue.getStore('progress').dispatch('HIDE')
                window.vue.error(error)
              })
            }
          }
        }
        for (let j in this.productConfigList) { // 최근가입설계 탭 상품추가 case
          let t_product = this.productConfigList[j]
          if (t_product.checked) {  // 상품 추가
            if (t_product.hasProduct) {
              this.$bizUtil.ctUtils.fn_InsAcontPrpslPrdt(lv_Vm, t_product).then(function (response) { // 기계약건 추가(txTSSCT21I1)
                console.log('기계약건 추가>>', response)
                window.vue.getStore('progress').dispatch('HIDE')
                lv_Vm.$emit('reLoad') // 화면 리로드 & 데이터 갱신
                lv_Vm.$refs.refProductEdit.close()
              }).catch(function (error) {
                window.vue.getStore('progress').dispatch('HIDE')
                window.vue.error(error)
              })
            } else { // 가입설계 데이터들
              console.log('가입설계 추가', t_product.checked)
              Promise.all([lv_Vm.$bizUtil.ctUtils.fn_InsEntplPrpslPrdt(lv_Vm, t_product) // 가입설계제안상품 추가(txTSSCT23I1)
              , lv_Vm.fn_GtEntpLanutReg(t_product)]) // detail 셋팅
                .then(function (response) {
                  console.log('response>>>>', response)
                  let inEntp = response[0].body.inEntplVO
                  let outEntp = response[0].body.outEntplPrpslPrdtVO[0] // 추가에 대한 답변이므로 최상위는 1개
                  outEntp.padCylCdNm = inEntp.padCylCdNm
                  outEntp.smtotPrm = outEntp.prm // output에도 존재하지만 이름을 바꿔서 재셋팅
                  outEntp.prpslStatCd = inEntp.prpslStatCd
                  outEntp.mdfcDtm = inEntp.mdfcDtm
                  outEntp.prcd = inEntp.prcd
                  outEntp.compAcontCovr = lv_Vm.$bizUtil.ctUtils.fn_GetThisYearPrpslPrdt(outEntp.entplPrpslPrdtCovrVO) //보장분석으로 부터 올해 보장분석을 구한다.
                  window.vue.getStore('progress').dispatch('HIDE')
                  lv_Vm.$emit('reLoad') // 화면 리로드 & 데이터 갱신
                  lv_Vm.$refs.refProductEdit.close()
                }).catch(function (error) {
                  window.vue.getStore('progress').dispatch('HIDE')
                  window.vue.error(error)
                })
            }
          }
        }
      },

      /************************************************************************************************
      * Function명 : fn_GtEntpLanutReg
      * 설명       : 
      ************************************************************************************************/
      fn_GtEntpLanutReg (item) {
        let lv_Vm = this
        this.$bizUtil.ctUtils.fn_GtEntpLanutRegProc(lv_Vm, item).then(function (response) {
          if (Object.keys(response).length === 0) {
            // 해약처리가 비정상일때
            // 아무것도 없는 내용을 셋팅시킨다.
            let itemArray = [] // 카드
            itemArray.push(['-', '-', '-', '-'].slice())
            item.details = itemArray
            lv_Vm.productList.push(item)
            // lv_Vm.productConfigList.splice(index, 1)
          } else {
            // 해약처리가 정상일때
            item.details = response// 상품에 대한 금액처리 부분을 만들어 적용해야 한다.
            lv_Vm.productList.push(item)
            // lv_Vm.productConfigList.splice(index, 1)
          }
          // 셋팅후 정렬 및 노출
          //lv_Vm.fn_CalcAmount(lv_Vm)
          Promise.resolve(response)
        }).catch(function (error) {
          Promise.reject(error)
        })
      },

      /************************************************************************************************
      * Function명 : fn_OpenPrdRtvModal
      * 설명       : 개인연금 상품별 수령금액 모달 오픈
      ************************************************************************************************/
      fn_OpenPrdRtvModal (param) {
        this.mockData = []
        switch(param) {
          case '1':
            this.modalTitle = '개인연금'
            this.modalFlag = true
            this.mockData = this.bothPAnutList
            break
          case '2':
            this.modalTitle = '국민연금'
            this.modalFlag = false
            this.mockData = this.npensSumList
            break
          case '3':
            this.modalTitle = '퇴직연금'
            this.modalFlag = false
            this.mockData = this.rtpsSumList
            break
        }
        this.$refs.refPAnut.open()
      },

      /******************************************************************************
      * Function명 : fn_MoveMSPPI001M
      * 설명       : 가입설계 화면 이동
      ******************************************************************************/
      fn_MoveMSPPI001M() {
        this.$router.push({ name: 'MSPPI001M', params: {zaAgtFileId: this.lv_BaseData.data.chnlCustId} })
      },
    }
  };
</script>
<style scoped>
</style>