<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 bgcolor-1">

      <div class="calculate-expand w100 mb20">
        <div class="fexTy3 fs18rem">
          <span class="fwm">연 연금액합계(65세 기준)</span>
        </div>

        <!-- status_value01~06 까지의 합은 100% 이여야 합니다. 소숫점때문에 100%가 넘어가거나 모질라면 틀어질것입니다. -->
        <div v-if="Object.keys(allSumData).length > 0" class="calculate-chart">
          <div class="chart-box status-box">
            <div class="mt20 fs14rem fexTy3">
              <span class="crTy-bk7">설계전</span>
              <span class="fwb">{{ allSumData.beforeSum }}</span>
            </div>
            <div class="bar fexTy5 mt5">
              <div class="status-con06" :class="{'end': (allSumData.beforeRtpsGraphPercent === 0 && allSumData.beforeNpensGraphPercent) }" :style="{ width: `${allSumData.beforePAnutGraphPercent}%` }" />
              <div class="status-con04" :class="{'end': allSumData.beforeRtpsGraphPercent === 0 }" :style="{ width: `${allSumData.beforeNpensGraphPercent}%` }" />
              <div class="status-con05" :class="{'end': allSumData.beforeRtpsGraphPercent }" :style="{ width: `${allSumData.beforeRtpsGraphPercent }%`}" />
            </div>
          </div>

          <div class="chart-box status-box">
            <div class="mt20 fs14rem fexTy3">
              <span class="fwb">설계후</span>
              <span class="fwb">{{ allSumData.afterSum }}</span>
            </div>
            <div class="bar fexTy5 mt5">
              <div class="status-con06" :class="{'end': (allSumData.afterNpensGraphPercent === 0 && allSumData.afterRtpsGraphPercent === 0) }" :style="{ width: `${allSumData.afterPAnutGraphPercent}%` }" />
              <div class="status-con04" :class="{'end': allSumData.afterRtpsGraphPercent === 0 }" :style="{ width: `${allSumData.afterNpensGraphPercent}%` }" />
              <div class="status-con05" :class="{'end': allSumData.afterRtpsGraphPercent }" :style="{ width: `${allSumData.afterRtpsGraphPercent }%`}" />
            </div>
          </div>

          <div class="fexTy3 mt4">
            <span class="fs14rem fexTy5 mr10"><span class="status-txt06"></span>개인연금</span>
            <span class="fs14rem flex-1 txt-right">
              <span class="dsInline valignM">{{ `${beforePAnutSumData}만원` }}</span>
              <span class="dsInline valignM">
                <mo-icon icon="msp-arrow-line2" class="ml4 mr4" />
                <span class="fwb">{{ `${afterPAnutSumData}만원` }}</span><!-- 11,395~13,120 만원 -->
              </span>              
            </span>
          </div>
          <div class="fexTy3 mt4">
            <span class="fs14rem fexTy5 mr10"><span class="status-txt04"></span>국민연금</span>
            <span class="fs14rem flex-1 txt-right">
              <span class="dsInline valignM"></span>
              <span class="dsInline valignM">
                <!-- <mo-icon icon="msp-arrow-line2" class="ml4 mr4" /> -->
                <span class="fwb">{{ `${npensSumData}만원`}}</span><!-- 11,395~13,120 만원 -->
              </span>              
            </span>
          </div>
          <div class="fexTy3 mt4">
            <span class="fs14rem fexTy5 mr10"><span class="status-txt05"></span>퇴직연금</span>
            <span class="fs14rem flex-1 txt-right">
              <span class="dsInline valignM"></span>
              <span class="dsInline valignM">
                <!-- <mo-icon icon="msp-arrow-line2" class="ml4 mr4" /> -->
                <span class="fwb">{{ `${rtpsSumData}만원` }}</span><!-- 11,395~13,120 만원 -->
              </span>              
            </span>
          </div>
        </div>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import UrBoxContainer from '~/src/_uidev/containers/box/urBoxContainer.vue'
import ctConstants from '@/config/constants/ctConstants'
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT309D",
    screenId: "MSPCT309D",
    components: {UrBoxContainer},
    props: {
      pPensData: {
        type: Object,
        default: {}
      },
      pComplete: {
        type: Boolean,
        default: false
      },
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.$emit('headerData', {}) // 헤더 초기화
      console.log('created 온로드')
      this.pensData = this.pPensData
      this.fn_graphAnutDataProc()
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        variableFlag:false,
        beforeList: [],
        afterList: [],
        beforePAnutSumData: 0,
        afterPAnutSumData: 0,
        npensSumData: 0,
        rtpsSumData: 0,
        allSumData: {},
        headerItem: {}, // 헤더 월 보험료
        includeProductList: [],
        planAfCovan: [],
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      foreg: function () { // 개인추가정보
        return this.getStore('ctStore').getters.getMeminfoData.data
      },
      lv_BaseData: function () {
        // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
      lv_CovanData: function () {
        // 고객 계약 데이터
        let returnValue = this.getStore('ctStore').getters.getCovanData.data
        return returnValue
      },
      TSSCT005MParam: function () {
        // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
    },
    watch: {
      pComplete() {
        if (this.pComplete) {
          //this.$emit('totAmountData', {})
          this.pensData = this.pPensData
          console.log('300M 완료되어서 309 로직 시작!!', this.pensData)
          this.fn_graphAnutDataProc()
        } else {
          console.log('309 Onload 완료!!')
        }
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      open2()   { this.$refs.MSPCT307D_B02.open();  },
      close2()  { this.$refs.MSPCT307D_B02.close(); },

      /************************************************************************************************
      * Function명 : fn_graphAnutDataProc
      * 설명       : 
      ************************************************************************************************/
      fn_graphAnutDataProc () {
        let lv_Vm = this
        window.vue.getStore('progress').dispatch('FULL')
        window.vue.getStore('progress').dispatch('SHOW')
        // let beforeList =  {} // 설계전 연금 목록
        // let afterList = {} // 설계후 연금 목록
        let beforePAnutSum = [0, 0, 0, 0, 0, 0] // 당사,타사,가설 연령별 합산금액 55,60,65,70,75,80
        let afterPAnutSum = [0, 0, 0, 0, 0, 0] // 당사,타사,가설 연령별 합산금액 55,60,65,70,75,80
        let afterPAnutMinSum = [0, 0, 0, 0, 0, 0] // 가설 변액연금 최소값들의 합
        let afterPAnutMaxSum = [0, 0, 0, 0, 0, 0] // 가설 변액연금 최대값들의 합
        let bothPAnutSum = [] // 당,타사 연령별 금액 합친 object
        let npens = [] // 국민연금 데이터
        let rtps = [] // 퇴직연금 데이터
        let npensSum = 0 // 국민연금 같은값끼리 셀 합친 데이터
        let rtpsSum = 0 // 퇴직연금 같은값끼리 셀 합친 데이터

        lv_Vm.fn_PrpslList(lv_Vm, 'before').then(function (response1) {
          lv_Vm.beforeList = response1
          lv_Vm.fn_PrpslList(lv_Vm, 'after').then(function (response2) {
            lv_Vm.afterList = response2
            console.log('lv_Vm.beforeList  :: ', lv_Vm.beforeList)
            console.log('lv_Vm.afterList  :: ', lv_Vm.afterList)

            // [설계전] 연령별 연금액 합산(당사, 타사)
            for (let h in lv_Vm.beforeList) {
              let itm = lv_Vm.beforeList[h]
              if (itm.type === 'tcomp' || itm.type === 'otcomp') {
                for (let j in beforePAnutSum) {
                  beforePAnutSum[j] += itm.anpsn[j].prm
                }
              } else if (itm.type === 'npens') {
                npens.push(itm)
              } else if (itm.type === 'rtps') {
                rtps.push(itm)
              }
            }

            // [설계후] 연령별 연금액 합산(당사, 타사, 가설)
            for (let i in lv_Vm.afterList) {
              let itm = lv_Vm.afterList[i]
              if (itm.type === 'tcomp' || itm.type === 'otcomp') {
                for (let j in afterPAnutSum) {
                  afterPAnutSum[j] += itm.anpsn[j].prm
                }
              } else if (itm.type === 'entpl') { // 가입설계
                if (itm.anutType === ctConstants.annuityInfoMap.variable.code ||
                  (itm.anutType === ctConstants.annuityInfoMap.conversion.code && itm.maxPrm !== undefined)) {// 변액일 경우 (종신보험연금전환 변액 포함)
                  lv_Vm.variableFlag = true
                  for (let j in afterPAnutSum) {
                    afterPAnutMinSum[j] += (itm.anpsn[j].minPrm ? Math.floor(itm.anpsn[j].minPrm / 10000) : 0)
                    // afterPAnutSum[j] += itm.anpsn[j].prm // 변액은 중간 값 넣지 않음.
                    afterPAnutMaxSum[j] += (itm.anpsn[j].maxPrm ? Math.floor(itm.anpsn[j].maxPrm / 10000) : 0)
                  }
                  console.log('변액2>>>', afterPAnutMinSum )
                  console.log('변액3>>>', afterPAnutMaxSum)
                // 변액외의 경우는 합산 (변액은 화면에서 합산)
                } else {
                  for (let j in afterPAnutSum) {
                    afterPAnutSum[j] += itm.anpsn[j].prm
                  }
                }
              }
            }

            for (let i in npens) {
              console.log('국민연금 65세 기준금액 확인', npens[i].anpsn[2].prm)
              npensSum += npens[i].anpsn[2].prm
            }
            for (let i in rtps) {
              console.log('퇴직연금 65세 기준금액 확인', rtps[i].anpsn[2].prm)
              rtpsSum += rtps[i].anpsn[2].prm
            }

            for (let i in beforePAnutSum) {
              let showAfterText = '' // 화면에 표시될 값
              if (lv_Vm.variableFlag) { // 변액일 경우
                let minValue = lv_Vm.$bizUtil.ctUtils.fn_SetNumber(afterPAnutMinSum[i]) + afterPAnutSum[i]
                let maxValue = lv_Vm.$bizUtil.ctUtils.fn_SetNumber(afterPAnutMaxSum[i]) + afterPAnutSum[i]
                if (minValue === maxValue) {
                  showAfterText = lv_Vm.$commonUtil.numberWithCommas(minValue)
                } else {
                  showAfterText = lv_Vm.$commonUtil.numberWithCommas(minValue) + '~' + lv_Vm.$commonUtil.numberWithCommas(maxValue)
                }
              } else { // 변액 아닐경우
                showAfterText = lv_Vm.$commonUtil.numberWithCommas(afterPAnutSum[i])
              }
              bothPAnutSum[i] = {
                beforePrice: beforePAnutSum[i],
                age: 55 + (i * 5),
                afterPrice: afterPAnutSum[i],
                afterPAnutMinPrice: lv_Vm.$bizUtil.ctUtils.fn_SetNumber(afterPAnutMinSum[i]),
                afterPAnutMaxPrice: lv_Vm.$bizUtil.ctUtils.fn_SetNumber(afterPAnutMaxSum[i]),
                showAfterText: showAfterText
              }
            }
            

            // monthSum = lv_Vm.fn_SumByAge(lv_Vm, beforePAnutSum, afterPAnutSum, npensSum, rtpsSum, afterPAnutMinSum, afterPAnutMaxSum)
            console.log('beforePAnutSum>>>', beforePAnutSum)
            console.log('afterPAnutSum>>>', afterPAnutSum)
            console.log('afterPAnutMinSum>>>', afterPAnutMinSum)
            console.log('afterPAnutMaxSum>>>', afterPAnutMaxSum)
            console.log('bothPAnutSum>>>', bothPAnutSum) // 개인연금 설계전, 후 합계
            console.log('npensSum>>>', npensSum) // 국민연금 65세 기준 예상금액 합계
            console.log('rtpsSum>>>', rtpsSum) // 퇴직연금 65세 기준 예상금액 합계

            let t_allSum = {}
            t_allSum.beforeSum = `${lv_Vm.$commonUtil.numberWithCommas(bothPAnutSum[2].beforePrice + npensSum + rtpsSum)}만원` // 설계 전 개인,국민,퇴직 금액 합계
            t_allSum.beforeAllGraphData = bothPAnutSum[2].beforePrice + npensSum + rtpsSum
            //t_allSum.beforePAnutGraphData = Math.floor((bothPAnutSum[2].beforePrice / t_allSum.beforeAllGraphData) * 100)
            t_allSum.beforeNpensGraphData = Math.floor((npensSum / t_allSum.beforeAllGraphData) * 100)
            t_allSum.beforeRtpsGraphData = Math.floor((rtpsSum / t_allSum.beforeAllGraphData) * 100)
            t_allSum.beforePAnutGraphData = 100 - (t_allSum.beforeNpensGraphData + t_allSum.beforeRtpsGraphData)
            if (lv_Vm.variableFlag) { // 설계 후 개인,가상, 국민, 퇴직 금액 합계
            let t_sum = bothPAnutSum[2].afterPrice + npensSum + rtpsSum
              t_allSum.afterSum = `${lv_Vm.$commonUtil.numberWithCommas(bothPAnutSum[2].afterPAnutMinPrice + t_sum)} ~ ${lv_Vm.$commonUtil.numberWithCommas(bothPAnutSum[2].afterPAnutMaxPrice + t_sum)}만원`
              t_allSum.afterAllGraphData = bothPAnutSum[2].afterPAnutMaxPrice + t_sum
              //t_allSum.afterPAnutGraphData = Math.floor(((bothPAnutSum[2].afterPAnutMaxPrice + bothPAnutSum[2].afterPrice) / t_allSum.afterAllGraphData) * 100)
            } else {
              t_allSum.afterSum = `${lv_Vm.$commonUtil.numberWithCommas(bothPAnutSum[2].afterPrice + npensSum + rtpsSum)}만원`
              t_allSum.afterAllGraphData = bothPAnutSum[2].afterPrice + npensSum + rtpsSum
              //t_allSum.afterPAnutGraphData = Math.floor((bothPAnutSum[2].afterPrice / t_allSum.afterAllGraphData) * 100)
            }
            t_allSum.afterNpensGraphData = Math.floor((npensSum / t_allSum.afterAllGraphData) * 100)
            t_allSum.afterRtpsGraphData = Math.floor((rtpsSum / t_allSum.afterAllGraphData) * 100)
            t_allSum.afterPAnutGraphData = 100 - (t_allSum.afterNpensGraphData + t_allSum.afterRtpsGraphData)
            if (t_allSum.beforeAllGraphData - t_allSum.afterAllGraphData > 0) {  // 설계전 합계 > 설계 후 합계
              // 설계 전
              t_allSum.beforePAnutGraphPercent = t_allSum.beforePAnutGraphData
              t_allSum.beforeNpensGraphPercent = t_allSum.beforeNpensGraphData 
              t_allSum.beforeRtpsGraphPercent = t_allSum.beforeRtpsGraphData
              // 설계 후
              // let afterMainGraphPercent = Math.floor((t_allSum.afterAllGraphData / t_allSum.beforeAllGraphData) * 100)
              // t_allSum.afterPAnutGraphPercent = Math.round(afterMainGraphPercent * (t_allSum.afterPAnutGraphData / 100))
              // t_allSum.afterNpensGraphPercent = Math.round(afterMainGraphPercent * (t_allSum.afterNpensGraphData / 100))
              // t_allSum.afterRtpsGraphPercent = Math.round(afterMainGraphPercent * (t_allSum.afterRtpsGraphData / 100))
              t_allSum.afterPAnutGraphPercent = t_allSum.afterPAnutGraphData
              t_allSum.afterNpensGraphPercent = t_allSum.afterNpensGraphData
              t_allSum.afterRtpsGraphPercent = t_allSum.afterRtpsGraphData
            } else if (t_allSum.beforeAllGraphData === t_allSum.afterAllGraphData) { // 설계전 합계 = 설계 후 합계
              // 설계 전
              t_allSum.beforePAnutGraphPercent = t_allSum.beforePAnutGraphData
              t_allSum.beforeNpensGraphPercent = t_allSum.beforeNpensGraphData 
              t_allSum.beforeRtpsGraphPercent = t_allSum.beforeRtpsGraphData
              // 설계 후
              t_allSum.afterPAnutGraphPercent = t_allSum.afterPAnutGraphData
              t_allSum.afterNpensGraphPercent = t_allSum.afterNpensGraphData
              t_allSum.afterRtpsGraphPercent = t_allSum.afterRtpsGraphData
            } else { // 설계전 합계 < 설계 후 합계
              // 설계 전
              // let beforeMainGraphPercent = Math.floor((t_allSum.beforeAllGraphData / t_allSum.afterAllGraphData ) * 100)
              // t_allSum.beforePAnutGraphPercent = Math.round(beforeMainGraphPercent * (t_allSum.beforePAnutGraphData / 100))
              // t_allSum.beforeNpensGraphPercent = Math.round(beforeMainGraphPercent * (t_allSum.beforeNpensGraphData / 100))
              // t_allSum.beforeRtpsGraphPercent = Math.round(beforeMainGraphPercent * (t_allSum.beforeRtpsGraphData / 100))
              t_allSum.beforePAnutGraphPercent = t_allSum.beforePAnutGraphData
              t_allSum.beforeNpensGraphPercent = t_allSum.beforeNpensGraphData 
              t_allSum.beforeRtpsGraphPercent = t_allSum.beforeRtpsGraphData
              // 설계 후
              t_allSum.afterPAnutGraphPercent  = t_allSum.afterPAnutGraphData
              t_allSum.afterNpensGraphPercent = t_allSum.afterNpensGraphData
              t_allSum.afterRtpsGraphPercent = t_allSum.afterRtpsGraphData
            }
            lv_Vm.beforePAnutSumData = lv_Vm.$commonUtil.numberWithCommas(bothPAnutSum[2].beforePrice)
            lv_Vm.afterPAnutSumData = bothPAnutSum[2].showAfterText
            lv_Vm.npensSumData = lv_Vm.$commonUtil.numberWithCommas(npensSum)
            lv_Vm.rtpsSumData = lv_Vm.$commonUtil.numberWithCommas(rtpsSum)
            lv_Vm.allSumData = t_allSum
            console.log('lv_Vm.beforePAnutSumData>>>', lv_Vm.beforePAnutSumData) // 개인연금 설계전, 후 합계
            console.log('t_allSum>>>', t_allSum) // 개인연금 설계전, 후 합계

            //월납입보험료
            lv_Vm.fn_headerSet()

          }).catch(function (error2) {
            window.vue.getStore('progress').dispatch('HIDE')
            console.log(error2)
          })
        }).catch(function (error1) {
          window.vue.getStore('progress').dispatch('HIDE')
          console.log(error1)
        })
      },

      /************************************************************************************************
      * Function명 : fn_PrpslList
      * 설명       : 당사, 타사, 국민, 퇴직 연금 합치기(가설포함)
      * type       before(제안전), after(제안후)
      ************************************************************************************************/
      fn_PrpslList (lv_Vm, type) {
        return new Promise( function (resolve, reject) {
          let tcompList = lv_Vm.pensData.tcomAnutList // 당사
          let otcompList = lv_Vm.pensData.otcomAnutList // 타사
          let npens = lv_Vm.pensData.gtpNpens // 국민
          let rtps = lv_Vm.pensData.exptPpsn // 퇴직
          let rtnList = []
          let anutBgnAge = 0
          let anpsn = [] // 기간별 그래프 영역에서 사용하도록 연금액 형태변환
          console.log('tcompList>>>', tcompList)
          console.log('tcompList store>>>', lv_Vm.getStore('ctStore').getters.getTcomAnut)
          console.log('lv_Vm.pensData>>>', lv_Vm.pensData)

          // 당사연금 세팅
          for (let i in tcompList) {
            if (!(type === 'after' && tcompList[i].prpslStatCd === '02')) { // 제안후에서 제외되었을 경우 값을 담지 않음
              console.log('>>>>>>', tcompList[i])
              let tmpObj = {}
              anutBgnAge = tcompList[i].anutBgnMnisdAge
              tmpObj.type = 'tcomp'
              tmpObj.compNm = tcompList[i].insrCoNm
              tmpObj.prdtNm = tcompList[i].prdtNm
              tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'tcomp', anutBgnAge, tcompList[i].anpsnStartAmount)
              rtnList.push(tmpObj)
            }
          }

          // 타사연금 세팅
          for (let j in otcompList) {
            if (!(type === 'after' && otcompList[j].prpslStatCd === '02')) { // 제안후에서 제외되었을 경우 값을 담지 않음
              let tmpObj = {}
              anutBgnAge = otcompList[j].otcomAnutBgnAge
              //anutBgnAge = ctConstants.otcomAnutBgnAge // 타사연금개시나이 고정값
              tmpObj.type = 'otcomp'
              tmpObj.compNm = otcompList[j].insrCoNm
              tmpObj.prdtNm = otcompList[j].prdtNm
              tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'otcomp', anutBgnAge, otcompList[j].anpsn * 10000, otcompList[j].otcomAnutEndAge)
              // tmpObj.anpsn = this.$bizUtil.ctUtils.fn_FirstColor(tmpObj.anpsn, 'prm')
              rtnList.push(tmpObj)
            }
          }

          // 국민연금 세팅
          let npensAnpsn = []
          if (npens.length > 0 ) {
            npensAnpsn = npens[0].anpsnList
            anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'npens', 0, npensAnpsn)
            let npensObj = {
              type: 'npens',
              compNm: '-',
              prdtNm: '국민연금',
              anpsn: anpsn
            }
            if ( lv_Vm.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 국민연금 정보를 구한다
              rtnList.push(npensObj)
            }
          }
          // 퇴직연금 세팅
          let rtpsAnpsn = []
          if (rtps.length > 0 ) {
            rtpsAnpsn = rtps[0].anpsnList
            anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'rtps', 0, rtpsAnpsn)
            //anpsn = lv_Vm.$bizUtil.ctUtils.fn_FirstColor(anpsn, 'prm')
            let rtpsObj = {
              type: 'rtps',
              compNm: '-',
              prdtNm: '퇴직연금',
              anpsn: anpsn
            }
            if ( lv_Vm.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 퇴직 정보를 구한다
              rtnList.push(rtpsObj)
            }
          }

          if (type !== 'after') { // 설계전일때는 여기서 resolve로 넘겨주고, 설계후일때는 가설로직 마지막 부분에 resolve로 넘겨줌.
            resolve(rtnList)
          }

          // 1. 가입설계VO에 들어있는 상품 모두를 해약환급금조회IF 에 태운다.
          // 2. IF에서 나온 값에 따라서 연금유형을 알아낸다.
          // 2-1. 연금유형에 따라 금액을 산출한다.
          // 2-2. 종신보험 연금전환 가능 상품일 경우에는 나이를 세팅하여 연금 여부 함수(?)에 넣는다
          // 2-2-1. 나온 연금 유형에 따라서 금액을 산출한다. (일반,저축,변액 연금이 나옴)
          if (type === 'after') {
            let covanDt = lv_Vm.getStore('ctStore').getters.getCovanData.data
            let custVO = covanDt.gtplCllgHoshVO.gtplCllgCustVO
            let custInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
            let entplPrpslPrdtArray = []
            let param = {inEntplVO: {planId: ''}}
            let promiseArray = []
            let countResp = 0
            let entplList = lv_Vm.getStore('ctStore').getters.getCustcovrAnly.data?.entplVO // 가입설계리스트 인터페이스(txTSSCT24S1) 에서 조회한 값
            let entplPlanIdList = []

            // 현재고객의 가설제안상품 추출
            for (let i in custVO) {
              if (custVO[i].chnlCustId === custInfo.chnlCustId) {
                entplPrpslPrdtArray = custVO[i].entplPrpslPrdtVO
              }
            }
            if (!lv_Vm.$bizUtil.isEmpty(entplPrpslPrdtArray)) {
              // 계피동일건만 && 월보험료가 1억미만 상품만 추린다.(연금계산기 가설목록 기준)
              entplPrpslPrdtArray = entplPrpslPrdtArray.filter( x => { return x.mnisdChnlCustId === custInfo.chnlCustId && x.contrNm === custInfo.custNm })
              entplPrpslPrdtArray = entplPrpslPrdtArray.filter( x => { return x.prm.longAmount < 100000000 })
              console.log('entplPrpslPrdtArray>>>>', entplPrpslPrdtArray)
            }
            // 가입설계리스트(txTSSCT24S1)에 존재하는 설계ID만 해약환금급을 보낸다.
            // (현재고객의 기계약VO에 들어있지만, 가설이 되지 않는(가설목록에없는) 상품을 제외하기 위한 작업)
            for (let j in entplList) {
              entplPlanIdList.push(entplList[j].planId)
            }
            for (let k in entplPrpslPrdtArray) {
              if (entplPlanIdList.indexOf(entplPrpslPrdtArray[k].planId) > -1) {
                param.inEntplVO.planId = entplPrpslPrdtArray[k].planId
                promiseArray.push(lv_Vm.$bizUtil.ctUtils.fn_GtEntpLanutReg(lv_Vm, param))
              }
            }
            Promise.all(promiseArray).then(function (response) {
              console.log('Promise.all RESPONSE :: ', response)
              for (let i in response) {
                countResp++
                // 해약환급금 조회를 제대로 호출했지만, 정상코드 외의 값으로 오는 것들은 제외.
                if (response[i] === 'NONE' || response[i].msgComm.msgCd !== 'EFWS003') {
                  continue
                }
                let entplPrpslPrdt = entplPrpslPrdtArray[i]
                let trg = response[i].body
                let lanutType = lv_Vm.$bizUtil.ctUtils.fn_GtLanutType(trg) // 연금종류판단
                console.log('해약환급금 IF (가설 id) :: ', entplPrpslPrdt.planId, lanutType, response[i])
                let rtnAnutObj = {}
                let tmpObj = {}
                switch (lanutType) {
                  case ctConstants.annuityInfoMap.conversion.code : // 종신보험 연금전환
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_GtConversionVal(trg, false)
                    break

                  case ctConstants.annuityInfoMap.power.code : // 파워즉시
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_PowerAnnuityPro(trg, false)
                    break
                  case ctConstants.annuityInfoMap.normal.code : // 일반연금
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_NrmAnnuityPro(trg, false)
                    break

                  case ctConstants.annuityInfoMap.anutAmt20.code : // 저축연금
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_Anut20AnnuityPro(trg, false)
                    break

                  case ctConstants.annuityInfoMap.variable.code : // 변액연금
                    rtnAnutObj = lv_Vm.$bizUtil.ctUtils.fn_VariableAnnuityPro(trg, false)
                    break
                  case '' : // 공백
                  default : // 해당없음
                  // fn_GtLanutType에서 해당없음은 공백('')으로 보내므로 모두 잡음
                    console.log('해당없음')
                    break
                }
                console.log('rtnAnutObj>>>', rtnAnutObj)
                if (lanutType !== '') {
                  tmpObj.type = 'entpl'
                  tmpObj.compNm = '삼성생명'
                  tmpObj.prdtNm = entplPrpslPrdt.prdtNm
                  tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, 'entpl', rtnAnutObj.openAge, rtnAnutObj.val)
                  tmpObj.anutType = lanutType
                  rtnList.push(tmpObj)
                }
              }
              if (countResp === entplPrpslPrdtArray.length) { // 설계후일때는 가설로직 마지막 부분에 resolve로 넘겨줌. 설계전일때는 fn_PrpslList 함수마지막에 resolve로 넘겨줌.
                resolve(rtnList)
              }
            }).catch(function (error) {
              window.vue.error(error)
              console.log('error ::: ' + error)
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('CO')['ESYSC002'])
              reject(error)
            })
          }
        })
      },

      /************************************************************************************************
      * Function명 : fn_headerSet
      * 설명       : 헤더 셋팅
      ************************************************************************************************/
      fn_headerSet() {
        let lv_vm = this
        // 설계전 월 납입 보험료
        let lvSmtotPrm = 0
        for (let i in lv_vm.pensData.tcomAnutList) { // 당사연금
          let padCylCd = lv_vm.pensData.tcomAnutList[i].padCylCd // 당사연금 납입주기
          let smtotPrm = lv_vm.pensData.tcomAnutList[i].smtotPrm.longAmount // 보험료
          let padParngTotPrm = lv_vm.pensData.tcomAnutList[i].padParngTotPrm // 당사 향후 납입 보험료
          let retVal = lv_vm.$bizUtil.ctUtils.fn_getMonAmt(padCylCd, smtotPrm, padParngTotPrm)
          lvSmtotPrm += Number(retVal)
        }
        for (let i in lv_vm.pensData.otcomAnutList) { // 타사연금
          let padCylCd = lv_vm.pensData.otcomAnutList[i].padCylCd // 납입주기
          let smtotPrm = lv_vm.pensData.otcomAnutList[i].smtotPrm // 보험료
          let padParngTotPrm = lv_vm.pensData.otcomAnutList[i].padParngTotPrm // 당사 향후 납입 보험료
          let retVal = lv_vm.$bizUtil.ctUtils.fn_getMonAmt(padCylCd, smtotPrm, padParngTotPrm)
          lvSmtotPrm += Number(retVal)
        }
        for (let i in lv_vm.pensData.gtpNpens) { // 국민연금
          let padCylCd = lv_vm.pensData.gtpNpens[i].padCylCd // 납입주기
          let smtotPrm = lv_vm.pensData.gtpNpens[i].smtotPrm // 보험료
          let padParngTotPrm = lv_vm.pensData.gtpNpens[i].padParngTotPrm // 당사 향후 납입 보험료
          let retVal = lv_vm.$bizUtil.ctUtils.fn_getMonAmt(padCylCd, smtotPrm, padParngTotPrm)
          lvSmtotPrm += Number(retVal)
        }
        for (let i in lv_vm.pensData.exptPpsn) { // 퇴직연금
          let padCylCd = lv_vm.pensData.exptPpsn[i].padCylCd // 납입주기
          let smtotPrm = lv_vm.pensData.exptPpsn[i].smtotPrm // 보험료
          let padParngTotPrm = lv_vm.pensData.exptPpsn[i].padParngTotPrm // 당사 향후 납입 보험료
          let retVal = lv_vm.$bizUtil.ctUtils.fn_getMonAmt(padCylCd, smtotPrm, padParngTotPrm)
          lvSmtotPrm += Number(retVal)
        }
        this.headerItem.beforeMonAmt = lvSmtotPrm
        
        // 설계 후
        this.$bizUtil.ctUtils.fn_planAfCovan(lv_vm)
        lv_vm.includeProductList = [] // [].concat(lv_vm.planAfCovan.addmPrdtInfo) // 추가한 상품
        lv_vm.planAfCovan = lv_vm.getStore('ctStore').getters.getPlanAfCovan.data
        console.log('lv_vm.planAfCovan>>>', lv_vm.planAfCovan)
        //lv_vm.includeProductList = lv_vm.planAfCovan.addmPrdtInfo
        for (let i in lv_vm.planAfCovan.addmPrdtInfo) {
          let t_includeProduct = {}
          t_includeProduct = lv_vm.planAfCovan.addmPrdtInfo[i]
          //let lv_RstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, t_includeProduct, true, true, true)
          //lv_vm.fn_RSttingItem(t_includeProduct, lv_RstItem)
          //lv_vm.includeProductList.push(lv_RstItem)
          lv_vm.includeProductList.push(t_includeProduct)
        }

        /*
          III. 추가한 상품 : 1. 기계약 가입설계 vo (제안)  와 2. 가설 설계 IF 데이터 비교해서,
                          기계약 가입설계 vo (제안) includeProductList 에 있으면 추가임
                          추가된 건에 한해 해약환급금 IF 호출해서 연금액 구하기
        */
        let prpsPldtList = []
        console.log('lv_vm.includeProductList>>', lv_vm.includeProductList)
        prpsPldtList = lv_vm.getStore('ctStore').getters.getCustcovrAnly?.data  // 2. 가입설계 목록 entplVO (가설 설계 IF 데이터)
        console.log('prpsPldtList>>', prpsPldtList)
        // 계피동일건만 && 월보험료가 1억미만 상품만 추린다.(연금계산기 가설목록 기준)
        if (prpsPldtList !== undefined) {
          prpsPldtList = prpsPldtList.entplVO.filter( x => { return x.mnisdChnlCustId === lv_vm.lv_BaseData.data.chnlCustId && x.contrNm === lv_vm.lv_BaseData.data.custNm })
          prpsPldtList = prpsPldtList.filter( x => { return x.smtotPrm.longAmount < 100000000 }) 
        }
        // // 추가한 상품 목록을, 가입설계 목록에 있는 상품(설계번호)으로 체크해서 해당 설계번호가 없으면 추가 상품목록에서 삭제한다.
        let inChkCnt = 0
        let lv_incLen  = lv_vm.includeProductList.length-1
        for ( let incIdx = lv_incLen ; incIdx>=0; incIdx-- ) {
          inChkCnt = 0
          for ( let prpsIdx in prpsPldtList ) {
            if ( lv_vm.includeProductList[incIdx].planId === prpsPldtList[prpsIdx].planId ) {
              inChkCnt++
              break
            }
          }
          if( inChkCnt === 0 ) {
            lv_vm.includeProductList.splice(incIdx,1)
          }
        }
        lv_vm.headerItem.afterMonAmt = lvSmtotPrm
        for (let i in lv_vm.includeProductList) {
          console.log('lv_vm.includeProductList[i].monthPrice>>', lv_vm.includeProductList[i].monthPrice)
          lv_vm.headerItem.afterMonAmt += lv_vm.includeProductList[i].monthPrice
        }
        console.log('lv_vm.headerItem>>>', lv_vm.headerItem)
        this.$emit('headerData', lv_vm.headerItem)
        window.vue.getStore('progress').dispatch('HIDE')
        // 필요없을거 같아서 일단 주석처리1!!!
        /* let lv_PromiseArray = []
        // 추가 된건에 한해 해약환급금 IF 를 호출한다. (가입설계후 추가상품 구하기)
        for (let lv_index in lv_vm.includeProductList) {
          let t_includeProductItem = lv_vm.includeProductList[lv_index]

          lv_PromiseArray.push(lv_vm.$bizUtil.ctUtils.fn_GtEntpLanutRegProc(lv_vm, t_includeProductItem))
        }
        Promise.all(lv_PromiseArray).then( function (response) {
          // console.log('Promise AFTER ::: ' + JSON.stringify(response))
          // 가입설계제안의 해약정보를 모두획득하면 카드 그리기를 마무리 짓는다.
          for (let lv_DtlChkIndx in lv_vm.includeProductList) {
            let lv_TrgItem = lv_vm.includeProductList[lv_DtlChkIndx]
            let lv_ChkItem = response[lv_DtlChkIndx]
            console.log('response>>>>>', response)
            if (Object.keys(lv_ChkItem).length === 0) {
              // 해약처리가 비정상일때
              // 아무것도 없는 내용을 셋팅시킨다.
              let itemArray = [] // 카드
              itemArray.push(['-', '-', '-', '-'].slice())
              lv_TrgItem.details = itemArray
            } else {
              // 해약처리가 정상일때
              lv_TrgItem.details = lv_ChkItem// 상품에 대한 금액처리 부분을 만들어 적용해야 한다.
            }
          }
        }) */
      },


    }
  };
</script>
<style scoped>
</style>