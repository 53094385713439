<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 bgcolor-1">

      <div v-if="Object.keys(totAmountData).length > 0 && !(totAmountData.preTotal === '0' && totAmountData.postTotal === '0')" class="calculate-expand w100 mb20">
        <div class="fexTy3 fs18rem">
          <span class="fwm">연 연금액합계(65세 기준)</span>
          <span v-if="!totAmountData.privateAmountDiff || totAmountData.privateAmountDiff === 0" class="fwb fexTy5 ls--10">
            -
          </span>
          <span v-else class="fwb fexTy5 ls--10" :class="totAmountData.privateAmountDiff > 0 ? 'crTy-blue3' :'crTy-orange2'">{{ `${$bizUtil.numberWithCommasByNumber(Math.abs(totAmountData.privateAmountDiff))}만원` }}
            <mo-icon :icon="totAmountData.privateAmountDiff > 0 ? 'msp-arrow-increase' :'msp-arrow-decrease'" />
          </span><!-- crTy-orange2 msp-arrow-decrease-->
        </div>
        <span v-if="hopeLivingCost > 0" class="dsD fs14rem txt-right crTy-bk7">희망월 생활비 {{ hopeLivingCost }}만원</span>

        <!-- status_value01~06 까지의 합은 100% 이여야 합니다. 소숫점때문에 100%가 넘어가거나 모질라면 틀어질것입니다. -->
        <div class="calculate-chart">
          <div class="chart-box status-box">
            <div class="bar fexTy5">
              <div class="status-con06" :style="{ width: totAmountData.allByPrivatePercent }" />
              <div class="status-con05" :style="{ width: totAmountData.allByRtpsPercent }" />
              <div class="status-con04" :style="{ width: totAmountData.allByNpenPercent }" />
            </div>
          </div>

          <div v-if="totAmountData.postPrivateAmount && totAmountData.allByPrivatePercent" class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt06"></span>개인연금</span>
            <span class="fs14rem fwm">{{totAmountData.allByPrivatePercent}}<span class="dsInline min120 ml10 txt-right">{{ totAmountData.postPrivateAmount }} 만원</span></span>
          </div>
          <div v-if="totAmountData.preRtps && Number(totAmountData.preRtps.replaceAll(',', '')) > 0" class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt05"></span>퇴직연금</span>
            <span class="fs14rem fwm">{{totAmountData.allByRtpsPercent}}<span class="dsInline min120 ml10 txt-right">{{ totAmountData.preRtps }} 만원</span></span>
          </div>
          <div v-if="totAmountData.preNpen && Number(totAmountData.preNpen.replaceAll(',', '')) > 0" class="fexTy3 mt4">
            <span class="fs14rem fexTy5"><span class="status-txt04"></span>국민연금</span>
            <span class="fs14rem fwm">{{totAmountData.allByNpenPercent}}<span class="dsInline min120 ml10 txt-right">{{ totAmountData.preNpen }} 만원</span></span>
          </div>
        </div>
      </div>

      <!--개인연금 -->
      <div class="calculate-expand w100 mb20">
        <div class="fexTy3 fs18rem">
          <span class="fwm">개인연금</span>
          <span v-if="!totAmountData.privateAmountDiff || totAmountData.privateAmountDiff === 0" class="fwb fexTy5">
            -
          </span>
          <span v-else class="fwb fexTy5" :class="totAmountData.privateAmountDiff > 0 ? 'crTy-blue3' :'crTy-orange2'">{{`${$bizUtil.numberWithCommasByNumber(Math.abs(totAmountData.privateAmountDiff))}만원`}}
            <mo-icon :icon="totAmountData.privateAmountDiff > 0 ? 'msp-arrow-increase' :'msp-arrow-decrease'" />
          </span>
        </div>
        <div class="calculate-chart">
          <div class="chart-box status-box">
            <div class="mt20 fs14rem fexTy3">
              <span class="crTy-bk7">설계전</span>
              <span class="fwb">{{ totAmountData.prePrivateAmount? totAmountData.prePrivateAmount : '0' }}만원</span>
            </div>
            <div class="bar fexTy5 mt5">
              <div class="status-con00 radius" :style="{ width: totAmountData.prePrivateAmountPercent }" />
            </div>
            <div class="mt20 fs14rem fexTy3">
              <span class="fwb">설계후</span>
              <span class="fwb">{{ totAmountData.postPrivateAmount ? totAmountData.postPrivateAmount : '0' }}만원</span>
            </div>
            <div class="bar fexTy5 mt5">
              <div class="status-con06 radius" :style="{ width: totAmountData.postPrivateAmountPercent }" />
            </div>
          </div>
          <mo-button class="ns-btn-round w100 rounded h40 blackW fs16rem" @click="fn_OpenProductList('1')" :disabled="privateDisable">상품별 수령금액</mo-button>
        </div>
      </div>

      <!--국민연금 -->
      <div v-if="totAmountData.preNpen && Number(totAmountData.preNpen.replaceAll(',', '')) > 0" class="calculate-expand w100 mb20">
        <div class="fexTy3 fs18rem">
          <span class="fwm">국민연금</span>
        </div>
        <div class="calculate-chart">
          <div class="chart-box status-box">
            <div class="mt20 fs14rem fexTy3">
              <span class="fwb">예상금액</span>
              <span class="fwb">{{ totAmountData.preNpen ? totAmountData.preNpen : '-' }}만원</span>
            </div>
            <div class="bar fexTy5 mt5">
              <div class="status-con04 radius" :style="{ width: '100%' }" />
            </div>
          </div>

          <mo-button class="ns-btn-round w100 rounded h40 blackW fs16rem" @click="fn_OpenProductList('2')">상품별 수령금액</mo-button>

        </div>
      </div>

      <!--퇴직연금 -->
      <div v-if="totAmountData.preRtps && Number(totAmountData.preRtps.replaceAll(',', '')) > 0" class="calculate-expand w100 mb20">
        <div class="fexTy3 fs18rem">
          <span class="fwm">퇴직연금</span>
        </div>
        <div class="calculate-chart">
          <div class="chart-box status-box">
            <div class="mt20 fs14rem fexTy3">
              <span class="fwb">예상금액</span>
              <span class="fwb">{{ totAmountData.preRtps ? totAmountData.preRtps : '-'  }}만원</span>
            </div>
            <div class="bar fexTy5 mt5">
              <div class="status-con05 radius" :style="{ width: '100%' }" />
            </div>
          </div>

          <mo-button class="ns-btn-round w100 rounded h40 blackW fs16rem" @click="fn_OpenProductList('3')">상품별 수령금액</mo-button>

        </div>
      </div>

      

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-s mt30 bgcolor-1">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_productEdit">상품수정</mo-button> <!-- disabled -->
        </div>
      </ur-box-container>
        
    </ur-box-container>

    

    <!-- 개인/국민/퇴직 연금 상품별 수령금액 모달-->
    <mo-bottom-sheet ref="refProductList" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        {{ modalParam.title }}
        <template v-if="modalParam.flag === '1'">
          <div v-if="totAmountData.privateAmountDiff === 0" class="mt20 fexTy5">
            -
          </div>
          <div v-else class="mt20 fexTy5"><!-- crTy-blue3 msp-arrow-decrease-->
            <span class="dsD fs30rem fwb" :class="totAmountData.privateAmountDiff > 0 ? 'crTy-blue3' :'crTy-orange2'">{{ Math.abs(totAmountData.privateAmountDiff) }}<span class="fs26rem">만원</span></span>
            <mo-icon :icon="totAmountData.privateAmountDiff > 0 ? 'msp-arrow-increase' :'msp-arrow-decrease'" class="fs28" />
          </div>
        </template>
        <div class="mt4 fexTy5 sub-text">
          <template v-if="modalParam.flag === '1'">
            <span><span class="fs14rem crTy-bk7 min50 mr4 dsInline">설계 전</span>{{ totAmountData.prePrivateAmount }}만원</span><em>|</em>
            <span><span class="fs14rem crTy-bk7 min50 mr4 dsInline">설계 후</span>{{ totAmountData.postPrivateAmount }}만원</span>
          </template>
          <span v-else><span class="fs14rem crTy-bk7 min50 mr4 dsInline">예상금액</span>{{ modalParam.flag === '2' ? totAmountData.preNpen : totAmountData.preRtps }}만원</span>
        </div>
      </template>
      <div class="content-area maxh-300 pb30 pl0 pr0 bd-T-Ty1">
        <ur-box-container v-for="(list, idx) in modalParam.modalList" :key="idx" alignV="start" componentid="" direction="column">
          <div class="w100 pal2024 bd-b-Ty1">
            <div class="fexTy3">
              <span class="fs17rem txtSkip2 flex-1 mr10">{{list.name}}</span>
              <span class="badge-type pl10 pr10 fs12 h20" :class="{'lightgreen2' : list.hasProduct === true, 'lightred' : list.isRecommendProduct === true, 'lightgray' : list.isOtherProduct === true }">{{ list.isOtherProduct ? '타사' : (list.hasProduct ? '보유' : (list.isRecommendProduct ? '추천' : '')) }}</span>
            </div>
            <!-- <div class="txt-right fs17rem mt10 fwb">{{ list.yearPrice === 0 ? '-' :`${list.yearPrice}만원` }}</div> -->
            <div class="txt-right fs17rem mt10 fwb">{{ list.yearPrice === 0 ? '-' :`${$commonUtil.numberWithCommas(list.yearPrice)}만원` }}</div>
          </div>
          
        </ur-box-container> 
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="close1" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 상품수정 -->
    <mo-bottom-sheet ref="refProductEdit" class="ns-bottom-sheet" :key="editCnt"  :close-btn="true">
      <template v-slot:title>
        상품수정
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt20">
          <mo-segment-wrapper solid primary v-model="editTab" class="">
            <mo-segment-button value="1">상품 제외</mo-segment-button>
            <mo-segment-button value="2">상품 추가</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </template>
      <div class="content-area maxh-300 pb30 pl0 pr0 bd-T-Ty1">
        <!-- 상품 제외-->
        <template v-if="editTab === '1'">
          <template v-if="allRetProductList.length > 0">
            <ur-box-container v-for="(list, idx) in allRetProductList" :key="list.name + idx" alignV="start" componentid="" direction="column">
              <div class="w100 pal2024 bd-b-Ty1">
                <!-- <div class="fexTy5"> -->
                <div class="fexTy3-1">
                  <mo-checkbox v-model="list.checked" class="ns-check flex-1" :disabled="!list.canDelete">{{list.name}}</mo-checkbox>
                  <span class="badge-type pl10 pr10 fs12 h20 mt4" :class="{'lightgreen2' : list.hasProduct === true, 'lightred' : list.isRecommendProduct === true, 'lightgray' : list.isOtherProduct === true }">{{ list.isOtherProduct ? '타사' : (list.hasProduct ? '보유' : (list.isRecommendProduct ? '추천' : '')) }}</span>
                </div>
                <div class="fs17rem mt10 fwb  crTy-blue3 ml36">{{ list.monthPrice !== '-' ? `월 ${$commonUtil.numberWithCommas(list.monthPrice)} 원` : '-' }}</div>
              </div>
              <!-- no-data 조건설정 및 데이타 없음 --> 
            </ur-box-container>
          </template>
            <ur-box-container v-else alignV="start" componentid="" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info small-space"> <!-- 영역이 작은곳에서 활용 small-space -->
                    <span class="ns-ftcr-gray"><p class="mt10">보유 상품이 없습니다.</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- no-data end -->
        </template>
        <!--상품 추가-->
        <template v-if="editTab === '2'">
          <template v-if="productConfigList.length > 0">
            <ur-box-container v-for="(list, idx) in productConfigList" :key="list.name + idx" alignV="start" componentid="" direction="column">
              <div class="w100 pal2024 bd-b-Ty1">
                <!-- <div class="fexTy5"> -->
                <div class="fexTy3-1">
                  <mo-checkbox v-model="list.checked" class="ns-check flex-1">{{list.name}}</mo-checkbox>
                  <span class="badge-type pl10 pr10 fs12 h20 mt4" :class="{'lightgreen2' : list.hasProduct === true, 'lightred' : list.isRecommendProduct === true, 'lightgray' : list.isOtherProduct === true }">{{ list.isOtherProduct ? '타사' : (list.hasProduct ? '보유' : (list.isRecommendProduct ? '추천' : '')) }}</span>
                </div>
                <div class="fs17rem mt10 fwb  crTy-blue3 ml36">{{list.monthPrice !== '-' ? `월 ${$commonUtil.numberWithCommas(list.monthPrice)} 원` : '-' }}</div>
              </div>
              <!-- no-data 조건설정 및 데이타 없음 --> 
            </ur-box-container>
          </template>
            <ur-box-container v-else alignV="start" componentid="" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info small-space"> <!-- 영역이 작은곳에서 활용 small-space -->
                    <span class="ns-ftcr-gray"><p class="mt10">최근 가입설계 상품이 없습니다.</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- no-data end -->
        </template>  
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_MoveMSPPI001M" class="ns-btn-round white">새로설계</mo-button>
            <mo-button v-if="editTab === '1'" componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_EditConfirm" class="ns-btn-round blue">제외</mo-button>
            <mo-button v-if="editTab === '2'" componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_EditConfirm" class="ns-btn-round blue">추가</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
import UrBoxContainer from '~/src/_uidev/containers/box/urBoxContainer.vue';
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {UrBoxContainer},
    props: {
      pPensData: {
        type: Object,
        default: {}
      },
      pComplete: {
        type: Boolean,
        default: false
      },
      slctTabIdx: {
        type: Number,
        default: 0
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.$emit('totAmountData', {}) // 헤더 초기화를 위하여
      console.log('this.slctTabIdx>>>', this.slctTabIdx)
      if (this.lv_BaseData.data !== undefined && Object.keys(this.pPensData).length > 0){
          console.log('created 온로드')
          this.pensData = this.pPensData
          this.fn_productOnload()
        }
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isLoading: false,
        editTab: '1',
        value1: '',
        hopeLivingCost: 0, // 희망생활비 존재 여부
        productList: [], // 가입설계 + 당사,타사 리스트
        allRetProductList: [], // 보유상품 리스트
        productConfigList: [], // 추천상품 리스트
        pensData: {}, //
        npensProductList: [], // 국민연금 리스트
        exptProductList: [], // 퇴직연금 리스트
        modalParam: {
          title: '',
          flag: '',
          modalList: [],
        },
        totAmountData: {},
        editCnt: 0,
        privateDisable: true, // 개인연금 상품별 수령금액 버튼 활성화여부
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      lv_BaseData: function () {
        // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
      lv_CovanData: function () {
        // 고객 계약 데이터
        let returnValue = this.getStore('ctStore').getters.getCovanData.data
        return returnValue
      },
      foreg: function () {
        return this.getStore('ctStore').getters.getMeminfoData.data
      }
    },
    watch: {
      pComplete() {
        if (this.pComplete) {
          console.log('300M 완료되어서 307 로직 시작!!')
          this.fn_productOnload()
        } else {
        }
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      open1()   { this.$refs.refProductList.open();  },
      close1()  { this.$refs.refProductList.close(); },
      open2()   { this.$refs.refProductEdit.open();  },
      close2()  { this.$refs.refProductEdit.close(); },

      /************************************************************************************************
      * Function명 : fn_productOnload
      * 설명       : 
      ************************************************************************************************/
      fn_productOnload() {
        let lv_vm = this
        lv_vm.visibleLivingCost = false // 희망생활비
        lv_vm.pensData = lv_vm.pPensData
        console.log('초기화 제대로 안되니??', lv_vm.pensData)
        window.vue.getStore('progress').dispatch('FULL')
        window.vue.getStore('progress').dispatch('SHOW')
          Promise.all([lv_vm.$bizUtil.ctUtils.fn_PrpslCovrAnly(lv_vm)]) // 가입설계리스트 조회
            .then(function (response) {
              lv_vm.productList = []
              lv_vm.productConfigList = []
              lv_vm.allRetProductList = []
              lv_vm.npensProductList = []
              lv_vm.exptProductList = []
              console.log('온로드1>>>', response)
              let resp = lv_vm.foreg
              lv_vm.hopeLivingCost = Number(resp.rtmAftLvgct.longAmount)
              let promiseArray = []
              // 가입설계 제안 처리
              let pAnutFlag = true // 연금계산기 기준 가입설계 목록 flag ( 1. 계피동일 건만 & 2. 월보험료가 1억이상 상품 가설목록에서 배재 )
              lv_vm.$bizUtil.ctUtils.fn_PrpslCovrAnlyRcg(lv_vm, pAnutFlag)
              console.log('가입설계 리스트!!!>>>', lv_vm.productList)
              console.log('상품추천 리스트!!!>>>', lv_vm.productConfigList)
              // configList에 가입설계제안만 남아있을때 변액처리를 한다.
              // configList에 있는 가입설계(가입설계제안상품)만큼 가입설계 해약 정보 프로미스를 획득한다.
              for (let promiseIndex in lv_vm.productList) {
                let promiseInnerItem = lv_vm.productList[promiseIndex]
                // 화면 로딩시 비어있게 만들기 위해 detail을 강제로 셋팅
                let itemArray = [] // 카드
                itemArray.push(['-', '-', '-', '-'].slice())
                console.log('itemArray>>>>', itemArray)
                promiseInnerItem.details = itemArray
                promiseArray.push(lv_vm.$bizUtil.ctUtils.fn_GtEntpLanutRegProc(lv_vm, promiseInnerItem)) // 가입설계의 해약환급금(연금정보)를 가공
              }
              Promise.all(promiseArray).then( function (response) {
                console.log('이값은 뭐니??>>>', response)
                // 입설계제안의 해약정보를 모두획득하면 카드 그리기를 마무리 짓는다.
                for (let dtlChkIndx in lv_vm.productList) {
                  let chkItem = response[dtlChkIndx]
                    lv_vm.productList[dtlChkIndx].yearPrice = chkItem
                    lv_vm.productList[dtlChkIndx].checked = true
                    lv_vm.productList[dtlChkIndx].sortIdx = 2
                  //}
                }
                console.log('lv_vm.productList', lv_vm.productList)
                console.log('lv_vm.pensData>>>>>', lv_vm.pensData)
                // 자사 계약 셋팅
                for (let tcomIdx in lv_vm.pensData.tcomAnutList) {
                  let innrTcomAnut = lv_vm.pensData.tcomAnutList[tcomIdx]
                  console.log('pensData.tcomAnutList>>', innrTcomAnut)
                  if (innrTcomAnut.prpslStatCd === '01') {
                    let rstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, innrTcomAnut, true, false, true)
                    //lv_vm.fn_RSttingItem(innrTcomAnut, rstItem)
                    console.log('보유 탭 rstItem>>>>', rstItem)
                    rstItem.checked = true
                    rstItem.sortIdx = 1
                    lv_vm.productList.push(rstItem)
                  } else {
                    let rstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, innrTcomAnut, true, false, true)
                    //lv_vm.fn_RSttingItem(innrTcomAnut, rstItem)
                    console.log('최근 가입설계 탭 rstItem>>>>', rstItem)
                    rstItem.checked = false
                    rstItem.sortIdx = 1
                    lv_vm.productConfigList.push(rstItem)
                  }
                }
                console.log('lv_vm.productList>>>', lv_vm.productList)
                console.log('lv_vm.productConfigList>>>', lv_vm.productConfigList)
                // 타사계약 셋팅
                for (let otcompIdx in lv_vm.pensData.otcomAnutList) {
                  let InnrOtcomAnut = lv_vm.pensData.otcomAnutList[otcompIdx]
                  if (InnrOtcomAnut.prpslStatCd === '01') {
                    let rstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, InnrOtcomAnut, true, false, false)
                    //lv_vm.fn_RSttingItem(InnrOtcomAnut, rstItem)
                    rstItem.checked = true
                    rstItem.sortIdx = 3
                    lv_vm.productList.push(rstItem)
                  } else {
                    let rstItem = lv_vm.$bizUtil.ctUtils.fn_SettingListItem(lv_vm.lv_BaseData.data.custNm, InnrOtcomAnut, true, false, false)
                    //lv_vm.fn_RSttingItem(InnrOtcomAnut, rstItem)
                    rstItem.checked = false
                    rstItem.sortIdx = 3
                    lv_vm.productConfigList.push(rstItem)
                  }
                }
                lv_vm.allRetProductList = lv_vm.allRetProductList.concat(lv_vm.productList)
                if (lv_vm.pensData.gtpNpens !== undefined) {
                  // 국민연금 셋팅
                  if ( lv_vm.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 국민 정보를 구한다.
                    lv_vm.fn_SetNpensCard()
                  }
                }

                if (lv_vm.pensData.exptPpsn !== undefined) {
                  // 퇴직연금 셋팅
                  if ( lv_vm.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 퇴직 정보를 구한다.
                    lv_vm.fn_SetRtpsCard()
                  }
                }
                // 헤더셋팅
                lv_vm.fn_CalcAmount(lv_vm)
                if (lv_vm.productList.length > 0) {
                  lv_vm.privateDisable = false
                } else {
                  lv_vm.privateDisable = true
                }
                
                lv_vm.allRetProductList.sort(function (a, b) {
                  if (a.sortIdx < b.sortIdx) return -1
                  if (a.sortIdx === b.sortIdx) return 0
                  if (a.sortIdx > b.sortIdx) return 1
                })
                console.log('allRetProductList>>>', lv_vm.allRetProductList)
                // 로딩종료!!
                window.vue.getStore('progress').dispatch('HIDE')
              }).catch(function (error) {
                window.vue.getStore('progress').dispatch('HIDE')
                window.vue.error(error)
              })
            }).catch(function (error) {
              window.vue.getStore('progress').dispatch('HIDE')
              console.log('307D 온로드 에러???', error)
              window.vue.error(error)
            })
      },

      /************************************************************************************************
      * Function명 : fn_SetNpensCard
      * 설명       : 국민연금 셋팅
      ************************************************************************************************/
      fn_SetNpensCard() {
        //let anpsn = Math.floor(this.pensData.gtpNpens.anpsn) // 연금 연액
        //let itemArray = []
        this.pensData.gtpNpens.forEach(data => {
          let anpsnItem = {}
          anpsnItem.name = '국민연금'
          anpsnItem.isRecommendProduct = false
          anpsnItem.isOtherProduct = true // 타사
          anpsnItem.hasProduct = true // 보유
          anpsnItem.canDelete = false
          anpsnItem.status = '타사' // 추가하기 등록건은 타사, 모니모 조회건은 보유
          anpsnItem.monthFlag = 'M'
          anpsnItem.monthPrice = '-'
          anpsnItem.checked = true
          anpsnItem.sortIdx = 2
          // itemArray.push(['-', anpsn, '-', '-'].slice()) // 개인연금란을 제외하곤 셋팅하지 않는다.
          // anpsnItem.details = itemArray
          anpsnItem.yearPrice = data.anpsn
          //anpsnItem.anpsn = data.anpsn
          anpsnItem.NpenFlag = true
          this.npensProductList.push(anpsnItem)
        })
        this.allRetProductList = this.allRetProductList.concat(this.npensProductList)
      },

      /************************************************************************************************
      * Function명 : fn_SetRtpsCard
      * 설명       : 퇴직연금 셋팅
      ************************************************************************************************/
      fn_SetRtpsCard () {
        //let rtps = Math.floor(this.pensData.exptPpsn.anpsn) // 연금 연액
        //let itemArray = []
        this.pensData.exptPpsn.forEach(data => {
          let anpsnItem = {}
          anpsnItem.name = '퇴직연금'
          anpsnItem.isRecommendProduct = false
          anpsnItem.isOtherProduct = true // 타사
          anpsnItem.hasProduct = true // 보유
          anpsnItem.canDelete = false
          anpsnItem.status = '타사' // 추가하기 등록건은 타사, 모니모 조회건은 보유
          anpsnItem.monthFlag = 'M'
          anpsnItem.monthPrice = '-'
          anpsnItem.checked = true
          anpsnItem.sortIdx = 2
          // itemArray.push(['-', '-', rtps, '-'].slice()) // 개인연금란을 제외하곤 셋팅하지 않는다.
          // anpsnItem.details = itemArray
          anpsnItem.yearPrice = data.anpsn
          //anpsnItem.anpsn = data.anpsn
          anpsnItem.RtpsFlag = true
          this.exptProductList.push(anpsnItem)
        })
        this.allRetProductList = this.allRetProductList.concat(this.exptProductList)
      },

      /************************************************************************************************
      * Function명 : fn_OpenProductList
      * 설명       : 개인연금 상품별 수령금액 모달 오픈
      ************************************************************************************************/
      fn_OpenProductList (param) {
        switch(param) {
          case '1':
            this.modalParam.title = '개인연금'
            this.modalParam.flag = '1'
            let t_list = []
            for (let idx in this.productList) {
              let tmp = this.productList[idx]
              console.log('this.productList[idx]', this.productList[idx])
              if (Array.isArray(tmp.yearPrice)) {
                let t_price = tmp.yearPrice
                console.log('t_price', t_price)
                if (t_price.length > 0) {
                  tmp.yearPrice =  `${this.$bizUtil.numberWithCommasByNumber(t_price[0].value)} ~ ${this.$bizUtil.numberWithCommasByNumber(t_price[t_price.length - 1].value)}`
                } else {
                  tmp.yearPrice = 0
                }
                t_list.push(tmp)
              } else {
                t_list.push(tmp)
              }
            }
            t_list.sort(function (a, b) {
              if (a.sortIdx < b.sortIdx) return -1
              if (a.sortIdx === b.sortIdx) return 0
              if (a.sortIdx > b.sortIdx) return 1
            })
            this.modalParam.modalList = t_list
            break
          case '2':
            this.modalParam.title = '국민연금'
            this.modalParam.flag = '2'
            this.modalParam.modalList = this.npensProductList
            break
          case '3':
            this.modalParam.title = '퇴직연금'
            this.modalParam.flag = '3'
            this.modalParam.modalList = this.exptProductList
            break
        }
        this.$refs.refProductList.open()
      },

      /************************************************************************************************
      * Function명 : fn_DoConversionProc
      * 설명       : 종신보험 연금전환에 대한 55세 기준의 예상연금액을 화면에 셋팅한다.
      ************************************************************************************************/
      fn_DoConversionProc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_GtConversionVal(trgItem, true)
        // 종신보험 연금 전환은 변액형과 일반형으로 올수 있으므로 회신받은 값을 확인후 분기처리한다.
        let yearPrice = 0
        if (Object.keys(trgVl).length === 0) {
          // 해약처리가 비정상일때
          return yearPrice
        } else {
          // 값이 정상일때 변액형인지 아닌지를 구별한다.
          if (Array.isArray(trgVl.val)) { // 변액형일때
            let itemArrayInner = [] // 연금 한줄(개인연금)
            for (let trgVlIdx in trgVl.val) {
              let innrItem = trgVl.val[trgVlIdx]
              itemArrayInner.push({percentage: innrItem.lvyIrt, value: Math.floor(innrItem.anutAmt / 10000)})// 변액에 따른 수익율하나
            }
            yearPrice = itemArrayInner
            return yearPrice
          } else { // 변액형이 아닐때
            yearPrice = Math.floor(trgVl.val / 10000)
            return yearPrice
          }
        }
      },

      /************************************************************************************************
      * Function명 : fn_DoPowerProc
      * 설명       : 파워즉시연금에 대한 화면을 셋팅한다.
      ************************************************************************************************/
      fn_DoPowerProc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_PowerAnnuityPro(trgItem, true) // 65세 미만임을 체크한다.
        // console.log(trgVl)
        // 파워즉시연금 정보가 없으면 0 처리
        let pensAmt = 0
        if ( !this.$bizUtil.isEmpty(trgVl) ) {
          if ( !this.$bizUtil.isEmpty(trgVl.val) ) {
            pensAmt = trgVl.val
          }
        }
        let yearPrice = Math.floor(pensAmt / 10000)
        return yearPrice

      },

      /************************************************************************************************
      * Function명 : fn_DoNormalProc
      * 설명       : 일반연금에 대한 화면을 셋팅한다.
      ************************************************************************************************/
      fn_DoNormalProc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_NrmAnnuityPro(trgItem, true) // 65세 미만임을 체크한다.
        // console.log(trgVl)
        // 일반연금 정보가 없으면 0 처리
        let pensAmt = 0
        if ( !this.$bizUtil.isEmpty(trgVl) ) {
          if ( !this.$bizUtil.isEmpty(trgVl.val) ) {
            pensAmt = trgVl.val
          }
        }
        let yearPrice = Math.floor(pensAmt / 10000)
        return yearPrice
      },
     /************************************************************************************************
     * Function명 : fn_DoPlus20Proc
     * 설명       : 저축연금에 대한 화면을 셋팅한다.
     ************************************************************************************************/
      fn_DoAnutAmt20Proc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_Anut20AnnuityPro(trgItem, true) // 65세 미만임을 체크한다.
        // console.log(trgVl)
        // 저축연금 정보가 없으면 0 처리
        let pensAmt = 0
        if ( !this.$bizUtil.isEmpty(trgVl) ) {
          if ( !this.$bizUtil.isEmpty(trgVl.val) ) {
            pensAmt = trgVl.val
          }
        }
        let yearPrice = Math.floor(pensAmt / 10000)
        return yearPrice
      },

      /************************************************************************************************
      * Function명 : fn_DoVariableProc
      * 설명       : 변액연금에 대한 화면을 셋팅한다.
      ************************************************************************************************/
      fn_DoVariableProc (trgItem) {
        let trgVl = this.$bizUtil.ctUtils.fn_VariableAnnuityPro(trgItem, true)
        let itemArray = [] // 카드
        let itemArrayInner = [] // 연금 한줄(개인연금)
        for (let trgVlIdx in trgVl.val) {
          let innrItem = trgVl.val[trgVlIdx]
          itemArrayInner.push({percentage: innrItem.lvyIrt, value: Math.floor(innrItem.anutAmt / 10000)})// 변액에 따른 수익율하나
        }
        let yearPrice = itemArrayInner
        return yearPrice
      },

     /************************************************************************************************
     * Function명 : fn_CalcAmount
     * 설명       : 개인연금 전후 확인
     ************************************************************************************************/
      fn_CalcAmount (lv_vm) {
        let cfgList = []
        cfgList = cfgList.concat(lv_vm.productList.slice()) // 개인
        cfgList = cfgList.concat(lv_vm.npensProductList.slice()) // 국민
        cfgList = cfgList.concat(lv_vm.exptProductList.slice()) // 퇴직
        let totalList = []
        totalList = totalList.concat(cfgList.slice()) // 개인연금내역 전부담기
        totalList = totalList.concat(lv_vm.productConfigList.slice()) // 가설+제외 내용 전부담기
        console.log('가설제외 기존!!!', cfgList)
        console.log('가설포함 기존!!!', totalList)
        let prePrivateAmountLow = 0 // 설계전 개인연금 최소
        let prePrivateAmountHigh = 0 // 설계전 개인연금 최대
        let preNpenLow = 0 // 설계전 국민연금 최소
        let preNpenHigh = 0 // 설계전 국민연금 최대
        let preRtpsLow = 0 // 설계전 퇴직연금 최소
        let preRtpsHigh = 0 // 설계전 퇴직연금 최대
        let preTotalLow = 0 // 설계전 총합 최소
        let preTotalHigh = 0 // 설계전 총합 최대
        let postPrivateAmountLow = 0 // 설계후 개인연금 최소
        let postPrivateAmountHigh = 0 // 설계후 개인연금 최대
        let postNpenLow = 0 // 설계후 국민연금 최소
        let postNpenHigh = 0 // 설계후 국민연금 최대
        let postRtpsLow = 0 // 설계후 퇴직연금 최소
        let postRtpsHigh = 0 // 설계후 퇴직연금 최대
        let postTotalLow = 0 // 설계후 총합 최소
        let postTotalHigh = 0 // 설계후 총합 최대
        for (let totalListIdx in totalList) {
          let totalItem = totalList[totalListIdx]
          let hasProduct = totalItem.hasProduct // 보유여부
          let npenFlag = totalItem.NpenFlag // 국민연금 여부
          let rtpsFlag = totalItem.RtpsFlag // 퇴직연금 여부
          if (npenFlag) {
            // 국민연금이면 다른 로직을 탈필요가 없음
            // 국민연금은 최소/최대가 없지만 양식을맞춰둔다.
            console.log('국민 totalItem.yearPrice>>>', totalItem.yearPrice)
            preNpenLow += totalItem.yearPrice
            preNpenHigh += totalItem.yearPrice
          } else if (rtpsFlag) {
            console.log('퇴직 totalItem.yearPrice>>>', totalItem.yearPrice)
            // 퇴직연금이면 다른 로직을 탈필요가 없음
            // 퇴직연금이면 최소/최대가 없지만 양식을맞춰둔다.
            preRtpsLow += totalItem.yearPrice
            preRtpsHigh += totalItem.yearPrice
          } else {
            // 그외 상품일때는 개인연금(설계전으로 계산하기 위해 보유 상품만 더한다.)
            if (hasProduct) {
              // 변액인경우와 아닌경우를 나누어 처리한다.
              if (Array.isArray(totalItem.yearPrice)) {
                let inrTotalItemLow = 0
                let inrTotalItemHigh = 0
                for (let iInrTotalItemIdx in totalItem.yearPrice) {
                  let inrTotalItemVal = totalItem.yearPrice[iInrTotalItemIdx]
                  if (inrTotalItemLow === 0) {
                    inrTotalItemLow = inrTotalItemVal
                  }
                  if (inrTotalItemHigh === 0) {
                    inrTotalItemHigh = inrTotalItemVal
                  }
                  if (inrTotalItemVal < inrTotalItemLow) {
                    inrTotalItemLow = inrTotalItemVal
                  }
                  if (inrTotalItemVal > inrTotalItemHigh) {
                    inrTotalItemHigh = inrTotalItemVal
                  }
                }
                prePrivateAmountLow += inrTotalItemLow
                prePrivateAmountHigh += inrTotalItemHigh
              } else {
                let trgVal = totalItem.yearPrice
                // if (trgVal === '-') {
                //   trgVal = 0
                // }
                prePrivateAmountLow += trgVal
                prePrivateAmountHigh += trgVal
              }
            }
          }
        }
        // 설계전을 구했다면 설계전 총합도 구한다.
        preTotalLow = prePrivateAmountLow + preNpenLow + preRtpsLow
        preTotalHigh = prePrivateAmountHigh + preNpenHigh + preRtpsHigh

        for (let cfgListIdx in cfgList) {
          let cfgItem = cfgList[cfgListIdx]
          let npenFlag = cfgItem.NpenFlag // 국민연금 여부
          let rtpsFlag = cfgItem.RtpsFlag // 퇴직연금 여부
          if (npenFlag) {
            // 국민연금이면 다른 로직을 탈필요가 없음
            // 국민연금은 최소/최대가 없지만 양식을맞춰둔다.
            postNpenLow += cfgItem.yearPrice
            postNpenHigh += cfgItem.yearPrice
          } else if (rtpsFlag) {
            // 퇴직연금이면 다른 로직을 탈필요가 없음
            // 퇴직연금이면 최소/최대가 없지만 양식을맞춰둔다.
            postRtpsLow += cfgItem.yearPrice
            postRtpsHigh += cfgItem.yearPrice
          } else {
            // 그외 상품일때는 개인연금(설계후로 제안상품 모두를 더한다.)

            let trgItem = cfgItem.yearPrice
            // 변액인경우와 아닌경우를 나누어 처리한다.
            if (Array.isArray(trgItem)) {
              let inrCfgItemLow = 0
              let inrCfgItemHigh = 0
              for (let inrCfgItemIdx in trgItem) {
                let inrCfgItem = trgItem[inrCfgItemIdx]
                let inrCfgItemVal = inrCfgItem.value
                if (inrCfgItemLow === 0) {
                  inrCfgItemLow = inrCfgItemVal
                }
                if (inrCfgItemHigh === 0) {
                  inrCfgItemHigh = inrCfgItemVal
                }
                if (inrCfgItemVal < inrCfgItemLow) {
                  inrCfgItemLow = inrCfgItemVal
                }
                if (inrCfgItemVal > inrCfgItemHigh) {
                  inrCfgItemHigh = inrCfgItemVal
                }
              }
              postPrivateAmountLow += inrCfgItemLow
              postPrivateAmountHigh += inrCfgItemHigh
            } else {
              let trgVal = trgItem
              // if (trgVal === '-') {
              //   trgVal = 0
              // }
              postPrivateAmountLow += trgVal
              postPrivateAmountHigh += trgVal
            }
          }
        }
        // 설계후을 구했다면 설계전 총합도 구한다.
        postTotalLow = postPrivateAmountLow + postNpenLow + postRtpsLow
        postTotalHigh = postPrivateAmountHigh + postNpenHigh + postRtpsHigh

        this.totAmountData.prePrivateAmount = this.fn_VldCmbBefore(prePrivateAmountLow, prePrivateAmountHigh) // 설계전 개인연금
        this.totAmountData.postPrivateAmount = this.fn_VldCmbBefore(postPrivateAmountLow, postPrivateAmountHigh) // 설계후 개인연금
        this.totAmountData.preNpen = this.fn_VldCmbBefore(preNpenLow, preNpenHigh) // 국민연금
        //this.totAmountData.postNpen = this.fn_VldCmbBefore(postNpenLow, postNpenHigh) // 국민연금
        this.totAmountData.preRtps = this.fn_VldCmbBefore(preRtpsLow, preRtpsHigh) // 퇴직연금
        //this.totAmountData.postRtps = this.fn_VldCmbBefore(postRtpsLow, postRtpsHigh) // 퇴직연금
        let t_totAmount = 0
        let t_allTot = 0

        let tmp_allByPrivatePercent = 0
        let tmp_allByNpenPercent = 0
        let tmp_allByRtpsPercent = 0

        if ((preTotalLow === preTotalHigh) && (postTotalLow === postTotalHigh)) { // 변액 아님
          let t_pre = Number(this.totAmountData.prePrivateAmount.replaceAll(',', ''))
          let t_post = Number(this.totAmountData.postPrivateAmount.replaceAll(',', ''))
          t_totAmount = Number(this.totAmountData.postPrivateAmount.replaceAll(',', ''))
          t_allTot = Number(this.totAmountData.preNpen.replaceAll(',', '')) + Number(this.totAmountData.preRtps.replaceAll(',', '')) + t_totAmount
          console.log('t_allTot>>', t_allTot)
          this.totAmountData.privateAmountDiff = t_post - t_pre
          this.totAmountData.prePrivateAmountPercent = t_pre >= t_post ? '100%' : `${Math.floor((t_pre / t_post) * 100)}%`
          this.totAmountData.postPrivateAmountPercent = t_post >= t_pre  ? '100%' : `${Math.floor((t_post / t_pre) * 100)}%`

          tmp_allByPrivatePercent = (Math.floor((t_post / t_allTot) * 100))
          tmp_allByNpenPercent = (Math.floor((Number(this.totAmountData.preNpen.replaceAll(',', '')) / t_allTot) * 100))
          tmp_allByRtpsPercent = (Math.floor((Number(this.totAmountData.preRtps.replaceAll(',', '')) / t_allTot) * 100))
          if (t_post > 0){
            tmp_allByPrivatePercent = 100 - (tmp_allByNpenPercent+tmp_allByRtpsPercent)
          }
          
          /* this.totAmountData.allByPrivatePercent = t_allTot !== 0 ? `${(Math.floor((t_post / t_allTot) * 100))}%` : '0%'
          this.totAmountData.allByNpenPercent = t_allTot !== 0 ? `${(Math.floor((Number(this.totAmountData.preNpen.replaceAll(',', '')) / t_allTot) * 100))}%` : '0%'
          this.totAmountData.allByRtpsPercent = t_allTot !== 0 ? `${(Math.floor((Number(this.totAmountData.preRtps.replaceAll(',', '')) / t_allTot) * 100))}%` : '0%' */
          this.totAmountData.allByPrivatePercent = t_allTot !== 0 ? `${tmp_allByPrivatePercent}%` : '0%'
          this.totAmountData.allByNpenPercent = t_allTot !== 0 ? `${tmp_allByNpenPercent}%` : '0%'
          this.totAmountData.allByRtpsPercent = t_allTot !== 0 ? `${tmp_allByRtpsPercent}%` : '0%'
        } else { // 변액
          // 변액은 최대값을 기준으로 계산한다
          let t_pre = Number(this.totAmountData.prePrivateAmount.replaceAll(',', ''))
          let t_post = Number(this.totAmountData.postPrivateAmount.split('~')[1].replaceAll(',', ''))
          t_totAmount = Number(this.totAmountData.postPrivateAmount.split('~')[1].replaceAll(',', ''))
          t_allTot = Number(this.totAmountData.preNpen.replaceAll(',', '')) + Number(this.totAmountData.preRtps.replaceAll(',', '')) + t_totAmount
          
          this.totAmountData.privateAmountDiff = t_post - t_pre
          this.totAmountData.prePrivateAmountPercent = t_pre >= t_post ? '100%' : `${Math.floor((t_pre / t_post) * 100)}%`
          this.totAmountData.postPrivateAmountPercent = t_post >= t_pre  ? '100%' : `${Math.floor((t_post / t_pre) * 100)}%`

          tmp_allByPrivatePercent = (Math.floor((t_post / t_allTot) * 100))
          tmp_allByNpenPercent = (Math.floor((Number(this.totAmountData.preNpen.replaceAll(',', '')) / t_allTot) * 100))
          tmp_allByRtpsPercent = (Math.floor((Number(this.totAmountData.preRtps.replaceAll(',', '')) / t_allTot) * 100))
          if (t_post > 0){
            tmp_allByPrivatePercent = 100 - (tmp_allByNpenPercent+tmp_allByRtpsPercent)
          }

          /* this.totAmountData.allByPrivatePercent = t_allTot !== 0 ? `${(Math.floor((t_post / t_allTot) * 100))}%` : '0%'
          this.totAmountData.allByNpenPercent = t_allTot !== 0 ? `${(Math.floor((Number(this.totAmountData.preNpen.replaceAll(',', '')) / t_allTot) * 100))}%` : '0%'
          this.totAmountData.allByRtpsPercent = t_allTot !== 0 ? `${(Math.floor((Number(this.totAmountData.preRtps.replaceAll(',', '')) / t_allTot) * 100))}%` : '0%' */
          this.totAmountData.allByPrivatePercent = t_allTot !== 0 ? `${tmp_allByPrivatePercent}%` : '0%'
          this.totAmountData.allByNpenPercent = t_allTot !== 0 ? `${tmp_allByNpenPercent}%` : '0%'
          this.totAmountData.allByRtpsPercent = t_allTot !== 0 ? `${tmp_allByRtpsPercent}%` : '0%'
        }
        
        let t_total = Number(this.totAmountData.preNpen.replaceAll(',', '')) + Number(this.totAmountData.preRtps.replaceAll(',', '')) + Number(t_totAmount)
        this.totAmountData.preTotal = this.fn_VldCmbBefore(preTotalLow, preTotalHigh)
        this.totAmountData.postTotal = this.fn_VldCmbBefore(postTotalLow, postTotalHigh)
        console.log('this.totAmountData>>>', this.totAmountData)
        this.$forceUpdate()
        this.$emit('totAmountData', this.totAmountData)
      },

      /************************************************************************************************
      * Function명 : fn_VldCmbBefore
      * 설명       : 개인연금 전후 확인
      ************************************************************************************************/
      fn_VldCmbBefore (low, high) {
        if (low === high) {
          // 설계전 -> 최저와 최고가 같으면 변액이 아님-> 1개의 값 -> 임시적으로 낮은값으로 결정
          return this.$bizUtil.numberWithCommasByNumber(low)
        } else {
          if (low > high) {
            // 설계전 -> 유효성검사로 최저가와 최고가가 맞지 않으면 최고가로 리턴 -> 만약 유효성정책이 따로 생긴다면 적용해야 한다.
            return this.$bizUtil.numberWithCommasByNumber(low)
          } else {
            // 정상적으로 범위를 표현할수 있을때
            return this.$bizUtil.numberWithCommasByNumber(low).concat('~').concat(this.$bizUtil.numberWithCommasByNumber(high))
          }
        }
      },

      /************************************************************************************************
      * Function명 : fn_productEdit
      * 설명       : 상품수정 버튼 클릭
      ************************************************************************************************/
      fn_productEdit() {
        this.editTab = '1'
        for (let i in this.allRetProductList) {
          let tmp = this.allRetProductList[i]
          tmp.checked = false
        }
        for (let j in this.productConfigList) {
          let tmp = this.productConfigList[j]
          tmp.checked = false
        }
        this.$forceUpdate()
        this.$refs.refProductEdit.open()
      },

      /************************************************************************************************
      * Function명 : fn_EditConfirm
      * 설명       : 상품수정 확인
      ************************************************************************************************/
      fn_EditConfirm() {
        let lv_Vm = this
        window.vue.getStore('progress').dispatch('FULL')
        window.vue.getStore('progress').dispatch('SHOW')
        for (let i in this.allRetProductList) { // 보유탭 상품제거 case
          let t_product = this.allRetProductList[i]
          console.log('t_product>>>', t_product)
          if (t_product.checked) { // 상품제거
            if (t_product.hasProduct) { // 보유상품 삭제처리
              this.$bizUtil.ctUtils.fn_DelAcontPrpslPrdt(lv_Vm, t_product).then(function (response) { // 기계약 제거(txTSSCT20D1)
                console.log('기계약 삭제 응답값>>', response)
                window.vue.getStore('progress').dispatch('HIDE')
                lv_Vm.$emit('reLoad') // 화면 리로드 & 데이터 갱신
                lv_Vm.$refs.refProductEdit.close()
              }).catch(function (error) {
                window.vue.getStore('progress').dispatch('HIDE')
                window.vue.error(error)
              })
            } else { // 추천상품 삭제처리
              this.$bizUtil.ctUtils.fn_DelEntplPrpslPrdt(lv_Vm, t_product).then(function (response) { // 가입설계제안 제거(txTSSCT22D1)
                console.log('가입설계 삭제 응답값은?>>', response)
                window.vue.getStore('progress').dispatch('HIDE')
                lv_Vm.$emit('reLoad') // 화면 리로드 & 데이터 갱신
                lv_Vm.$refs.refProductEdit.close()
              }).catch(function (error) {
                window.vue.getStore('progress').dispatch('HIDE')
                window.vue.error(error)
              })
            }
          }
        }
        for (let j in this.productConfigList) { // 최근가입설계 탭 상품추가 case
          let t_product = this.productConfigList[j]
          if (t_product.checked) {  // 상품 추가
            if (t_product.hasProduct) {
              this.$bizUtil.ctUtils.fn_InsAcontPrpslPrdt(lv_Vm, t_product).then(function (response) { // 기계약건 추가(txTSSCT21I1)
                console.log('기계약건 추가>>', response)
                window.vue.getStore('progress').dispatch('HIDE')
                lv_Vm.$emit('reLoad') // 화면 리로드 & 데이터 갱신
                lv_Vm.$refs.refProductEdit.close()
              }).catch(function (error) {
                window.vue.getStore('progress').dispatch('HIDE')
                window.vue.error(error)
              })
            } else { // 가입설계 데이터들
              console.log('가입설계 추가', t_product.checked)
              Promise.all([lv_Vm.$bizUtil.ctUtils.fn_InsEntplPrpslPrdt(lv_Vm, t_product) // 가입설계제안상품 추가(txTSSCT23I1)
              , lv_Vm.fn_GtEntpLanutReg(t_product)]) // detail 셋팅
                .then(function (response) {
                  console.log('response>>>>', response)
                  // let inEntp = response[0].body.inEntplVO
                  // let outEntp = response[0].body.outEntplPrpslPrdtVO[0] // 추가에 대한 답변이므로 최상위는 1개
                  // outEntp.padCylCdNm = inEntp.padCylCdNm
                  // outEntp.smtotPrm = outEntp.prm // output에도 존재하지만 이름을 바꿔서 재셋팅
                  // outEntp.prpslStatCd = inEntp.prpslStatCd
                  // outEntp.mdfcDtm = inEntp.mdfcDtm
                  // outEntp.prcd = inEntp.prcd
                  // outEntp.compAcontCovr = lv_Vm.$bizUtil.ctUtils.fn_GetThisYearPrpslPrdt(outEntp.entplPrpslPrdtCovrVO) //보장분석으로 부터 올해 보장분석을 구한다.
                  window.vue.getStore('progress').dispatch('HIDE')
                  lv_Vm.$emit('reLoad') // 화면 리로드 & 데이터 갱신
                  lv_Vm.$refs.refProductEdit.close()
                }).catch(function (error) {
                  window.vue.getStore('progress').dispatch('HIDE')
                  window.vue.error(error)
                })
            }
          }
        }
      },

      /************************************************************************************************
      * Function명 : fn_GtEntpLanutReg
      * 설명       : 
      ************************************************************************************************/
      fn_GtEntpLanutReg (item) {
        let lv_Vm = this
        this.$bizUtil.ctUtils.fn_GtEntpLanutRegProc(lv_Vm, item).then(function (response) {
          if (Object.keys(response).length === 0) {
            // 해약처리가 비정상일때
            // 아무것도 없는 내용을 셋팅시킨다.
            let itemArray = [] // 카드
            itemArray.push(['-', '-', '-', '-'].slice())
            item.details = itemArray
            lv_Vm.productList.push(item)
            // lv_Vm.productConfigList.splice(index, 1)
          } else {
            // 해약처리가 정상일때
            item.details = response// 상품에 대한 금액처리 부분을 만들어 적용해야 한다.
            lv_Vm.productList.push(item)
            // lv_Vm.productConfigList.splice(index, 1)
          }
          // 셋팅후 정렬 및 노출
          //lv_Vm.fn_CalcAmount(lv_Vm)
          Promise.resolve(response)
        }).catch(function (error) {
          Promise.reject(error)
        })
      },

      /******************************************************************************
      * Function명 : fn_MoveMSPPI001M
      * 설명       : 가입설계 화면 이동
      ******************************************************************************/
      fn_MoveMSPPI001M() {
        this.$router.push({ name: 'MSPPI001M', params: {zaAgtFileId: this.lv_BaseData.data.chnlCustId} })
      },
    }
  };
</script>
<style scoped>
</style>