<template>
  <ur-page-container class="msp " title="노후지수 연금계산기" :show-title="false" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="headerviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_ClickBtnPrevious()" />
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main txtSkip-inline">{{scrollRate > 0.5 ? '노후지수 연금계산기' : '노후지수 연금계산기'}}</div>
              <div slot="action">
                <mo-button shape="border" class="ns-btn-round btn-title-style-1 blue" @click="fn_MoveMSPPI001M">가입설계</mo-button> 
                <mo-icon icon-size="30px" type="picto" @click="open">more</mo-icon>
              </div>
            </mo-top-bar>

            
          </template>
          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="pal3024 pb20">
              <div class="bd-radius-10 pal20 bgcolor-6">
                <div class="fexTy3">
                  <div class="fs14rem"><b class="fs19rem crTy-blue3 mr6" @click="fn_PopupCustNmUiCard(params.chnlCustId)">{{ custNm }}</b>님</div>
                  <mo-button class="ns-btn-round h28 white" @click="fn_searchCustNm"><mo-icon icon="msp-search" class="mr4 crTy-blue3" />고객검색</mo-button>
                </div>
                <div class="w100" v-if="slctTabIdx === 1">
                  <span class="dsD mt5 fs16rem">연 예상연금</span>
                  <div class="mh75px dsInFlax align-item-center">
                    <span class="dsD fs16rem fwb"><span class="fs30rem">{{ $commonUtil.numberWithCommas(fn_MnisMoneyYear) }} 만원</span>(월 {{$commonUtil.numberWithCommas(fn_MnisMoneyMonth)}} 만원)</span>
                  </div>
                </div>
                <div class="w100" v-if="slctTabIdx === 2">
                  <span class="dsD mt5 fs16rem">연금액합계(65세 기준)</span>
                  <div class="mh75px dsInFlax align-item-center">
                    <div class="fwb">
                      <span v-if="Number(postTotal) === 0 && Number(preTotal) === 0" class="fs16rem dsInFlax align-item-center">
                        <span class="fs20rem">{{ preTotal }}</span>만원
                      </span>
                      <template v-else>
                        <span class="fs16rem dsInFlax align-item-center">
                          <span class="fs20rem">{{ preTotal }}</span>만원
                          <mo-icon icon="msp-arrow-line2" class="ml10 mr10" />
                        </span>
                        <span class="fs26rem dsInline crTy-blue3"><span class="fs30rem ls--6em">{{ postTotal }}</span>만원</span>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="w100 " v-if="slctTabIdx === 3">
                  <span class="dsD mt5 fs16rem">월보험료</span>
                  <div class="mh75px dsInFlax align-item-center">
                    <div class="fwb">
                      <span class="fs16rem dsInFlax align-item-center">
                        <span class="fs20rem">{{ $commonUtil.numberWithCommas(beforeMonAmt) }}</span>원 
                        <mo-icon v-if="afterMonAmt > 0" icon="msp-arrow-line2" class="ml10 mr10" />
                      </span>                      
                      <span v-if="afterMonAmt > 0" class="fs26rem dsInline crTy-blue3"><span class="fs30rem mr2">{{ $commonUtil.numberWithCommas(afterMonAmt)}}</span>원</span><!-- 127,500~137,000 -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <ur-box-container alignV="start" direction="column" class="con-area">
              <div class="w100">
                <span class="fs16rem fexTy5">
                  보유상품<b class="crTy-blue3 ml6 mr2">{{ $commonUtil.numberWithCommas(fn_ContCnt) }}</b>건
                </span>
              </div>
              <div class="mt20 bd-radius-10 box-shadow-type-3 w100 pal20">
                <div class="w100 fexTy3 fs16rem">
                  <span>월 보험료</span>
                  <span class="crTy-blue3"><b class="fs20rem mr4">{{$commonUtil.numberWithCommas(fn_totMonAmt)}}</b>원</span>
                </div>
                <div class="w100 mt10 fexTy3 fs16rem">
                  <span>총 납입</span>
                  <span><b class="fs20rem mr4">{{$commonUtil.numberWithCommas(fn_totAlpadPrmAmt)}}</b>원</span>
                </div>
              </div>
              <ul class="terms-list-area crTy-bk7 fs14rem mt20">
                <li>본 화면은 참고용으로 지급의 근거로 사용될 수 없으며, 납입방법, 연금지급방법 等 실제 계약 상태에 따라 달라질 수 있습니다.</li>
              </ul>
            </ur-box-container>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <mo-tab-box :defaultIdx="defaultIdxs" @tab-change="fn_MainTabChg" ref="tabbox"  class="ns-move-tab-box">
              <mo-tab-label :idx="1" class="w100" ><span>준비현황</span></mo-tab-label>
              <mo-tab-label :idx="2" class="w100" :disable="isTabDisable"><span>컨설팅</span></mo-tab-label>
              <mo-tab-label :idx="3" class="w100" :disable="isTabDisable"><span>설계 전·후</span></mo-tab-label>
            </mo-tab-box>

            <mo-tab-box default-idx="11" ref="refReadyTab" @tab-change="fn_subTabChg" class="ns-move-tab-box gray-tab-bar h38px widthauto bd-b-Ty1" v-show="slctTabIdx === 1 ">
              <mo-tab-label idx="11"><span>준비현황</span></mo-tab-label>
              <mo-tab-label idx="12"><span>노후지수</span></mo-tab-label>
            </mo-tab-box>

            <mo-tab-box default-idx="21" ref="refCnstTab" @tab-change="fn_subTabChg" class="ns-move-tab-box gray-tab-bar h38px widthauto bd-b-Ty1" v-show="slctTabIdx === 2 ">
              <mo-tab-label idx="21"><span>상품별</span></mo-tab-label>
              <mo-tab-label idx="22"><span>기간별</span></mo-tab-label>
            </mo-tab-box>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="">
              <!--준비현황 - 준비현황-->
              <MSPCT302D ref="refMSPCT302D" v-if="slctTabIdx === 1 && subTabIdx === '11'" :pBtnDisable="isbtnDisable" :pPensData="pensData" @reLoad="callbackRePensData" @pensSet="callbackPensSet" />
              <!--준비현황 - 노후지수-->
              <MSPCT304D ref="refMSPCT304D" v-else-if="slctTabIdx === 1 && subTabIdx === '12'" :pBtnDisable="isbtnDisable" :pPensData="pensData" :slctTabIdx="slctTabIdx" @reLoad="callbackRePensData" @pensSet="callbackPensSet" />
              <!--컨설팅 - 상품별-->
              <MSPCT307D ref="refMSPCT307D" v-else-if="slctTabIdx === 2 && subTabIdx === '21'" :pPensData="pensData" :slctTabIdx="slctTabIdx" :pComplete="isComplete" @totAmountData="callbackTotAmountData" @reLoad="callbackRePensData"  />
              <!--컨설팅 - 기간별-->
              <MSPCT308D ref="refMSPCT308D" v-else-if="slctTabIdx === 2 && subTabIdx === '22'" :pPensData="pensData" :slctTabIdx="slctTabIdx" :pComplete="isComplete" @totAmountData="callbackTotAmountData" @reLoad="callbackRePensData"  />
              <!--설계 전·후-->
              <MSPCT309D ref="refMSPCT309D" v-else-if="slctTabIdx === 3" :pPensData="pensData" :slctTabIdx="slctTabIdx" :pComplete="isComplete" @headerData="callbackHeaderData" />
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>

      </div>
    </ur-box-container> 

    <!-- 더보기 -->
    <mo-bottom-sheet ref="MSPCT302D_B01" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        더보기
      </template>
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type">
          <mo-button @click="fn_btnChk('1')" class="ns-btn-check on" :class="{ on: btn_index === '1' }">보장설계<mo-icon icon="msp-check-bold"/></mo-button><!-- class명 on시 파란색 선택 입니다. -->
          <!-- <mo-button @click="fn_btnChk('2')" class="ns-btn-check" :class="{ on: btn_index === '2' }"><mo-icon icon="msp-check-bold"/></mo-button> -->
        </ur-box-container>
      </div>
    </mo-bottom-sheet>
    <!-- 로딩 -->
    <!-- 당사정보 / 타사정보 / 실손정보 / 보장설계 db 불러오는 성격에 다른 text 수정요청드립니다.-->
    <div class="msp loading--dim msp-in-page" v-show="isLoading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>
  </ur-page-container>
</template>
<script>
import UrBoxContainer from '~/src/_uidev/containers/box/urBoxContainer.vue';
import PSAmlUtil from '@/ui/ps/comm/PSAmlUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Msg from '@/systems/webkit/msg/msg'
import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드
import MSPCT302D from '@/ui/ct/MSPCT302D'
import MSPCT304D from '@/ui/ct/MSPCT304D'
import MSPCT307D from '@/ui/ct/MSPCT307D'
import MSPCT308D from '@/ui/ct/MSPCT308D'
import MSPCT309D from '@/ui/ct/MSPCT309D'
import MSPSA060P from '@/ui/sa/MSPSA060P'
import MSPCT301P from '@/ui/ct/MSPCT301P'
import Screen from '~systems/mixin/screen'
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT300M",
    screenId: "MSPCT300M",
    mixins: [Screen],
    components: {
      MSPCT302D,
      MSPCT304D,
      MSPCT307D,
      MSPCT308D,
      MSPCT309D,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.getStore('ctStore').dispatch('ANUTRDYHDRDEL') // 헤더 초기화
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)
     // 스크롤 애니메이션 instance 선언
      this.headerviewScrollCompID = this.$refs.headerview.getScrollElementId()
      //tab
      this.fn_MainTabChg(this.defaultIdxs)
      /* if (this.$route.params.custNm !== undefined) {
        console.log('라우터!!!!')
        this.custNm = this.$route.params.custNm
        let pParam = {
          custNm: this.custNm,
          chnlCustId: this.$route.params.chnlCustId,
          custInfo: this.$route.params.custInfo
        }
        this.fn_setCustInfo(pParam)
      } */
      if (this.custNm === '') {
        this.fn_searchCustNm()
      }
    },
    beforeRouteLeave (to, from, next) {
    // 연금 페이지에서 사용하는 vuex 삭제
    console.log('연금계산기 떠남!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    this.fn_destroy()

    this.$destroy()
    next()
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        value1: '',
        slctTabIdx : 1, // 현재 선택된 탭 인덱스 값 1 : 완료건 2:중단건
        subTabIdx: '11', // 서브탭
        defaultIdxs : 1,
        btn_index: '',
        headerviewScrollCompID : '',
        isMSPSA060P: {}, // 고객검색 팝업
        isCustInfoBottomSheet: {}, // 고객네임카드
        custDtlInfo: {}, // 고객상세정보
        params:{},
        custNm: '', // 고객명
        custId: '', // 고객ID
        addPInfo: {},
        pensData: {},
        rtnList : [],
        isbtnDisable: true,
        is305PData: false,
        isComplete: false,
        isTabDisable: true, // 고객검색 전 준비현황 탭 제외 비활성화

        isLoading: false,
        loadingMsg: '',
        loadingSubMsg: '',

        preTotal: 0, // 컨설팅 연금액 합계 설계전
        postTotal: 0, // 컨설팅 연금액 합계 설계후
        beforeMonAmt: 0, // 설계 전 월 보험료
        afterMonAmt: 0, // 설계 후 월 보험료
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      fn_ContCnt: function () { // 보유연금상품
        let contCnt = this.getStore('ctStore').getters.getAnutHeader.data
        return contCnt !== undefined ? contCnt.contCnt : '0'
      },
      fn_totMonAmt: function () { // 월납입 보험료
        let totMonAmt = this.getStore('ctStore').getters.getAnutHeader.data
        return totMonAmt !== undefined ? totMonAmt.totMonAmt : '0'
      },
      fn_totAlpadPrmAmt: function () { // 납입하신 보험료
        let totAlpadPrmAmt = this.getStore('ctStore').getters.getAnutHeader.data
        return totAlpadPrmAmt !== undefined ? totAlpadPrmAmt.totAlpadPrmAmt : '0'
      },
      fn_MnisMoneyMonth: function () { // 총 월 예상연금
        let mnisMoneyMonth = this.getStore('ctStore').getters.getAnutHeader.data
        return mnisMoneyMonth !== undefined ? mnisMoneyMonth.mnisMoneyMonth : '0'
      },
      fn_MnisMoneyYear: function () { // 총 연 예상연금
        let mnisMoneyYear = this.getStore('ctStore').getters.getAnutHeader.data
        return mnisMoneyYear !== undefined ? mnisMoneyYear.mnisMoneyYear : '0'
      },
      lv_BaseData: function () { // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
      foreg: function () { // 개인추가정보
        return this.getStore('ctStore').getters.getMeminfoData.data
      }
    },
    watch: {
      custNm() {
        if (this.custNm !== '') {
          this.isbtnDisable = false
          console.log('watch 고객이름 검색완료>>', this.isbtnDisable)
        } else {
          this.isbtnDisable = true
        }
      },
      slctTabIdx(v){
        this.$refs.tabbox.setIdx(v)
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_ClickBtnPrevious () {
        if (this.$route.query?._isMenuItem) {
          console.log('메인이동')
          this.$router.push({ name: 'MSPBC002M' })
        } else {
          this.$router.go(-1)
        }
      },

      /******************************************************************************
      * Function명 : fn_MoveMSPPI001M
      * 설명       : 가입설계 화면 이동
      ******************************************************************************/
      fn_MoveMSPPI001M() {
        this.$router.push({ name: 'MSPPI001M', params: {zaAgtFileId: this.params.chnlCustId} })
      },

      /******************************************************************************
      * Function명 : fn_PopupCustNmUiCard
      * 설명       : 고객 네임카드 오픈
      ******************************************************************************/
      fn_PopupCustNmUiCard (pChnlCustId,pGubun) {
        console.log('pChnlCustId>>', pChnlCustId)
        
        //to-do 고객네임카드 연계 화면 처리
        if(pChnlCustId !== '-'){
          this.isCustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
            properties: {
              chnlCustId: pChnlCustId,
              cnstlNo: this.getStore('userInfo').getters.getUserInfo.userId,
              //contNo : contNo
              parentId: 'MSPCT300M'
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.isCustInfoBottomSheet)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.isCustInfoBottomSheet)
              }
            }
          })
        }
      },

      /******************************************************************************
      * Function명 : fn_searchCustNm
      * 설명       : 고객검색 버튼 클릭
      ******************************************************************************/
      fn_searchCustNm() {
        let lv_vm = this
        this.isMSPSA060P = this.$bottomModal.open(MSPCT301P, {
          properties: {
          },
          listeners: {
            onPopupConfirm: (data) => {
              console.log('고객검색 완료', data)
              this.fn_setCustInfo(data)
              this.$bottomModal.close(this.isMSPSA060P);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.isMSPSA060P);
              /* if (this.custNm === '') {
                this.fn_ClickBtnPrevious()
              } */
            },
            goMSPCM050M: (param) => {
              this.$bottomModal.close(this.isMSPSA060P);
              this.$router.push({ name: 'MSPCM050M', params : { custNm: param, srnId: this.$options.screenId, aprvType: '01', rtnParam: 'SUCCESS' }})
            },
            goMSPCT310M: (param) => {
              this.$bottomModal.close(this.isMSPSA060P)
              console.log('왜 이동안해??', param)
              this.$router.push({ name: 'MSPCT310M', params : { custInfo: param}})
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },

      /******************************************************************************
      * Function명 : fn_MainTabChg
      * 설명       : 메인탭(준비현황/컨설팅/설계 전후) 선택
      ******************************************************************************/
      fn_MainTabChg(idx){      
        this.slctTabIdx = idx
        switch(idx) {
          case 1 :
            this.subTabIdx = '11'
            this.$refs.refReadyTab.setIdx(this.subTabIdx)
            break;
          case 2 :
            this.subTabIdx = '21'
            this.preTotal = 0
            this.postTotal = 0
            this.$refs.refCnstTab.setIdx(this.subTabIdx)
            break;
        }
      },

      /******************************************************************************
      * Function명 : fn_subTabChg
      * 설명       : 서브탭 선택
      ******************************************************************************/
      fn_subTabChg(idx) {
        this.subTabIdx = idx
      },

      /******************************************************************************
      * Function명 : fn_setCustInfo
      * 설명       : 고객 상세정보 조회
      ******************************************************************************/
      fn_setCustInfo (data) {
        console.log('고객정보확인>>>', data)
        let lv_Vm = this
        lv_Vm.params.custNm = data.custNm // 고객명
        lv_Vm.params.chnlCustId = data.chnlCustId // 채널고객ID
        lv_Vm.params.age = data.age // 연령
        lv_Vm.params.gender = data.sxcd === '2' ? '1' : '2' // 성별 코드
        lv_Vm.custNm = lv_Vm.params.custNm
        lv_Vm.fn_destroy()
        lv_Vm.$bizUtil.ctUtils.fn_tmpAftSrchCust(lv_Vm) // 고객정보 스토어 저장
        lv_Vm.isTabDisable = false
        lv_Vm.$refs.tabbox.setIdx(1)
        lv_Vm.fn_onLoad()
      },

      /******************************************************************************
      * Function명 : fn_alertOpen
      * 설명       : 알림 오픈
      ******************************************************************************/
      fn_alertOpen (titleMsg, content, btnMsg) {
        let isAlert = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              single: false,
              title: titleMsg,
              content: content,
              title_pos_btn: btnMsg,
              //title_neg_btn: "취소"
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(isAlert)
                this.$router.push( { name: 'MSPCM050M', params : { custNm: this.params.custNm, srnId: this.$options.screenId, aprvType: '01' } } )
              },
              onPopupClose: () => {
                this.$bottomModal.close(isAlert)
              }
            }
          })
      },

      /******************************************************************************
      * Function명 : fn_onLoad
      * 설명       : 
      ******************************************************************************/
      fn_onLoad() {
        this.fn_SelCovrAnly()
      },

      /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      *               pMsg - Progress 화면 내 Msg
      ******************************************************************************/
      fn_SetProgressMsgPop(pShowFlag, pMsg, pSubMsg) {
        console.log('로딩실행!!!!', pShowFlag, pMsg, pSubMsg)
        this.isLoading = pShowFlag
        // this.loadingMsg = this.$t('ct')[pMsg]
        this.loadingMsg = '연금설계 진행 중' // message.json에서 ECTC065 문구를 수정해야 하나 CI SR 영향으로 하드코딩
        this.loadingSubMsg = this.$t('ct')[pSubMsg]
      },

      /******************************************************************************
      * Function명 : fn_SelCovrAnly
      * 설명       : 보장플랜기계약보장분석조회
      ******************************************************************************/
      fn_SelCovrAnly () {
        //this.isLoading = true
        let lv_Vm = this
        console.log('첫번째')
        lv_Vm.isComplete = false
        this.fn_SetProgressMsgPop(true, 'ECTC065', 'ECTC061')
        this.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
        this.$bizUtil.ctUtils.fn_PrmsRgstdt(lv_Vm, false).then( function (response) {
          //lv_Vm.fn_SetProgressMsgPop(false)
          console.log('첫번째 결과', response)
          //lv_Vm.fn_SetProgressMsgPop(true, 'ECTC066', 'ECTC061')
          lv_Vm.fn_baseAcont() // 기계약 조회(계약자포함)
          // 고객 나이를 세팅(고객파트에서 파라미터로 주는 나이가 보험나이가 아니여서 보험나이로 다시 세팅함)
          let gtplCllgCustVO = response.body.gtplCllgHoshVO.gtplCllgCustVO
          let custBaseInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
          gtplCllgCustVO.some( function (custInfo, custIdx) {
            let condition = custInfo.chnlCustId === custBaseInfo.chnlCustId
            if (condition) {
              custBaseInfo.age = custInfo.insrAge
              lv_Vm.getStore('ctStore').dispatch('CUSTREG', {'data': custBaseInfo})
              return (condition) // some내에서는 for문의 break와 같은 역할
            }
          })
          lv_Vm.fn_initPensLoadData()
        }).catch(function (error) {
          lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
          window.vue.error(error)
        })
      },

      /************************************************************************************************
       * Function명 : fn_baseAcont
       * 설명       : 기계약조회 조회(계약자포함)
      ************************************************************************************************/
      fn_baseAcont () {
        let lv_vm = this
        lv_vm.$bizUtil.ctUtils.fn_DrwRgstdt(lv_vm)
        console.log('2번째 getContData.data >>> ', lv_vm.getStore('ctStore').getters.getContData.data)
      },

      /******************************************************************************
      * Function명  : fn_initDataSet
      * 설명        : 초기화
      ******************************************************************************/
      fn_initDataSet () {
        this.contData = []
        this.pensData = {}
        this.addPInfo = {}
        this.acontInqrYn = ''
      },

      /******************************************************************************
      * Function명  : fn_destroy
      * 설명        : 스토어 초기화
      ******************************************************************************/
      fn_destroy () {
        //this.getStore('ctStore').dispatch('DELETE') // 보장데이터
        this.getStore('ctStore').dispatch('TCOMANUTDEL') // 당사연금
        this.getStore('ctStore').dispatch('OTCOMANUTDEL') // 타사연금
        this.getStore('ctStore').dispatch('ENTPLANUTDEL') // 가입설계제안
        this.getStore('ctStore').dispatch('NPENSDEL') // 국민연금
        this.getStore('ctStore').dispatch('RTPSDEL') // 퇴직연금
        this.getStore('ctStore').dispatch('WLIFEANUTCNVRDEL') // 종신보험연금전환
        this.getStore('ctStore').dispatch('MEMBERINFODEL') // 기존 개인추가정보
        this.getStore('ctStore').dispatch('DIRMEMINFOREG') // 직접입력하기 항목
      },

      /******************************************************************************
      * Function명  : fn_initPensLoadData
      * 설명        : 연금데이터 서비스 호출
      ******************************************************************************/
      fn_initPensLoadData () {
        let lv_Vm = this
        lv_Vm.fn_initDataSet() // 초기화
        console.log('getContData<><><>', lv_Vm.getStore('ctStore').getters.getContData)
        lv_Vm.contData = lv_Vm.getStore('ctStore').getters.getContData.data // 보장분석 기계약 vuex 데이터 로드
        let tr_wlifeAnutCnvrArr = []
        tr_wlifeAnutCnvrArr.push(lv_Vm.$bizUtil.ctUtils.fn_CustFncaf(lv_Vm)) // 당사연액/월액 조회
        tr_wlifeAnutCnvrArr.push(lv_Vm.$bizUtil.ctUtils.fn_PAddmInfo(lv_Vm)) // 개인정보추가정보 조회
        Promise.all(tr_wlifeAnutCnvrArr)
          .then(function (response) {
            lv_Vm.fn_SetProgressMsgPop(false)
            console.log('fn_initPensLoadData ::: 연금데이터 서비스 호출 성공', response)
            lv_Vm.fn_SetTcomAunt(response) // 당사연금액 정보를 가지고 당사 연금 상품 정보를 셋팅
            lv_Vm.fn_EtcAnut(response) // 개인추가정보를 가지고 타사/국민/퇴직 연금 상품 정보를 셋팅
            // 계약번호가 정상적으로 조회되면 (유효 계약번호 조회)

            lv_Vm.fn_pensDataSet()
          })
          .catch(function (error) {
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
          })
      },

      /******************************************************************************
      * Function명  : fn_SetTcomAunt
      * 설명        : 당사연금액 정보를 가지고 당사 연금 상품 정보를 셋팅
      ******************************************************************************/
      fn_SetTcomAunt (response) {
        let lv_Vm = this
        this.$bizUtil.ctUtils.fn_SetTcomAunt(lv_Vm, response) // 당사연금 셋팅
        console.log('당사연금 셋팅완료')
      },

      /******************************************************************************
      * Function명  : fn_EtcAnut
      * 설명        : 타사, 국민, 퇴직 연금 정보 조회
      ******************************************************************************/
      fn_EtcAnut (response) {
        this.addPInfo = response[1].body
        let resp = response[1].body
        console.log('개인추가정보????', response)
        // 개인추가 정보  vuex 저장 (조회오류이거나 not found 일 경우 trtRslt = -1)
        this.getStore('ctStore').dispatch('MEMBERINFOREG', {'data': resp})
        let lv_Vm = this
        this.$bizUtil.ctUtils.fn_EtcAnut(lv_Vm, resp) // 타사, 국민, 퇴직 연금 정보 조회
        console.log('타사,퇴직, 국민 연금 조회 완료')
      },

      /******************************************************************************
      * Function명  : callbackPensSet
      * 설명        : 302D,304D 연금전환 버튼 클릭 시 callback 
      ******************************************************************************/
      callbackPensSet() {
        console.log('콜백')
        this.fn_pensDataSet()
      },

      /******************************************************************************
      * Function명  : fn_pensDataSet
      * 설명        : 
      ******************************************************************************/
      fn_pensDataSet: function () {
        console.log('fn_pensDataSet ::: 당사 연금 상품 정보를 셋팅', this.foreg.jobNm)
        // vuex 에 저장된 연금 데이터를 가져온다.
        let tcomAnutList = this.getStore('ctStore').getters.getTcomAnut.data === undefined ? [] : this.getStore('ctStore').getters.getTcomAnut.data
        let otcomAnutList = this.getStore('ctStore').getters.getOtcomAnut.data === undefined ? [] : this.getStore('ctStore').getters.getOtcomAnut.data // 타사연금
        let gtpNpensInfo = this.getStore('ctStore').getters.getNpens.data // 국민연금
        let exptPpsnInfo = this.getStore('ctStore').getters.getRtps.data // 퇴직연금
        let pensTotDataList = {
          tcomAnutList: [],
          otcomAnutList: [],
          gtpNpens: [],
          exptPpsn: [],
          otherAnutList: []
        }
        let t_tcomAnutList = []
        let t_otcomAnutList = []
        let t_gtpNpensInfo = []
        let t_exptPpsnInfo = []
        console.log('당사연금>>', this.getStore('ctStore').getters.getTcomAnut)
        console.log('타사연금>>', this.getStore('ctStore').getters.getOtcomAnut)
        console.log('국민연금>>', this.getStore('ctStore').getters.getNpens)
        console.log('퇴직연금>>', this.getStore('ctStore').getters.getRtps)
        for (let i in tcomAnutList) {
          let tcomAnut = {}
          // 월납입 보험료 계산을 위해서 '납입주기', '향후 납입보험료' 항목 추가
          tcomAnut.contNo = tcomAnutList[i].contNo // 계약번호
          tcomAnut.tcomYn = tcomAnutList[i].tcomYn 
          tcomAnut.insrCoNm = tcomAnutList[i].insrCoNm // 가입회사
          tcomAnut.prdtNm = tcomAnutList[i].prdtNm // 보험명
          tcomAnut.contYmd = tcomAnutList[i].contYmd // 계약년월
          tcomAnut.rcvPrd = tcomAnutList[i].rcvPrd // 수령기간
          tcomAnut.smtotPrm = tcomAnutList[i].smtotPrm // 보험료
          tcomAnut.padCylCd = tcomAnutList[i].padCylCd // 납입주기
          tcomAnut.prpslStatCd = tcomAnutList[i].prpslStatCd // 제안상태코드(01:포함, 02:제외)
          tcomAnut.alpadTotPrm = tcomAnutList[i].alpadTotPrm.longAmount // 기납입총보험료
          tcomAnut.padParngTotPrm = tcomAnutList[i].padParngTotPrm.longAmount // 향후 납입보험료
          tcomAnut.anpsn = Math.floor(tcomAnutList[i].anpsn / 10000) // 예상연금연액(65세)
          tcomAnut.anpsn01 = Math.floor(tcomAnutList[i].anpsn01 / 10000) // 예상연금월액(65세)
          tcomAnut.anpsnStartAmount = tcomAnutList[i].anpsnStartAmount
          tcomAnut.anutBgnMnisdAge = tcomAnutList[i].anutBgnMnisdAge
          tcomAnut.anutBgnAisdAge = tcomAnutList[i].anutBgnAisdAge
          tcomAnut.anutBgnYmd = tcomAnutList[i].anutBgnYmd
          t_tcomAnutList.push(tcomAnut)
        }
        pensTotDataList.tcomAnutList = t_tcomAnutList
        for (let j in otcomAnutList) {
          let otcomAnut = {}
          otcomAnut.contNo = otcomAnutList[j].contNo // 계약번호
          otcomAnut.insrCoNm = otcomAnutList[j].insrCoNm // 가입회사
          otcomAnut.prdtNm = otcomAnutList[j].prdtNm // 보험명
          otcomAnut.contYmd = otcomAnutList[j].contYmd // 계약년월
          otcomAnut.rcvPrd = otcomAnutList[j].rcvPrd // 수령기간 
          otcomAnut.smtotPrm = otcomAnutList[j].smtotPrm.longAmount// 보험료
          otcomAnut.prpslStatCd = otcomAnutList[j].prpslStatCd // 제안상태코드(01:포함, 02:제외)
          otcomAnut.alpadTotPrm = otcomAnutList[j].alpadTotPrm.longAmount // 기납입총보험료
          otcomAnut.otcomAnutBgnAge = otcomAnutList[j].otcomAnutBgnAge // 연금 개시나이
          otcomAnut.otcomAnutEndAge = otcomAnutList[j].otcomAnutEndAge // 연금 종료나이
          otcomAnut.anpsn = Math.floor(otcomAnutList[j].anpsn / 10000) // 예상연금연액
          //otcomAnut.anpsn = otcomAnutList[j].otcomAnutBgnAge <= 65 ? Math.floor(otcomAnutList[j].anpsn / 10000) : 0 // 예상연금연액(65세)
          otcomAnut.anpsn01 = Math.floor(otcomAnutList[j].anpsn01 / 10000) // 예상연금월액
          //otcomAnut.anpsn01 = otcomAnutList[j].otcomAnutBgnAge <= 65 ? Math.floor(otcomAnutList[j].anpsn01 / 10000) : 0 // 예상연금월액(65세)
          t_otcomAnutList.push(otcomAnut)
          pensTotDataList.otherAnutList.push(otcomAnut)
        }
        pensTotDataList.otcomAnutList = t_otcomAnutList
        if ( this.foreg.jobNm === '1') { // 직업군이 직장인(:1)인 경우에만, 국민, 퇴직 정보를 구한다.
          if ( gtpNpensInfo !== undefined ) {
            let gtpNpens = {}
            gtpNpens.contNo = 0 // 계약번호 빈값
            gtpNpens.insrCoNm = '-' // 가입회사
            gtpNpens.prdtNm = '국민연금' // 보험명은 '국민연금'
            gtpNpens.contYmd = (isNaN(this.addPInfo.ecmpYr) !== true ? this.addPInfo.ecmpYr : '0')// 계약년월 -> 입사년도로 표시 (값이 없으면 초기 빈값)
            gtpNpens.rcvPrd = gtpNpensInfo.rcvPrd // 수령기간 : 종신 99
            gtpNpens.smtotPrm = 0
            gtpNpens.alpadTotPrm = 0
            gtpNpens.anpsn = Math.floor(gtpNpensInfo.anpsn[2] / 10000) // 연령구간중 연금연액 65세 값 셋팅 (6개구간 55,60,65,70,75,80 값중 세번째 값)
            gtpNpens.anpsn01 = Math.floor(gtpNpensInfo.anpsn01[2] / 10000) // 연령구간중 연금월액 65세 값 셋팅 (6개구간 55,60,65,70,75,80 값중 세번째 값)
            gtpNpens.anpsnList = gtpNpensInfo.anpsn // 연령구간 연금월액 값 셋팅 (6개구간 55,60,65,70,75,80 값)
            t_gtpNpensInfo.push(gtpNpens)
            pensTotDataList.otherAnutList.push(gtpNpens) // 모니모 추가 될 때 대비
          }
          pensTotDataList.gtpNpens = t_gtpNpensInfo
          if ( exptPpsnInfo !== undefined ) {
            let exptPpsn = {}
            exptPpsn.contNo = 0 // 계약번호 빈값
            exptPpsn.insrCoNm = '-' // 가입회사
            exptPpsn.prdtNm = '퇴직연금' // 보험명은 '퇴직연금'
            exptPpsn.contYmd = (isNaN(this.addPInfo.ecmpYr) !== true ? this.addPInfo.ecmpYr : '0')// 계약년월 -> 입사년도로 표시 (값이 없으면 초기 빈값)
            exptPpsn.rcvPrd = exptPpsnInfo.rcvPrd // 수령기간 : 종신:99
            exptPpsn.smtotPrm = 0
            exptPpsn.alpadTotPrm = 0
            exptPpsn.anpsn = Math.floor(exptPpsnInfo.anpsn[2] / 10000) // 연령구간중 연금연액 65세 값 셋팅 (6개구간 55,60,65,70,75,80 값중 세번째 값)
            exptPpsn.anpsn01 = Math.floor(exptPpsnInfo.anpsn01[2] / 10000) // 연령구간중 연금월액 65세 값 셋팅 (6개구간 55,60,65,70,75,80 값중 세번째 값)
            exptPpsn.anpsnList = exptPpsnInfo.anpsn // 연령구간 연금월액 값 셋팅 (6개구간 55,60,65,70,75,80 값)
            t_exptPpsnInfo.push(exptPpsn)
            pensTotDataList.otherAnutList.push(exptPpsn) // 모니모 추가 될 때 대비
          }
          pensTotDataList.exptPpsn = t_exptPpsnInfo
        }
        this.pensData = pensTotDataList
        this.isComplete = true
        console.log('300M this.pensData>>>>>', this.pensData )
        console.log('선택한 탭>>>', this.slctTabIdx)
        this.fn_pensHeaderSet() // 보유상품 건수, 총납입, 월 보험료, 연-월 예상연금 셋팅
        this.fn_makeGraphData() // 그래프용 데이터 셋팅
      },

      /******************************************************************************
      * Function명  : fn_pensHeaderSet
      * 설명        : 
      ******************************************************************************/
      fn_pensHeaderSet: function () {
        console.log('fn_pensHeaderSet>>헤더 셋팅')
        // 연금전환 IF까지 호출이 완료된 시점에 헤더부분을 생성한다.
        let lv_Vm = this
        let anuntHdrItem = {}
        // 보유 연금상품 건수
        anuntHdrItem.contCnt = lv_Vm.pensData.tcomAnutList.length + lv_Vm.pensData.otcomAnutList.length + lv_Vm.pensData.gtpNpens.length + lv_Vm.pensData.exptPpsn.length
        
        // 월 납입 보험료
        let lvSmtotPrm = 0
        for (let i in lv_Vm.pensData.tcomAnutList) { // 당사연금
          let padCylCd = lv_Vm.pensData.tcomAnutList[i].padCylCd // 당사연금 납입주기
          let smtotPrm = lv_Vm.pensData.tcomAnutList[i].smtotPrm.longAmount // 보험료
          let padParngTotPrm = lv_Vm.pensData.tcomAnutList[i].padParngTotPrm // 당사 향후 납입 보험료
          let retVal = lv_Vm.$bizUtil.ctUtils.fn_getMonAmt(padCylCd, smtotPrm, padParngTotPrm)
          lvSmtotPrm += Number(retVal)
        }
        for (let i in lv_Vm.pensData.otcomAnutList) { // 타사연금
          let padCylCd = lv_Vm.pensData.otcomAnutList[i].padCylCd // 납입주기
          let smtotPrm = lv_Vm.pensData.otcomAnutList[i].smtotPrm // 보험료
          let padParngTotPrm = lv_Vm.pensData.otcomAnutList[i].padParngTotPrm // 당사 향후 납입 보험료
          let retVal = lv_Vm.$bizUtil.ctUtils.fn_getMonAmt(padCylCd, smtotPrm, padParngTotPrm)
          lvSmtotPrm += Number(retVal)
        }
        for (let i in lv_Vm.pensData.gtpNpens) { // 국민연금
          let padCylCd = lv_Vm.pensData.gtpNpens[i].padCylCd // 납입주기
          let smtotPrm = lv_Vm.pensData.gtpNpens[i].smtotPrm // 보험료
          let padParngTotPrm = lv_Vm.pensData.gtpNpens[i].padParngTotPrm // 당사 향후 납입 보험료
          let retVal = lv_Vm.$bizUtil.ctUtils.fn_getMonAmt(padCylCd, smtotPrm, padParngTotPrm)
          lvSmtotPrm += Number(retVal)
        }
        for (let i in lv_Vm.pensData.exptPpsn) { // 퇴직연금
          let padCylCd = lv_Vm.pensData.exptPpsn[i].padCylCd // 납입주기
          let smtotPrm = lv_Vm.pensData.exptPpsn[i].smtotPrm // 보험료
          let padParngTotPrm = lv_Vm.pensData.exptPpsn[i].padParngTotPrm // 당사 향후 납입 보험료
          let retVal = lv_Vm.$bizUtil.ctUtils.fn_getMonAmt(padCylCd, smtotPrm, padParngTotPrm)
          lvSmtotPrm += Number(retVal)
        }
        anuntHdrItem.totMonAmt = lvSmtotPrm

        // 납입하신 보험료
        let lvTotAlpadPrmAmt = 0
        for (let i in lv_Vm.pensData.tcomAnutList) { // 기납입 당사연금 보험료
          lvTotAlpadPrmAmt += Number(lv_Vm.pensData.tcomAnutList[i].alpadTotPrm)
        }
        for (let i in lv_Vm.pensData.otcomAnutList) { // 기납입 타사연금 보험료
          lvTotAlpadPrmAmt += Number(lv_Vm.pensData.otcomAnutList[i].alpadTotPrm)
        }
        anuntHdrItem.totAlpadPrmAmt = lvTotAlpadPrmAmt

        
        let lvTotMnisMnyMonAmt = 0 // 총 월 예상연금
        let lvTotMnisMnyYearAmt = 0 // 총 연 예상연금
        for (let i in lv_Vm.pensData.tcomAnutList) { // 당사
          lvTotMnisMnyMonAmt += Number(lv_Vm.pensData.tcomAnutList[i].anpsn)
          lvTotMnisMnyYearAmt += Number(lv_Vm.pensData.tcomAnutList[i].anpsn)
        }
        for (let i in lv_Vm.pensData.otcomAnutList) { // 타사
          lvTotMnisMnyMonAmt += Number(lv_Vm.pensData.otcomAnutList[i].anpsn)
          lvTotMnisMnyYearAmt += Number(lv_Vm.pensData.otcomAnutList[i].anpsn)
        }
        for (let i in lv_Vm.pensData.gtpNpens) { // 국민
          lvTotMnisMnyMonAmt += Number(lv_Vm.pensData.gtpNpens[i].anpsn)
          lvTotMnisMnyYearAmt += Number(lv_Vm.pensData.gtpNpens[i].anpsn)
        }
        for (let i in lv_Vm.pensData.exptPpsn) { // 퇴직
          lvTotMnisMnyMonAmt += Number(lv_Vm.pensData.exptPpsn[i].anpsn)
          lvTotMnisMnyYearAmt += Number(lv_Vm.pensData.exptPpsn[i].anpsn)
        } 
        anuntHdrItem.mnisMoneyMonth = Math.floor(lvTotMnisMnyMonAmt / 12)
        anuntHdrItem.mnisMoneyYear = lvTotMnisMnyYearAmt
        lv_Vm.getStore('ctStore').dispatch('ANUTRDYHDR', {'data': anuntHdrItem})
        console.log('계산값 확인>>>', this.getStore('ctStore').getters.getAnutHeader.data)
      },

      /************************************************************************************************
      * Function명 : fn_makeGraphData
      * 설명       : 당사, 타사, 국민, 퇴직 연금 그래프용 데이터 생성
      ************************************************************************************************/
      fn_makeGraphData () {
        let lv_Vm = this
        let curYear = lv_Vm.curYear
        // let tcompList = lv_Vm.$bizUtil.cloneDeep(lv_Vm.tcompList.data) // 당사. 종신보험연금전환 상품 보여줄시 원본값 사용하면 에러발생하여, 값 복제하여 사용.
        // let tcompList = this.pensData.tcomAnutList // this.getStore('ctStore').getters.getTcomAnut.data
        let tcompList = lv_Vm.$bizUtil.cloneDeep(this.getStore('ctStore').getters.getTcomAnut.data)
        console.log('그래프용 함수 tcompList>', tcompList)
        let wlifeAnutCnvrList = lv_Vm.getStore('ctStore').getters.getWlifeAnutCnvr.wlifeAnutCnvrList // 종신보험연금전환 데이터목록
        console.log('종신보험연금전환 데이터 목록>>', wlifeAnutCnvrList)
        let wlifeAnutCnvrShowFlag = lv_Vm.getStore('ctStore').getters.getWlifeAnutCnvr.showPension // 종신보험연금전환 라디오버튼 선택여부
        let wlifeAnutCnvrShow1Flag = lv_Vm.getStore('ctStore').getters.getWlifeAnutCnvr.showPension1 // 생애설계자금전환 라디오버튼 선택여부
        console.log('종신보험연금전환 선택여부>>', wlifeAnutCnvrShowFlag)
        let otcompList = lv_Vm.getStore('ctStore').getters.getOtcomAnut.data // 타사
        // let entplList = this.getStore('ctStore').getters.getEntplAnut.data // 가입설계
        let npens = lv_Vm.getStore('ctStore').getters.getNpens.data // 국민
        let rtps = lv_Vm.getStore('ctStore').getters.getRtps.data // 퇴직
        let custAge = Number(lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.age)
        let rtnList = {
          tcompList: [],
          otcompList: [],
          gtpNpens : [],
          exptPpsn : [],
          otherAnutList: []
        }
        let anutBgnAge = 0
        let anpsn = [] // 기간별 그래프 영역에서 사용하도록 연금액 형태변환

        for (let tcompIdx in tcompList) {
          if (tcompList[tcompIdx].wlifeAnutCnvrYN === 'Y') continue // 연금전환인 데이터는 아래 다음 for문에서 처리함.
          let type = 'tcomp'
          let tmpObj = {}
          anutBgnAge = tcompList[tcompIdx].anutBgnMnisdAge
          tmpObj.type = type
          tmpObj.compNm = tcompList[tcompIdx].insrCoNm
          tmpObj.prdtNm = tcompList[tcompIdx].prdtNm
          tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, type, anutBgnAge, tcompList[tcompIdx].anpsnStartAmount)
          //tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_MergeSamePrm('before', tmpObj.anpsn, 'prm')
          console.log('확인중!!!!', tmpObj.anpsn)
          tmpObj.startGraphIdx = tmpObj.anpsn.findIndex(item => item.prm > 0)
          for (let i = 0; i < tmpObj.anpsn.length; i++) {
            let t_prm = tmpObj.anpsn[i].prm
            if (i > tmpObj.startGraphIdx) {
                if (t_prm === 0) {
                  tmpObj.endGraphIdx = i - 1
                  break
                } else {
                  tmpObj.endGraphIdx = i
                }
              }
          }
          lv_Vm.pensData.tcomAnutList.forEach(item => {
            if (item.contNo === tcompList[tcompIdx].contNo) {
              item.startGraphIdx = tmpObj.startGraphIdx
              item.endGraphIdx = tmpObj.endGraphIdx
            }
          })
          //rtnList.tcompList.push(tmpObj)
        }

        if (wlifeAnutCnvrShowFlag || wlifeAnutCnvrShow1Flag) {
          for (let wlifeAnutCnvrIdx in wlifeAnutCnvrList) {
            let type = 'wlifeAnutCnvr'
            wlifeAnutCnvrList[wlifeAnutCnvrIdx].anutBgnYmd = String(Number(curYear) - custAge + 65)
            // wlifeAnutCnvrList[wlifeAnutCnvrIdx].anpsn = wlifeAnutCnvrList[wlifeAnutCnvrIdx].entireAgeAnpsnVO
            let tmpObj = {}
            anutBgnAge = wlifeAnutCnvrList[wlifeAnutCnvrIdx].anutBgnMnisdAge
            tmpObj.type = type
            tmpObj.compNm = wlifeAnutCnvrList[wlifeAnutCnvrIdx].insrCoNm
            tmpObj.prdtNm = wlifeAnutCnvrList[wlifeAnutCnvrIdx].prdtNm
            // tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, type, anutBgnAge, wlifeAnutCnvrList[wlifeAnutCnvrIdx].anpsn)
            tmpObj.anpsn = lv_Vm.$bizUtil.ctUtils.fn_AnutRcvPbscAge(lv_Vm, type, anutBgnAge, wlifeAnutCnvrList[wlifeAnutCnvrIdx].entireAgeAnpsnVO)
            console.log('종신보험 anpsn', tmpObj.anpsn)
            tmpObj.startGraphIdx = tmpObj.anpsn.findIndex(item => item.prm > 0)
            for (let i = 0; i < tmpObj.anpsn.length; i++) {
              let t_prm = tmpObj.anpsn[i].prm
              if (i > tmpObj.startGraphIdx) {
                  if (t_prm === 0) {
                    tmpObj.endGraphIdx = i - 1
                    break
                  } else {
                    tmpObj.endGraphIdx = i
                  }
                }
            }
            lv_Vm.pensData.tcomAnutList.forEach(item => {
              if (item.contNo === wlifeAnutCnvrList[wlifeAnutCnvrIdx].contNo) {
                item.startGraphIdx = tmpObj.startGraphIdx
                item.endGraphIdx = tmpObj.endGraphIdx
              }
            })
          }
        }
        for (let otcompIdx in otcompList) { // 연금 추가 타사연금
          let tmpObj = {}
          // anutBgnAge = CtConstants.otcomAnutBgnAge // 타사연금개시나이 고정값
          anutBgnAge = otcompList[otcompIdx].otcomAnutBgnAge
          let anutEndAge = otcompList[otcompIdx].acomtYn === 'Y' ? anutBgnAge + CtConstants.anutRcvPrd : otcompList[otcompIdx].otcomAnutEndAge // 직접입력 타사일경우는 연금종료나이를 지정함.
          tmpObj.type = 'otcomp'
          tmpObj.compNm = otcompList[otcompIdx].insrCoNm
          tmpObj.prdtNm = otcompList[otcompIdx].prdtNm
          tmpObj.anpsn = this.$bizUtil.ctUtils.fn_AnutRcvPbscAge(this, 'otcomp', anutBgnAge, otcompList[otcompIdx].anpsn, anutEndAge)
          console.log('타사연금 금액 확인!!!', tmpObj.anpsn)
          let startGraphIdx = 0
          let endGraphIdx = 0
          startGraphIdx = tmpObj.anpsn.findIndex(item => item.prm > 0)
          for (let i = 0; i < tmpObj.anpsn.length; i++) {
            let t_prm = tmpObj.anpsn[i].prm
            if (i > startGraphIdx) {
                if (t_prm === 0) {
                  endGraphIdx = i - 1
                  break
                } else {
                  endGraphIdx = i
                }
              }
          }
          lv_Vm.pensData.otherAnutList.forEach(item => {
              if (item.prdtNm === '타사연금') {
                item.startGraphIdx = startGraphIdx
                item.endGraphIdx = endGraphIdx
                item.chartType = ''
              }
            })
        }
        console.log('국민연금!!!!!!!!!', npens)
        if (npens !== undefined) { // 고객추가정보가 없을경우 국민/퇴직 연금 조회안됨
          if ( this.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 퇴직 정보를 구한다
            anpsn = this.$bizUtil.ctUtils.fn_AnutRcvPbscAge(this, 'npens', 0, npens.anpsn)
            let startGraphIdx = 0
            let endGraphIdx = 0
            let cssIdx = []
            console.log('국민연금 anpsn 확인!@!@!@!', anpsn)
            startGraphIdx = anpsn.findIndex(item => item.prm > 0)
            anpsn.forEach((e, idx) => {
              if (idx >= startGraphIdx) {
                endGraphIdx = idx
                cssIdx.push(idx)
              } else {
                cssIdx.push(0)
              }
            })
            lv_Vm.pensData.otherAnutList.forEach(item => {
              if (item.prdtNm === '국민연금') {
                item.startGraphIdx = startGraphIdx
                item.endGraphIdx = endGraphIdx
                item.chartType = '1'
                item.cssIdx = cssIdx
              }
            })
          }
        }
        console.log('기존 국민연금', this.pensData.gtpNpens)
        if (rtps !== undefined) { // 고객추가정보가 없을경우 국민/퇴직 연금 조회안됨
          if ( this.foreg.jobNm === '1' ) { // 직업군이 직장인(:1)인 경우에만, 퇴직 정보를 구한다
            anpsn = this.$bizUtil.ctUtils.fn_AnutRcvPbscAge(this, 'rtps', 0, rtps.anpsn)
            let startGraphIdx = 0
            let endGraphIdx = 0
            console.log('퇴직연금 anpsn 확인!@!@!@!', anpsn)
            startGraphIdx = anpsn.findIndex(item => item.prm > 0)
            for (let i = 0; i < anpsn.length; i++) {
              let t_prm = anpsn[i].prm
              if (i > startGraphIdx) {
                  if (t_prm === 0) {
                    endGraphIdx = i - 1
                    break
                  } else {
                    endGraphIdx = i
                  }
                }
            }
            lv_Vm.pensData.otherAnutList.forEach(item => {
              if (item.prdtNm === '퇴직연금') {
                item.startGraphIdx = startGraphIdx
                item.endGraphIdx = endGraphIdx
                item.chartType = ''
              }
            })
          }
        }
        console.log('기존 퇴직연금', this.pensData.exptPpsn)
        console.log('this.pensData.otherAnutList>>>', this.pensData.otherAnutList)
        
        this.rtnList = rtnList
        console.log('this.rtnList>>', this.rtnList)
        if (this.is305PData) {
          this.getStore('toast').dispatch('ADD', '입력하신 내용이 반영되었습니다.')
        }
        this.is305PData = false
      },

      onSelect(value) {
        this.selectedItem = this.$refs.bottomSelect.getSelectedItem();
      },

      fn_btnChk (str) {
        this.btn_index = str
        console.log('params>>>', this.params)
        this.$router.push({name: 'MSPCT110M', params: this.params})
      },

      callbackTotAmountData (param) {
        console.log('자식에게 받은 데이터>>', param)
        if (Object.keys(param).length > 0) {
          this.preTotal = param.preTotal
          this.postTotal = param.postTotal
        } else {
          this.preTotal = 0
          this.postTotal = 0
        }
      },

      callbackRePensData (data) {
        console.log('상품수정하여 콜백 실행!!', data)
        this.is305PData = data
        this.fn_onLoad()
      },

      callbackHeaderData (param) {
        console.log('설계 전후 헤더 데이터 전송!!', param)
        if (Object.keys(param).length > 0) {
          this.beforeMonAmt = param.beforeMonAmt
          this.afterMonAmt = param.afterMonAmt
        } else {
          this.beforeMonAmt = 0
          this.afterMonAmt = 0
        }
      },

      open() { this.$refs.MSPCT302D_B01.open();  },
      close() { this.$refs.MSPCT302D_B01.close(); },

    },
  };
</script>
<style scoped>
</style>