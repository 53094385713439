
/*
  mixin (2018/06/01 정재윤)
    - 화면 접근 시 화면별 ACL 통제
    - keep-alive 관리
*/
const isDebug = false

// 열린화면에 표시되어야 하는 화면 목록
const openScreenInclude = [
  // 안내자료, 한글자운세
  'MSPSA201M', 'MSPSA071M',
  // 주간활동현황, 활동현황확인
  'MSPAP307M', 'MSPAP105M',
  // 최근설계, 가입보장설계
  'MSPPI310M', 'MSPPI700M',
  // 청약서발행현황, 전자서명현황, 신계약진행현황, 모바일청약현황, 상설(제안용)재발행, 상품설명서재발행, 비교안내문재발행, 청약서재발행
  'MSPPS220M', 'MSPPS240M', 'MSPPS250M', 'MSPPS230M', 'MSPPS310M', 'MSPPS320M', 'MSPPS330M', 'MSPPS340M',
  // 전체고객, 프리미엄고객사랑서비스, 1건고객, VIP서비스신청, 수금인수고객, 관심고객, 이벤트고객, 미승인고객, 삭제예정고객, 정보활용동의현황, 고객접촉정보, 캠페인고객, 내그룹, 수익증권추천고객, 대출가망고객, 추가계약 추천고객, New 통합조회 시스템
  'MSPCM115M', 'MSPCM303M', 'MSPCM150M', 'MSPCM180M', 'MSPCM322M', 'MSPCM317M', 'MSPCM124M', 'MSPCM220M', 'MSPCM217M', 'MSPCM170M', 'MSPCM121M', 'MSPCM160M', 'MSPCM210M', 'MSPCM240M', 'MSPCM250M', 'MSPCM330M', 'MSPCM340M',
  // 보유계약, 자동이체미이체, 수금체크명세, 미결명세조회, 우량체미변경계약, 휴면보험금, 사고보험금조회, 유지체크명세, 제휴계약, 재가입대상계약, UL통합관리
  'MSPPM902M', 'MSPPM910M', 'MSPPM711M', 'MSPPM720M', 'MSPPM731M', 'MSPPM741M', 'MSPPM911M', 'MSPPM870M', 'MSPPM891M', 'MSPPM850M', 'MSPPM920M', 
  // 전체후보자
  'MSPRC200M',
  // 연금계산기, '보장설계'
  'MSPCT300M', 'MSPCT110M'
]

export default {
  beforeCreate () {
    customLogger(this, '02. beforeCreate')
  },
  created () {
    this.getStore('pageAcl').dispatch('CURSET', this.$options.screenId)
    customLogger(this, '03. created')
  },
  beforeMount () {
    customLogger(this, '04. beforeMount')
  },
  mounted () {
    customLogger(this, '05. mounted')
  },
  activated () {
    customLogger(this, '06. activated')

    // keep alive 목록
    let keepAliveArr = window.vue.getStore('keepalive').getters.getInclude
    // 현재 화면 ID
    let currentScreenId = this.$options.screenId

    if (keepAliveArr.includes(currentScreenId) && openScreenInclude.includes(currentScreenId)) {
      this.$commonUtil.openScreenAdd(this)
    }
  },
  beforeUpdate () {
    customLogger(this, '07. beforeUpdate')
  },
  updated () {
    customLogger(this, '08. updated')
  },
  beforeDestroy () {
    customLogger(this, '11. beforeDestroy')
  },
  destroyed () {
    customLogger(this, '12. destroyed')
  },
  deactivated () {
    customLogger(this, '10. deactivated')
  },
  beforeRouteEnter (to, from, next) { // router.beforeEach 사이에 router.afterEach 수행됨 (beforeCreate 전)
    customLogger(this, '01. beforeRouteEnter')
    next()
  },
  beforeRouteLeave (to, from, next) { // 이 이후에, router.beforeEach, router.afterEach 수행됨
    customLogger(this, '09. beforeRouteLeave')
    // dynaTraceLog(to.name)

    var _this = this
    // 화면 차단 서비스 수행
    _this.$commonUtil.checkScreenBlock(to, from, _this).then(function (response) {
      // 로그 직접 호출로 변경
      // let params = {
      //   'curMobslSrnId': from.name,
      //   'nxtMobslSrnId': to.name
      // }
      // _this.post(_this, params, 'txTSSAD10I1', 'I').then(function (response) {
      //   console.log('logging success')
      // }).catch(function (e) {
      //   window.vue.error(e)
      // })
      // 다음 화면으로 이동 수행
      next()
    }).catch(function (content) {
      // 화면 차단 메시지를 confirm으로 표시
      _this.getStore('confirm').dispatch('ADD', content)
    })
  }
}

function customLogger (context, lifeCycle) {
  if (isDebug) {
    console.log('### [' + (context ? (context.$options ? (context.$options.name || 'undefined') : 'undefiend') : 'undefined') + ']\t - mixin - ' + lifeCycle)
    // console.log(context)
  }
}

// function dynaTraceLog (name) {
//   try {
//     if ( typeof dynaTrace !== 'undefined' ) {
//       // eslint-disable-next-line
//       dynaTrace.reportString('nxtMobslSrnId', name)
//     }
//   } catch (e) {
//     window.vue.error(e)
//   }
// }
