<template>
  <ur-page-container class="msp" title="본인인증" :show-title="true" type="subpage" @on-header-left-click="fn_close">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <mo-validate-watcher ref="agreeValidateWatcher">
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 pb30">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-sed pal0">
            <div class="ns-certify-sed pal0 mt10" :class="isPensValidation ? 'error' : ''">
              <div class="ns-check fexTy5">
                <mo-checkbox v-model="isPensYn" class="crTy-bk7 ls--15">본인은 연금계산 서비스 이용을 희망합니다.</mo-checkbox>
              </div>
              <div v-if="isPensValidation" class="ns-certify-sed-txt">
                <div class="txt-error">연금계산기 이용 동의가 필요합니다.</div>
              </div>
            </div>
          </ur-box-container>
          <!--<ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
            <mo-segment-wrapper @click="fn_tabClick" solid primary v-model="tabIdx" class="chip-type-wrap chip-ty1">
              <mo-segment-button value="1">휴대폰인증</mo-segment-button>
              <mo-segment-button value="2">카카오페이</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container> -->

          <ur-box-container direction="column" alignV="start" componentid="" class="ns-certify-sed pal0" v-if="tabIdx === '1'">          
            <div class="ns-certify-sed pal0 mt10" :class="isAgreeValidation ? 'error' : ''"> <!-- 에러시 class에 error 추가 -->
              <div class="ns-check arrow fexTy5" @click="fn_AgreePopContent(true)">
                <mo-checkbox v-model="isPhoneAgreeYn" class="crTy-bk7" @input="fn_AgreePopContent(isPhoneAgreeYn)">본인확인 서비스 이용 동의</mo-checkbox>
              </div>
              <div v-if="isAgreeValidation" class="ns-certify-sed-txt">
                <div class="txt-error">본인확인 서비스 이용 동의 해 주세요.</div>
              </div>
            </div>
            <div class="ns-certify-sed pal0 mt10"> <!-- 에러시 class에 error 추가 -->
              <div class="ns-certify-sed-code row-text">
                <mo-text-field  class="form-input-name full" v-model="custNm" :rules="isCustNmValidation" underline placeholder="고객명을 입력하세요." />
              </div>
              <div class="ns-certify-sed-txt">
                <div class="txt-error">이름을 입력해주세요.</div>
              </div>
            </div>

            <div class="ns-certify-sed pal0 mt20"> <!-- 에러시 class에 error 추가 -->
              <div class="ns-certify-sed-code row-text">
              <!-- <div class="verify-birth"> -->
                <mo-text-field  type="number" mask="######" underline class="birth-input1 ty--02 form-input-name" v-model="knb1" :rules="isKnb1Validation"  placeholder="생년월일"/>
                <span class="hyphen ml10 mr10"></span>
                <mo-text-field type="number" v-model="knb2" mask="#" :rules="isKnb2Validation" placeholder="0" underline class="birth-input2 ty--02 pl4 form-input-name w20px" />
                <em class="password w100">●●●●●●</em>
              </div>
            </div>

            <div class="ns-certify-sed pal0 mt20"> <!-- 에러시 class에 error 추가 -->
              <div class="ns-certify-sed-code row-text">
                <msp-bottom-select v-model="slctTelecomType" :items="telecomTypes" :rules="isTelecomValidation" underline class="w200px mr10" placeholder="선택" bottom-title="통신사" closeBtn scrolling/>
                <mo-decimal-field mask="phone" class="w100" v-model="phoneNum" :rules="isCelNumValidation" placeholder="휴대폰 번호를 입력해주세요" />
              </div>
              <div class="ns-certify-sed-txt">
                <div class="txt-error">통신사를 선택해 주세요.</div>
              </div>
              <div class="ns-certify-sed-txt">
                <div class="txt-error">휴대폰 번호를 입력해 주세요.</div>
              </div>
            </div>
            <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
                <div class="ns-btn-relative-s w100 mb0">
                  <div class="relative-div">
                    <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_SelfAuth">
                      {{ !authNumberDisabled ? '인증번호 재전송' : '인증번호 전송' }}
                    </mo-button>
                  </div>
                </div>
              </ur-box-container>
            
            <!-- 인증번호 전송 클릭시 표출됨 => 인증확인 완료시 : 클래스값 "success"표시, 인증 오류시: 클래스값 "error"표시 -->
            <div class="ns-certify-sed mt20 pal0" :class="{'success': isAuthCompleted, 'error': isAuthError }" >
              <div class="ns-certify-sed-code mb2sam0">
                <div class="left">
                  <mo-text-field  type="number" mask="######" v-model="authNumber" class="form-input-name" ref="refAuthNumber" underline placeholder="인증번호를 입력해주세요."/>
                  <span v-if="isCountStart" class="time pl10">{{ minutes | twoDigits }}:{{ seconds | twoDigits }}</span>
                </div>
              </div>
              <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
                <div class="ns-btn-relative-s w100 mb0">
                  <div v-if="!authNumberDisabled" class="relative-div">
                    <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_PrcsMoblAthntCnf">인증확인</mo-button>
                  </div>
                </div>
              </ur-box-container>
              <div class="ns-certify-sed-txt">
                <div v-if="isAuthCompleted" class="txt-success"><span class="ico"></span>인증 완료되었습니다.</div>
                <div v-else-if="isAuthError" class="txt-error crTy-orange2">인증번호가 다릅니다.</div>
              </div>
            </div>

          </ur-box-container>

          <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="verify-btn">
          <span class="txt" style="color: #6b7684">
            인증번호 미도착시 아래와 같이 조치 부탁드립니다.<br>
            휴대폰 스팸번호 분류확인<br>
            NICE인증 고객센터 문의 <span style="color: #416dff;">&#9742;1600-1522</span>
          </span>
        </ur-box-container>

          <ur-box-container direction="column" alignV="start" componentid="" class="ns-certify-sed pal0" v-if="tabIdx === '2'">          
            <div class="ns-certify-sed pal0 mt10" :class="isAgreeValidation ? 'error' : ''"> <!-- 에러시 class에 error 추가 -->
              <div class="ns-check arrow fexTy5" @click="fn_kakaoAgreeOpen(true)">
                <mo-checkbox v-model="isKakaoAgreeYn" class="crTy-bk7" @input="fn_kakaoAgreeOpen(isKakaoAgreeYn)">본인확인 서비스 이용 동의</mo-checkbox>
              </div>
              <div v-if="isAgreeValidation" class="ns-certify-sed-txt">
                <div class="txt-error">본인확인 서비스 이용 동의 해 주세요.</div>
              </div>
            </div>
            <div class="ns-certify-sed pal0 mt10"> <!-- 에러시 class에 error 추가 -->
              <div class="ns-certify-sed-code row-text">
                <mo-text-field  class="form-input-name full" v-model="custNm" :rules="isCustNmValidation" underline placeholder="고객명을 입력하세요." />
              </div>
              <div class="ns-certify-sed-txt">
                <div class="txt-error">이름을 입력해주세요.</div>
              </div>
            </div>

            <div class="ns-certify-sed pal0 mt20"> <!-- 에러시 class에 error 추가 -->
              <div class="ns-certify-sed-code row-text">
                <mo-text-field  type="number" mask="######" underline class="birth-input1 form-input-name" v-model="knb1" :rules="isKnb1Validation"  placeholder="901111"/>
                <span class="hyphen ml10 mr10"></span>
                <mo-text-field type="number" v-model="knb2" mask="#" :rules="isKnb2Validation" underline class="birth-input2 form-input-name w20px" />
                <em class="password">●●●●●</em>
              </div>
              <div class="ns-certify-sed-txt">
                <div class="txt-error">주민등록번호 앞자리를 입력해 주세요.</div>
              </div>
              <div class="ns-certify-sed-txt">
                <div class="txt-error">주민등록번호 뒤한자리를 입력해 주세요.</div>
              </div>
            </div>

            <div class="ns-certify-sed pal0 mt20"> <!-- 에러시 class에 error 추가 -->
              <div class="ns-certify-sed-code row-text">
                <mo-decimal-field mask="phone" class="w100" v-model="phoneNum" placeholder="000 - 0000 - 0000" @input="fn_inputPhoneNum" />
              </div>
              <div class="ns-certify-sed-txt">
                <div class="txt-error">휴대폰 번호를 입력해 주세요.</div>
              </div>
            </div>
            <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
              <div class="ns-btn-relative-s w100 mb0">
                <div class="relative-div">
                  <mo-button v-if="authNumberDisabled" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ReqAuthNumberKakao">카카오페이 인증요청</mo-button>
                  <mo-button v-else color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="isKakaoDisabled" @click="fn_AuthConfirmKakao">인증확인</mo-button>
                </div>
              </div>
            </ur-box-container>
            
            <!-- 인증번호 전송 클릭시 표출됨 => 인증확인 완료시 : 클래스값 "success"표시, 인증 오류시: 클래스값 "error"표시 -->
            <div v-if="authResult !== ''" class="ns-certify-sed pal0"  :class="authResult">
              <div class="ns-certify-sed-txt">
                <div class="txt-info">인증정보가 전송되었습니다.<br>인증완료 후 인증확인 버튼을 눌러주세요.</div>
                <div class="txt-success"><span class="ico"></span>카카오페이 인증이 완료되었습니다.</div>
                <div class="txt-error crTy-orange2" v-html="authResultMessage"></div>
              </div>
            </div>
          </ur-box-container>

        </ur-box-container>
      </mo-validate-watcher>
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_close">이전</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" :disabled="isNextbtn" @click="fn_next">다음</mo-button>
        </div>
      </ur-box-container>
    </template>

    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
      <template v-slot:title>개인정보 이용 활용동의</template>
      <div class="ns-btn-close" @click="close"></div>
      <!-- content 영역 -->
      <div class="customer-info-txt pt10">
        <p class="ment fs16">(주)카카오페이는 본인인증서비스 제공을 위해 본인으로부터 아래의 개인정보를 입력받으며, 입력 받은 개인정보의 수집 및 이용을 위해 다음의 사항에 대하여 본인으로부터 동의를 받습니다.</p>

        <p class="ment fs16 mt20">개인(신용)정보 제 3자 제공동의</p>
        <p class="ment fs16 mt6">-제공목적: 본인인증</p>
        <p class="ment fs16 mt6">-제공받는자: (주)카카오페이</p>
        <p class="ment fs16 mt6">-제공받는자의 개인정보 보유 이용기간 : 5년</p>
        <p class="ment fs16 mt6">-제공항목: 이름, 생년월일, 휴대폰번호</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="close" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {},
    props: {
      pCustInfo: {
        type: Object,
        default: {}
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_close)
      /* if (this.$route.params) {
        this.custInfo = this.$route.params.custInfo
        this.custNm = this.$route.params.custInfo.custNm
        console.log('this.custInfo>>', this.custInfo)
      } else {
        this.$router.push({ name: 'MSPBC002M' })
      } */
      this.custInfo = this.pCustInfo
      this.custNm = this.pCustInfo.custNm
    },
    mounted() {
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_close)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        slctTelecomType: '', // 선택한 휴대폰 통신사
        telecomTypes: [
          { value: 'SKT', text: 'SKT'},
          { value: 'KTF', text: 'KT' },
          { value: 'LGT', text: 'LG U+' },
          { value: 'SKTM',text: 'SKT(알뜰폰)' },
          { value: 'KTM', text: 'KT(알뜰폰)'  },
          { value: 'LGTM',text: 'LG U+(알뜰폰)'},
        ],
        tabIdx: '1',
        value: '',
        value0: false,
        value1: '',
        isPensYn: false,
        isPhoneAgreeYn: false, // 휴대폰 본인확인 동의 여부
        isKakaoAgreeYn: false, // 카카오페이 본인확인 동의 여부
        custInfo: {}, // 고객정보
        custNm: '', // 고객정보
        knb1: '',
        knb2: '',
        phoneNum: '',
        sCertVnoUrl: 'https://cert.vno.co.kr/app/agree/agree_main.jsp', // 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL
        date: 0, // 인증 유효 시간
        now: Math.trunc((new Date()).getTime() / 1000), // 현재시각
        isAuthCompleted: false, // 인증성공
        isAuthError: false, // 인증실패
        interval: {}, // 인증번호 타이머
        isCountStart: false, // 인증 타이머 시작
        moblAthntCd: '', // 모바일인증코드
        moblAthntPswd: '', // 모바일인증비밀번호
        respUnqNo: '', // 응답고유번호
        reqUnqNo: '', // 요청고유번호
        niceAuthObj: {}, // 본인인증 정보
        kakaoAuthObj: {}, // 카카오 본인인증 정보
        authNumberDisabled: true, // 인증번호 입력란 disabled (default: 비활성화)
        aprvNoErrCnt: 0, // 인증번호 실패 횟수
        authNumber: '', // 인증번호 - 입력값
        isNextbtn:true, // 다음버튼 활성화 여부(false 활성화)
        authResult: '', 
        authResultMessage: '', //카카오 인증 결과 메세지
        isKakaoDisabled: false, // 카카오 인증버튼 활성화 여부

        isPensValidation: false,
        isAgreeValidation: false,
        isCustNmValidation: [
          v => v.length !== 0 || '이름을 입력해주세요.'
        ],
        isKnb1Validation: [
          v => (v.length !== 0 && v.length === 6) || '주민등록번호 앞자리를 입력해주세요.'
        ],
        isKnb2Validation: [
          v => v.length === 1 || '주민등록번호 뒤 한자리를 입력해주세요.'
        ],
        isTelecomValidation: [
          v => v.length !== 0 || '통신사를 선택해주세요.'
        ],
        isCelNumValidation: [
          v => (v.length !== 0 && v.length === 11) || '휴대폰 번호를 입력해주세요'
        ]

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      seconds () {
        let lv_Seconds = (this.remainDateValue - this.now) % 60
        if (lv_Seconds > 0) {
          return (this.remainDateValue - this.now) % 60
        } else {
          if (this.minutes === 0) {
            console.log('타이머 중지!!!!')
            window.clearInterval(this.interval) // 타이머 중지
          }
          return 0
        }
      },
      minutes () {
        let lv_Minutes = Math.trunc((this.remainDateValue - this.now) / 60) % 60
        if (lv_Minutes > 0) {
          return Math.trunc((this.remainDateValue - this.now) / 60) % 60
        } else {
          return 0
        }
      },
      remainDateValue () {
        return Math.trunc(this.date / 1000)
      }
    },
    filters: {
      twoDigits: function (value) {
        if (value.toString().length <= 1) {
          return '0' + value.toString()
        }
        return value.toString()
      }
    },
    watch: {
      isAuthCompleted() {
        if (this.isAuthCompleted === true) {
          this.isNextbtn = false
        } else {
          this.isNextbtn = true
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      //open() { this.$refs.nsbottomsheet.open();  },
      close() { this.$refs.nsbottomsheet.close(); },

      /******************************************************************************
      * Function명 : fn_kakaoAgreeOpen
      * 설명       : 카카오 본인인증 이용동의 약관 오픈
      ******************************************************************************/
      fn_kakaoAgreeOpen(isAgree) {
        console.log('isAgree', isAgree)
        if (isAgree) {
          this.$refs.nsbottomsheet.open()
        }
      },

      /******************************************************************************
      * Function명 : fn_AgreePopContent
      * 설명       : 휴대폰 본인인증 이용동의 약관 오픈
      ******************************************************************************/
      fn_AgreePopContent (isAgree) {
      let url = this.sCertVnoUrl
      if (isAgree) {
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentBrowserPlugin', {uri: url}, () => {}, () => {})
        } else { // PC에서 작동
          window.open(url, '_blank')
        }
      }
    },

      /******************************************************************************
      * Function명 : fn_Validation
      * 설명       : 인증번호 전송 클릭시 밸리데이션
      ******************************************************************************/
      fn_Validation() {
        console.log('this.isPensYn>>', this.isPensYn)
        console.log('휴대폰 본인확인여부>>', this.isPhoneAgreeYn)
        console.log('카카오 본인확인여부>>', this.isKakaoAgreeYn)
        if (!this.isPensYn) {
          this.isPensValidation = true
          return false
        } else {
          this.isPensValidation = false
        }
        if ((this.tabIdx === '1' && !this.isPhoneAgreeYn) || (this.tabIdx === '2' && !this.isKakaoAgreeYn)) {
          this.isAgreeValidation = true
          return false
        } else {
          this.isAgreeValidation = false
        }

        console.log('밸리', this.$refs.agreeValidateWatcher.validate())
        return this.$refs.agreeValidateWatcher.validate() === true
      },

      /******************************************************************************
      * Function명 : fn_SelfAuth
      * 설명       : 인증번호 전송 클릭
      ******************************************************************************/
      fn_SelfAuth () {
        // 입력값 유효성 체크
        if (!this.fn_Validation()) return
        let lv_Vm = this
        console.log('slctTelecomType>>', this.slctTelecomType)
        let pParam = {
                      custId: '', // 고객ID
                      custNm: this.custNm, // 고객명
                      knbFrno: this.knb1, // 주민번호 앞자리
                      knbBknoCipher: '', // 주민번호 뒷자리 암호화
                      selfAthntUsScCd: '01', // 본인인증용 구분코드 01 : 개인정보활용동의, 02:전자서명 본인인증(전화번호 수정불가), 03: 본인인증 AS_IS(1:01, 2:02)                      
                      cmmuScCd: this.slctTelecomType === 'KTF' ? 'KT' : this.slctTelecomType, // 통신사
                      // cmmuScCd: this.slctTelecomType, // 통신사
                      celno: this.phoneNum, // 핸드폰 번호
                      knb: 'CT', // 업무구분코드 (FN: 융자)
                      rrn: `${this.knb1}${this.knb2}000000`
                    }
        console.log('pParam>>', pParam)
        //this.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)
        
        const trnstId = 'txTSSBU22S3'
        //const trnstId = 'txTSSBU02S1'
        const auth = 'S'
        this.post(lv_Vm, pParam, trnstId, auth)
          .then(response => {
            if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {
              let tmpRslt = response.body
              // 정상요청 된 경우
              if (tmpRslt.rsltCd === '0000') {
                lv_Vm.authNumberDisabled = false // 인증번호 Text-Input 활성화
                
                lv_Vm.moblAthntCd = tmpRslt.moblAthntCd // 모바일인증코드
                lv_Vm.moblAthntPswd = tmpRslt.moblAthntPswd // 모바일인증비밀번호
                lv_Vm.respUnqNo = tmpRslt.respUnqNo // 응답고유번호
                lv_Vm.reqUnqNo = tmpRslt.reqUnqNo // 요청고유번호
                // 인증번호 입력란 포커스
                lv_Vm.$refs['refAuthNumber'].focus()
                // 타이머 세팅 및 시작
                lv_Vm.isCountStart = true
                lv_Vm.date = moment(new Date()).add(7, 'm').toDate() // 인증 유효 시간을 7분으로 timer 세팅
                lv_Vm.interval = window.setInterval( () => {
                  lv_Vm.now = new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime() / 1000 | 0
                }, 1000)
                
                lv_Vm.aprvNoErrCnt = 0 // 실패 횟수는 다시 0으로 초기화
                
              }
            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
           }).catch(error => {
            window.vue.error(error)
          })
      },

      /******************************************************************************
     * Function명 : fn_PrcsMoblAthntCnf
     * 설명       : 휴대폰 인증번호 확인
     ******************************************************************************/
    fn_PrcsMoblAthntCnf () {
      const lv_Vm = this

      if (this.$bizUtil.isEmpty(this.authNumber) || this.authNumber.length < 6) {
        this.getStore('confirm').dispatch('ADD', '인증번호 정보가 정확하지 않습니다.') // 인증번호 정보가 정확하지 않습니다.
        return
      }
      this.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)
      let pParams = {
        custId: '', // 고객ID
        chnlCustId: this.custInfo.chnlCustId, // 채널고객ID chnlCustId
        custNm: this.custNm, // 고객명
        knbFrno: this.knb1, // 주민번호 앞자리
        knbBknoCipher: '', // 주민번호 뒷자리 암호화
        selfAthntUsScCd: '01', // 본인인증용 구분코드 01 : 개인정보활용동의, 02:전자서명 본인인증(전화번호 수정불가), 03: 본인인증 AS_IS(1:01, 2:02)
        celno: this.phoneNum, // 핸드폰 번호
        knb: 'CT', // 업무구분코드
        rrn: `${this.knb1}${this.knb2}000000`,
        moblAthntCd: this.moblAthntCd, // 모바일인증코드
        moblAthntPswd: this.moblAthntPswd, // 모바일인증비밀번호
        reqUnqNo: this.reqUnqNo, // 요청고유번호
        respUnqNo: this.respUnqNo, // 응답고유번호
        athntNo: this.authNumber, // 인증번호
        aprvNoErrCnt: this.aprvNoErrCnt // 인증번호 오류 횟수
      }

      const trnstId = 'txTSSCT22S2'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth).then((res) => {
        console.log('문자 인증 확인 결과>>>', res)
        if (!_.isEmpty(res.body) && res.body.rsltCd === '0000') { // 인증 확인 처리
          window.clearInterval(lv_Vm.interval) // 타이머 종료
          lv_Vm.isCountStart = false
          lv_Vm.isAuthCompleted = true
          lv_Vm.isAuthError = false

        } else { // 정상처리가 아닌경우
          lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          lv_Vm.isAuthCompleted = false
          lv_Vm.isAuthError = true
          if (res.msgComm.msgCd === 'ECMU001') { // 인증번호 3회 오류 시
            window.clearInterval(lv_Vm.interval) // 타이머 종료
            lv_Vm.isCountStart = false
            
          }
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      });

    },


    /******************************************************************************
    * Function명 : fn_ReqAuthNumberKakao
    * 설명       : 카카오페이 인증요청
    ******************************************************************************/
    fn_ReqAuthNumberKakao() {
      // 동의 체크 확인
      if (!this.fn_Validation()) return
      let params ={
        knb: `${this.knb1}${this.knb2}000000`, // 주민등록번호
        celno: this.phoneNum, // 전화번호
        infoPrcusCnsntTyoCd: '17', 
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId, 
        custNm: this.custNm, // 고객명
        chnlCustId: '' // 채널고객ID
      }

      let lv_Vm = this
      this.post(this, params, 'txTSSCM12S2', 'S').then(function (response) {
          //local 환경에서는 그냥 패스
          if( process.env.NODE_ENV === 'local' ){
            lv_Vm.authNumberDisabled = false // 인증확인 버튼 활성화
            lv_Vm.authResult = 'info'
          } else if (response.body !== null && response.body !== '' && response.body.wkRslt === 'Y'
            && response.body.athntReqRespCd === 'P000') {

            lv_Vm.kakaoAuthObj.athntTrnstId = response.body.athntTrnstId // 트랜잭션 id 받아놓기.
            lv_Vm.kakaoAuthObj.athntHstrId = response.body.athntHstrId
            lv_Vm.authNumberDisabled = false // 인증결과확인 버튼 활성화
            lv_Vm.authResultMessage = '' // 결과 메시지 초기화
            lv_Vm.authResult = 'info'

          } else {
            // 정상처리가 아닌경우
            if (response.body.athntReqRespCd === 'E400') {
              response.msgComm.msgDesc = '(카카오톡 미사용/해지 고객)'
            } else if ( response.body.athntReqRespCd === 'E401' ) {
              response.msgComm.msgDesc = '(휴대폰 번호 누락/불일치)'
            } else if ( response.body.athntReqRespCd === 'E402' ) {
              response.msgComm.msgDesc = '(고객명 누락/불일치)'
            } else if ( response.body.athntReqRespCd === 'E403' ) {
              response.msgComm.msgDesc = '(생년월일 누락/불일치)'
            } else if ( response.body.athntReqRespCd === 'E421' ) {
              response.msgComm.msgDesc = '(생년월일 불일치)'
            } else if ( response.body.athntReqRespCd === 'E422' ) {
              response.msgComm.msgDesc = '(고객명 불일치)'
            } else {
              response.msgComm.msgDesc = '자세한 사항에 대한 문의는 카카오Pay 고객센터(1644-7405)로 문의 바랍니다.'
            }

            lv_Vm.authResultMessage = '카카오Pay 인증이 발송되지 않았습니다.<br>' + response.msgComm.msgDesc
            lv_Vm.authResult = 'error'
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
      * Function명 : fn_AuthConfirmKakao
      * 설명       : 카카오페이 인증확인
      ******************************************************************************/
      fn_AuthConfirmKakao() {
      let params = {
                     athntTrnstId: this.kakaoAuthObj.athntTrnstId, // 트랜잭션id
                     athntHstrId: this.kakaoAuthObj.athntHstrId
                   }

      let lv_Vm = this
      this.post(lv_Vm, params, 'txTSSCM12S3', 'S').then(function (response) {
          //local 환경에서는 그냥 패스
          if( process.env.NODE_ENV === 'local' ){
            response.body = {}
            response.body.athntStatRespCd = 'P000'
            response.body.athntTrtStatCd = 'COMPLETE'
          }
          if (response.body !== null && response.body !== '' && response.body.athntStatRespCd === 'P000') {
            if ( response.body.athntTrtStatCd === 'COMPLETE' ) { // 서명완료
              ;
            } else if ( response.body.athntTrtStatCd === 'PREPARE' ) {
              lv_Vm.authResultMessage = '카카오Pay 인증이 완료되지 않았습니다.<br>인증 완료후 ‘인증결과 확인’을 다시 한 번 클릭하시기 바랍니다.'
            } else if ( response.body.athntTrtStatCd === 'EXPIRED' ) { // 타임아웃
              lv_Vm.authResultMessage = '인증시간이 초과되었습니다.<br>상단의 ‘카카오페이 인증요청’버튼을 눌러 재인증 하시기 바랍니다.'
            }

          } else { // 정상처리가 아닌경우
            if (response.body.athntReqRespCd === 'E400') {
              response.msgComm.msgDesc = '중복 호출. 잘못된 요청입니다.'
            } else if ( response.body.athntReqRespCd === 'E401' ) {
              response.msgComm.msgDesc = '조회 식별자가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E402' ) {
              response.msgComm.msgDesc = '서명 결과가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E403' ) {
              response.msgComm.msgDesc = '서명이 완료되지 않았습니다.'
            } else if ( response.body.athntReqRespCd === 'E404' ) {
              response.msgComm.msgDesc = 'Transaction ID가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E405' ) {
              response.msgComm.msgDesc = '이용기관 정보를 찾을 수 없습니다.'
            } else {
              response.msgComm.msgDesc = ''
            }
            lv_Vm.authResultMessage = '카카오Pay 인증이 실패되었습니다.<br>' + response.msgComm.msgDesc
          }

          // 인증 성공
          if( lv_Vm.$bizUtil.isEmpty(lv_Vm.authResultMessage)) {
            // 인증 성공 시간 조회(서버기준)
            lv_Vm.$commonUtil.getServerTime().then(function (response) {
              lv_Vm.authTime = moment(response.toString()).format('YYYY-MM-DD HH:mm')
              lv_Vm.authResult = 'success' // 인증 완료 메시지 출력
              lv_Vm.isKakaoDisabled = true // 인증결과 확인 버튼 비활성화
              lv_Vm.isNextbtn = false // 다음 버튼 활성화
            })
          }
          // 인증 실패
          else {
            lv_Vm.authResult = 'error' // 인증 실패 메시지 출력
          }
          
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
      * Function명 : fn_tabClick
      * 설명       : 인증종류 탭 클릭
      ******************************************************************************/
      fn_tabClick() {
        console.log('탭 클릭!')
        this.isKakaoAgreeYn = false
        this.isPhoneAgreeYn = false
        this.authNumberDisabled = true
        this.isCountStart = false
        this.isNextbtn = true
        this.isKakaoAuthYn = false
        this.isKakaoDisabled = false
      },

      /******************************************************************************
      * Function명 : fn_close
      * 설명       : 이전버튼 클릭
      ******************************************************************************/
      fn_close() {
        this.$emit('onPopupClose')
      },

      /******************************************************************************
      * Function명 : fn_next
      * 설명       : 다음버튼 클릭
      ******************************************************************************/
     fn_next() {
       //this.$router.push({ name: 'MSPCT300M', params: {custNm: this.custInfo.custNm, chnlCustId: this.custInfo.chnlCustId, custInfo: this.custInfo }})
       let params = {custNm: this.custInfo.custNm, chnlCustId: this.custInfo.chnlCustId, custInfo: this.custInfo }
       this.$emit('onPopupConfirm', this.custInfo)
     }
    }
  };
</script>
<style scoped>
::v-deep .ty--02 {
  min-width: 20px !important;
}
::v-deep .ty--02 .mo-text-field__input {
  padding: 0 !important;
}
::v-deep .password {
  font-style: normal;
  width: 50%;
  color: #bbb;
  margin-left: 8px;
  letter-spacing: 0;
  padding-top: 8px;
}
::v-deep .ty--02 .mo-validator__message {
  position: absolute;
  left: 0;
  top: 40px;
  min-width: 160px;
}
</style>