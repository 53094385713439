/*
* 업무구분:
* 화면 명: MSPSA060P
* 화면 설명:비대면 화상상담 고객검색
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp msp-bc-wrap--bgGray" type="subpage" :show-title="false" title="비대면 화상상담 고객검색" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap">

      <div class="search-user-area">

        <div class="mt25 pl20 pr20">
          <div class="search-round-box fexTy5 mo-top-bar__primary">
            <div class="mo-top-bar-nav">
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
            </div>
            <mo-text-field ref="refSearchCustNm" v-model="searchCustNmData" @input="fn_onCustSearch" class="form-input-name full" underline clearable placeholder="고객명을 입력하세요" />
          </div>
        </div>
        <div class="mt20 pl15 pr15">
          <div v-if="searchCustNmData.length <= 1 && custSrchRecList.length > 0" class="search-round-con">
            <div class="bd-b-Ty1 pb10 mb10">
              <span class="fs16rem fwb">최근 검색</span>
            </div>
            <div class="search-con-box">
              <template v-for="(data, idx) in custSrchRecList" >
                <ur-box-container v-if="idx < 3" :key="idx" alignV="start" componentid="" direction="row" class="fexTy3 search-row">
                  <span @click="fn_selCust(data)" class="fs17rem txtSkip flex-1">
                    <span>{{data.custNm}}</span>
                  </span>
                  <span class="fs14rem crTy-bk9">
                    <span v-if="data.mskDob" class="ml10">{{ data.mskDob }}</span>
                    <span v-if="data.telno" class="phone ml18"><mo-icon icon="msp-phone" class="mr4" />{{data.telno.substring(3, 19)}}</span>
                  </span>
                </ur-box-container>
              </template>
            </div>
          </div>
          <div v-else-if="searchCustNmData.length > 1 && !isSearching" class="search-round-con">
            <div class="search-con-box">
              <!--검색 데이터 있음-->
              <template v-if="srchRsltCustSrchList.length > 0">
                <ur-box-container v-for="(data, idx) in srchRsltCustSrchList" :key="idx" alignV="start" componentid="" direction="row" class="fexTy3 search-row">
                  <span @click="fn_selCust(data)" class="fs17rem txtSkip flex-1">
                    <span v-html="fn_getHighlightTitle(data.custNm)">{{data.custNm}}</span>
                  </span>
                  <span class="fs14rem crTy-bk9">
                    <span v-if="data.mskDob" class="ml10">{{ data.mskDob }}</span>
                    <span v-if="data.telno" class="phone ml18"><mo-icon icon="msp-phone" class="mr4" />{{data.telno.substring(3, 19)}}</span>
                  </span>
                </ur-box-container>
              </template>
              <!-- no-data 조건설정 및 데이타 없음 --> 
              <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span class="ns-ftcr-gray"><p class="mt10"><b class="crTy-bk1">'{{ searchCustNmData }}'</b>에 대한 검색결과가 없습니다.</p></span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
              <!-- no-data end -->
            </div>
          </div><!--search round con  -->
        </div>


      </div>

    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
    import moment from 'moment'
    import DateUtil from '@/ui/ps/comm/PSDateUtil'
    import MSPSA063P from '@/ui/sa/MSPSA063P'
    export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA060P",
    screenId: "MSPSA060P",
    components: {
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_selListRntSrchCust() // 최근 검색고객목록조회
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA060P')
      this.$refs.refSearchCustNm.focus()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      isSearching: false, // 검색중
      custSrchRecList: [], // 최근 검색 고객 목록
      searchCustNmData: '', // 검색 고객명
      srchRsltCustSrchList: [], // 검색한 고객 목록
      nofacCnslCust: {}, // 화상상담 고객정보
      selCust: {}, 
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_ClickBtnPrevious() {
        this.$emit('onPopupClose')
      },
      fn_getHighlightTitle (val) {
      if (!this.searchCustNmData) {
        return val
      }
      const regex = new RegExp(`(${this.searchCustNmData})`, 'gi')
      return val.replace(regex, '<span class="crTy-blue3">$1</span>')
    },
      /******************************************************************************
      * Function명 : fn_selListRntSrchCust
      * 설명       : 최근 검색고객목록조회
      ******************************************************************************/
      fn_selListRntSrchCust () {
        let lv_Vm = this
        lv_Vm.custSrchRecList = []
        let pParams = {}
        const trnstId = 'txTSSBC02S1'
        const auth = 'S'

        this.post(lv_Vm, pParams, trnstId, auth, {}, true)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              lv_Vm.custSrchRecList = response.body.custSrchRecList
              lv_Vm.custSrchRecList.forEach(e => {
                if (e.dob !== null) {
                  let tmp = e.dob.split('-')
                  tmp[1] = '**'
                  tmp[2] = '**'
                  e.mskDob = `${tmp[0]}-${tmp[1]}-${tmp[2]}`
                }
              })
              console.log('최근 검색고객 목록>>>>', lv_Vm.custSrchRecList)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_onCustSearch
      * 설명       : 고객검색
      ******************************************************************************/
      fn_onCustSearch: _.debounce(function () {
        console.log('fn_onCustSearch >>> 고객검색' , this.searchCustNmData.length)
        let lv_Vm = this
        const trnstId = 'txTSSBC02S2'
        const auth = 'S'

        // 길이가 두 글자 이상인 경우만 검색함
        if (this.searchCustNmData.length < 1) {
          return
        }
        lv_Vm.isSearching = true
        lv_Vm.srchRsltCustSrchList = []
        // 옵션 Input 셋팅
        let pParams = {srchCustWord : this.searchCustNmData} // 고객명

        this.post(lv_Vm, pParams, trnstId, auth, {}, true)
          .then(function (response) {
            console.log('검색결과>>>>', response.body)
            if (response.body !== null && response.body !== '') {
              lv_Vm.srchRsltCustSrchList = response.body.custSrchList
              lv_Vm.isSearching = false
              lv_Vm.srchRsltCustSrchList.forEach(e => {
                if (e.dob !== null) {
                  let tmp = e.dob.split('-')
                  tmp[1] = '**'
                  tmp[2] = '**'
                  e.mskDob = `${tmp[0]}-${tmp[1]}-${tmp[2]}`
                }
              })
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }, 500),
      /******************************************************************************
      * Function명 : fn_selCust
      * 설명       : 고객을 선택함
      ******************************************************************************/
      fn_selCust (srchCust) {
        let lv_Vm = this
        // let userEno = this.getStore('userInfo').getters.getUserInfo.userId
        const trnstId = 'txTSSBC02I1'
        const auth = 'I'

        // 사용자 정보
        lv_Vm.selCust.chnlCustId = srchCust.chnlCustId // 채널고객ID
        lv_Vm.selCust.custNm = srchCust.custNm // 검색고객명
        lv_Vm.selCust.dob = srchCust.dob // 검색고객생년월일
        lv_Vm.selCust.telno = srchCust.telno // 검색고객전화번호

        // 옵션 Input 셋팅
        let pParams = lv_Vm.selCust
        console.log('고객선택 pParams>>>', pParams)

        this.post(lv_Vm, pParams, trnstId, auth, {}, true)
          .then(function (response) {
            if (response.msgComm.msgCd === 'ECOU002') { // ECOU002:저장되었습니다
              lv_Vm.searchCustNmData = '' // 검색값 초기화
                lv_Vm.nofacCnslCust = {}
                lv_Vm.nofacCnslCust.custNm = srchCust.custNm // 검색고객명
                lv_Vm.nofacCnslCust.chnlCustId = srchCust.chnlCustId // 채널고객ID
                lv_Vm.nofacCnslCust.dob = srchCust.dob // 고객 생년월일

                console.log('라우터!!', lv_Vm.$router.history.current.name)
                let parentPage = lv_Vm.$router.history.current.name
                if (parentPage === 'MSPCT300M') {
                  lv_Vm.$emit('onPopupConfirm', srchCust)
                } else {
                  lv_Vm.fullPopup = lv_Vm.$bottomModal.open(MSPSA063P, {
                    properties: {
                      pNofacCnslCust: lv_Vm.nofacCnslCust,
                    },
                    listeners: {
                      onPopupConfirm: () => {
                        lv_Vm.$bottomModal.close(lv_Vm.fullPopup);
                      },
                      onPopupClose: () => {
                        lv_Vm.$bottomModal.close(lv_Vm.fullPopup);
                      }
                    }
                  },
                  {
                    properties: {
                      noHeader: true
                    }
                  })
                }
                // lv_Vm.$MenuManager.fn_RouterPushSrnId('TSSCM207M_BC', {chnlCustId: lv_Vm.selCust.chnlCustId, cnsltNo: userEno})
            }
          }) 
          .catch(function (error) {
            window.vue.error(error)
          })
      },
    }
  }
</script>
<style scoped>
</style>