<template>
  <ur-page-container ref="page" class="msp" title="다른연금추가" :show-title="false" type="subpage" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="headerviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="popupClose()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main txtSkip-inline">{{scrollRate > 0.5 ? '다른연금추가' : '다른연금추가'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>            
            <span class="text_hidden">collapsing</span>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <span class="text_hidden">sticky</span>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30 pb20">
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
                <div class="info-title-wrap">
                   <span class="fwm fs18rem must">희망 월 생활비</span>
                </div>               
                <ur-box-container alignV="start" componentid="" direction="column" class="relative mt10">
                  <mo-decimal-field numeric v-model="hopeAmount"  class="form-input-name full" underline placeholder="필수 입력" />
                  <span class="input-txt">만원</span>
                </ur-box-container>
              </ur-box-container>
            </ur-box-container> 

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pt30 pb20">
              <span class="fwm fs18rem must">연금정보</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment msp-segment-ty1 mt10">
                <mo-segment-wrapper solid primary v-model="firstItem">
                  <mo-segment-button value="1" @click="fn_tabChk('1')">직접입력</mo-segment-button>
                  <mo-segment-button value="2" @click="fn_tabChk('2')">연금액계산</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>
            </ur-box-container>  

            <!--직접입력--> 
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area pb0" v-if="tab_index === '1'">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box">
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
                  <div class="info-title-wrap mb0">
                    <span class="crTy-bk7">국민연금</span>
                  </div>
                  <div class="fexTy3 full">
                    <div class="txt-right w45d5">{{ endAge3 }}세</div>
                    <div class="relative w45d5">
                      <mo-decimal-field numeric v-model="earnings2" class="form-input-name" underline placeholder="입력" />
                      <span class="input-txt">만원</span>
                    </div>
                  </div>                  
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
                  <div class="info-title-wrap mb0">
                    <span class="crTy-bk7">퇴직연금</span>
                  </div>
                  <div class="fexTy3 full">
                    <div class="relative w45d5">
                      <mo-decimal-field numeric v-model="endAge4" maxlength="2" class="form-input-name"  underline placeholder="숫자만 입력" />
                      <span class="input-txt">세</span>
                    </div>
                    <div class="relative w45d5">
                      <mo-decimal-field numeric v-model="earnings3" class="form-input-name" underline placeholder="입력" />
                      <span class="input-txt">만원</span>
                    </div>
                  </div>                  
                  <div class="fexTy2 mt6">
                    <mo-icon icon="msp-info" /><span class="ml4 crTy-bk7">예상 퇴직연령은 55세부터 70세까지만 입력하여 주세요.</span> 
                  </div>                  
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
                  <div class="info-title-wrap mb0">
                    <span class="crTy-bk7">타사연금</span>
                  </div>
                  <div class="fexTy3 full">
                    <div class="relative w45d5">
                      <mo-decimal-field numeric v-model="endAge1" maxlength="2" class="form-input-name" underline placeholder="숫자만 입력" />
                      <span class="input-txt">세</span>
                    </div>
                    <span class="fs17rem w9px ml10 mr10">~</span>
                    <div class="relative w45d5">
                      <mo-decimal-field numeric v-model="endAge2" maxlength="2" class="form-input-name" underline placeholder="숫자만 입력" />
                      <span class="input-txt">세</span>
                    </div>
                  </div>
                  <div class="fexTy2 mt6">
                    <!-- <mo-icon icon="msp-info" /><span class="ml4 crTy-bk7">예상 퇴직연령은 55세부터 70세까지만 입력하여 주세요.</span> -->
                  </div>
                  <div class="relative full">
                    <mo-decimal-field numeric v-model="earnings1" class="form-input-name" underline placeholder="입력" />
                    <span class="input-txt">만원</span>
                  </div>                  
                </ur-box-container> 
              </ur-box-container>  
            </ur-box-container>
            <!--연금액 계산-->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area pb0" v-if="tab_index === '2'">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box">
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
                  <div class="info-title-wrap mb0">
                    <span class="crTy-bk7">직업</span>
                  </div>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                    <mo-segment-wrapper solid primary v-model="jobKind" class="chip-type-wrap">
                      <mo-segment-button value="1">직장인</mo-segment-button>
                      <mo-segment-button value="6">기타</mo-segment-button>
                    </mo-segment-wrapper>
                  </ur-box-container>                  
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
                  <div class="info-title-wrap mb0">
                    <span class="crTy-bk7">연소득</span>
                  </div>
                  <div class="relative">
                    <mo-decimal-field numeric v-model="earnings" class="form-input-name" underline placeholder="입력" />
                    <span class="input-txt">만원</span>
                  </div>                  
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
                  <div class="info-title-wrap mb0">
                    <span class="crTy-bk7">입사나이(공적연금 가입연령)</span>
                  </div>
                  <div class="relative">
                    <mo-decimal-field numeric v-model="startAge" class="form-input-name" maxlength="2" underline value=" " placeholder="숫자만 입력" />
                    <span class="input-txt">세</span>
                  </div>                
                  <div class="fexTy2 mt6">
                    <mo-icon icon="msp-info" /><span class="ml4 crTy-bk7">입사나이(공적연금 가입연령)는 18세부터 60세까지만 입력하여 주세요.</span> 
                  </div>                  
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
                  <div class="info-title-wrap mb0">
                    <span class="crTy-bk7">예상 퇴직연령</span>
                  </div>
                  <div class="relative">
                    <mo-decimal-field numeric v-model="endAge" maxlength="2" class="form-input-name" underline value="0" placeholder="숫자만 입력" />
                    <span class="input-txt">세</span>
                  </div>
                  <div class="fexTy2 mt6">
                    <mo-icon icon="msp-info" /><span class="ml4 crTy-bk7">예상 퇴직연령은 55세부터 70세까지만 입력하여 주세요.</span> 
                  </div>                  
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
                  <div class="info-title-wrap mb0">
                    <span class="crTy-bk7">은퇴 희망연령</span>
                  </div>
                  <div class="relative">
                    <mo-decimal-field numeric v-model="hopeAge" maxlength="2" class="form-input-name" underline value="0" placeholder="숫자만 입력" />
                    <span class="input-txt">세</span>
                  </div>                
                </ur-box-container>   
              </ur-box-container>
            </ur-box-container> 

                           
            <ur-box-container direction="column" alignV="start" class="con-area mt20 pb50"> 
              <ul class="terms-list-area crTy-bk7 fs14rem">
                <li>금융감독원 통합연금포털에서 고객님의 연금액을 확인하실 수 있습니다.</li>
              </ul>
            </ur-box-container>    
          </template>
        </mo-collapsing-header-view>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button v-if="tab_index === '1'" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_reset">초기화</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_pAddmInfoReg">저장</mo-button>
          </div>
        </ur-box-container>  
      </div>
    </ur-box-container> 
     
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT305P",
    screenId: "MSPCT305P",
    components: {
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_AnutBgnAge() // 국민연금 개시나이(최초 로딩 시 자동 출력 위해)
    },
    mounted () {
     // 스크롤 애니메이션 instance 선언
      this.headerviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.headerviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      /* let dirMemInfo = this.getStore('ctStore').getters.getDirMemInfo?.data
      console.log('dirMemInfo>>>', dirMemInfo)
      if ( dirMemInfo !== undefined ) {
        this.hopeAmount = dirMemInfo.hopeamount 
        this.endAge1 = dirMemInfo.tComEndAge1 
        this.endAge2 = dirMemInfo.tComEndAge2 
        this.earnings1 = dirMemInfo.tComEarning 
        this.endAge3 = dirMemInfo.nPensEndAge 
        this.earnings2 = dirMemInfo.nPensEarning 
        this.endAge4 = dirMemInfo.rtpsEndAge 
        this.earnings3 = dirMemInfo.rtpsEarning 
      } */
      this.fn_PAddmInfo()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        firstItem:'1',
        jobKind:'',
        tab_index: '1',
        headerviewScrollCompID: '',

        hopeAmount: '', // 희망월생활비

        endAge1: '', // 타사연금 -수령연령1
        endAge2: '', // 타사연금 -수령연령2
        earnings1: '', // 타사연금 - 월연금액
        endAge3: '', // 국민연금 - 수령연령
        earnings2: '', // 국민연금 - 월연금액
        endAge4: '', // 퇴직연금 - 수령연령
        earnings3: '', // 퇴직연금 - 월연금액

        earnings: '0', // 연소득
        startAge: '', // 입사나이
        endAge: '', // 예상 퇴직연령
        hopeAge: '', // 은퇴 희망연령
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /************************************************************************************************
      * Function명 : fn_reset
      * 설명       : 스토어 리셋
      ************************************************************************************************/
      fn_reset() {
        this.getStore('ctStore').dispatch('NPENSDEL') // 국민연금
        this.getStore('ctStore').dispatch('RTPSDEL') // 퇴직연금
		    this.getStore('ctStore').dispatch('OTCOMANUTDEL') // 타사연금
        this.$emit('confirmMSPCT305P')
      },

      fn_tabChk (str) {
        this.tab_index = str
        this.fn_clear() // 초기화
      },

      /************************************************************************************************
      * Function명 : fn_clear
      * 설명       : 초기화
      ************************************************************************************************/
      fn_clear() {
        this.hopeAmount = '' 
        this.endAge1 = '' 
        this.endAge2 = '' 
        this.earnings1 = '' 
        this.earnings2 = '' 
        this.endAge4 = '' 
        this.earnings3 = ''

        this.jobKind = ''

        this.fn_PAddmInfo()
      },

      /************************************************************************************************
      * Function명 : fn_PAddmInfo
      * 설명       : 
      ************************************************************************************************/
      fn_PAddmInfo () {
        console.log('fn_PAddmInfo')
        let lv_Vm = this
        let custInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
        let memInfo = lv_Vm.getStore('ctStore').getters.getMeminfoData.data
        console.log('custInfo>>', custInfo)
        if (memInfo != null) {
          console.log('memInfo>>>>>>', memInfo)
          let toYear = moment(new Date().toISOString()).format('YYYY')
          this.hopeAmount = memInfo.rtmAftLvgct.longAmount === 0 ? '' : memInfo.rtmAftLvgct.longAmount// 은퇴후 희망 월 생활비
          this.earnings = memInfo.yincmRegAmt.amount ? memInfo.yincmRegAmt.amount : memInfo.yincmRegAmt
          //this.startAge = Number(custInfo.age) - (toYear - memInfo.ecmpYr) // 입사년도 : 입사나이 - (금년 - 나이)
          this.endAge = memInfo.exptRtmAge // 예상 퇴직연령
          this.hopeAge = memInfo.hopRtmAge // 은퇴 희망연령
        }
      },

      /************************************************************************************************
      * Function명 : popupClose
      * 설명       : 팝업닫기
      ************************************************************************************************/
      popupClose() {
        this.$emit('closeMSPCT305P')
      },

      /************************************************************************************************
      * Function명 : fn_AnutBgnAge
      * 설명       : 국민연금 개시나이
      ************************************************************************************************/
      fn_AnutBgnAge () {
        let lv_Vm = this
        let custInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
        let toYear = moment(new Date().toISOString()).format('YYYY')
        let birthYear = toYear - custInfo.age // 출생년도
        let anutBgnYmd = lv_Vm.$bizUtil.ctUtils.fn_CalAnutBgnAge(birthYear) // 국민연금 개시나이
        console.log('anutBgnYmd>>', anutBgnYmd)
        lv_Vm.endAge3 = anutBgnYmd // 직접입력 시 국민연금 개시나이 등록
      },

      /************************************************************************************************
      * Function명 : fn_pAddmInfoReg
      * 설명       : 저장버튼 클릭
      ************************************************************************************************/
      fn_pAddmInfoReg () {
        if (this.tab_index === '1') { // 직접입력
          if ( this.fn_validationCheckDirect() ) {
            // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC028']) // 반영되었습니다.
            this.$emit('confirmMSPCT305P', true)
          }
        } else if (this.tab_index === '2') { // 연금액계산
          this.fn_validationCheckAuto()
        }
      },

      /************************************************************************************************
      * Function명 : fn_validationCheckDirect
      * 설명       : 직접입력 밸리데이션 체크
      ************************************************************************************************/
      fn_validationCheckDirect() {
        let chk = 0
        console.log('this.hopeAmount>>>', this.hopeAmount === 0)
        if (this.hopeAmount === '' || this.hopeAmount === 0) {
          this.hopeAmount = 0
          this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC029'].replace('{0}', '희망 월 생활비'))
          return false
        }
        if (this.earnings2 !== '') { // 국민연금 입력값 있는 경우
          chk = 1
          this.fn_PAddmInfoRegKook()
        }
        if (this.endAge1 !== '' || this.endAge2 !== '' || this.earnings1 !== '') { // 타사연금 입력값 있을 경우
          if (this.endAge1 === '' || this.endAge2 === '' || this.earnings1 === '') {
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC029'].replace('{0}', '타사연금 정보'))
            return false
          } else if (Number(this.endAge1) > Number(this.endAge2)) {
            this.getStore('toast').dispatch('ADD', '시작나이는 종료나이보다 작아야 합니다')
            return false
          } else {
            chk = 1
            this.fn_PAddmInfoRegTa() // 타사연금 데이터 셋팅
          }
        }
        if (this.endAge4 !== '' || this.earnings3 !== '') { // 퇴직연금 입력값 있을 경우
          if (this.endAge4 === '' || (Number(this.endAge4) < 55 || Number(this.endAge4) > 70)) { // 나이 입력값 있는경우
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC031'])
            return false
          } else {
            chk = 1
            this.fn_PAddmInfoRegToei() // 퇴직연금 예상연금 데이터 셋팅
          }
        }
        if (chk === 0) {
          this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC032']) // "타사/국민/퇴직연금 중 하나 이상 입력을 하셔야 합니다."
          return false
        }
        /* let addInfo = this.getStore('ctStore').getters.getMeminfoData.data
        addInfo['mode'] = this.tab_index === '1' ? 2 : 1
        // 직접입력시 직업은 default 로 직장인(jobNm:1)으로 셋팅
        addInfo['jobNm'] = '1'
        this.getStore('ctStore').dispatch('MEMBERINFOREG', {'data': addInfo})
        console.log('연금액계산후 vuex 정보>>>', this.getStore('ctStore').getters.getMeminfoData.data) */

        /*
        직접입력시 화면에 입력한 항목은 서버에 저장되는 데이터가 아니며,
        연금분석화면내에서는 유지되어야 하므로 VUEX 정보에 저장한다.
        직접입력화면 데이터의 경우 연금계산기 화면에서 빠져나갈시 삭제해야하므로
        해당 VUEX 정보도 삭제처리한다.
        */
        let dirMemInfo = {}
        let custInfo = this.getStore('ctStore').getters.getCustBaseInfo.data
        let toYear = moment(new Date().toISOString()).format('YYYY')
        let params = {}
        dirMemInfo = {
          'hopeamount': this.hopeAmount, // 희망월생활비
          'tComEndAge1': this.endAge1, // 타사연금 -수령연령1
          'tComEndAge2': this.endAge2, // 타사연금 -수령연령2
          'tComEarning': this.earnings1, // 타사연금 - 월연금액
          'nPensEndAge': this.endAge3, // 국민연금 - 수령연령
          'nPensEarning': this.earnings2, // 국민연금 - 월연금액
          'rtpsEndAge': this.endAge4, // 퇴직연금 - 수령연령
          'rtpsEarning': this.earnings3 // 퇴직연금 - 월연금액
        }
        let rtmAftLvgct = {'longAmount': this.hopeAmount} // 은퇴후희망월생활비
        params = {
          'chnlCustId': custInfo.chnlCustId,
          'jobNm': '1', // 직업명
          'jobpsNm': '', // 직위명
          'pubsvCopFgr': 0, // 공무원직급수
          'pubsvOrdyr': 0, // 공무원연차
          // 'ecmpYr': String(toYear - Number(custInfo.age) + Number(this.startAge)), // 입사년도 : 금년 - 나이 + 입사나이
          'ecmpYr': '-', // 직접입력시 계약년월을 '-'로 표시
          'exptRtmAge': Number(this.endAge), // 예상퇴직연령
          'hopRtmAge': Number(this.hopeAge), // 희망퇴직연령
          'rtmAftLvgct': rtmAftLvgct, // this.hopeAmount, // 은퇴후희망월생활비
          'yincmRegAmt': this.earnings, // 연소득등록금액
          'mntIncmAmt': Number(this.earnings) / 12, // 월소득금액
          'mntLvgct': 0, // 저축투자현황입력 월생활비
          'cmprcRsngRat': '2', // 물가상승률(2% 계산필요)
          'trtrEno': '', // 처리자/로그인 사번 (FC사번->서버에서 처리)
          'mode': this.tab_index === '1' ? 2 : 1 // 입력모드 (1 연금액 계산하기  2 직접입력하기)
        }

        this.getStore('ctStore').dispatch('DIRMEMINFOREG', {'data': dirMemInfo})
        this.getStore('ctStore').dispatch('MEMBERINFOREG', {'data': params}) // 개인정보 변경 후 Vuex에 저장
        console.log('연금 직접입력 vuex 정보>> ', this.getStore('ctStore').getters.getDirMemInfo.data)
        return true
      },

      /************************************************************************************************
      * Function명 : fn_validationCheckAuto
      * 설명       : 연금액계산 밸리데이션 체크
      ************************************************************************************************/
      fn_validationCheckAuto() {
        if ( this.jobKind === '1' ) {
          if (this.hopeAmount === '' || this.hopeAmount === '0') {
            //this.hopeAmount = '0'
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC029'].replace('{0}', '희망 월 생활비'))
            return false
          }
          if (this.earnings === '' || this.earnings === '0') {
            //this.earnings = '0'
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC029'].replace('{0}', '연소득'))
            return false
          }
          if (this.startAge === '' || this.startAge === '0') {
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC029'].replace('{0}', '입사나이'))
            return false
          }
          if (Number(this.startAge) < 18 || Number(this.startAge) > 60 ) {
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC039']) // 입사나이(공적연금 가입연령)는 18세부터 60세까지만 입력하여 주세요.
            return false
          }
          if (Number(this.endAge) < 55 || Number(this.endAge) > 70 ) {
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC031'])
            return false
          }
          if (this.hopeAge === '' || this.hopeAge === '0') {
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC029'].replace('{0}', '은퇴 희망연령'))
            return false
          }
          if (Number(this.hopeAge) < 55 || Number(this.hopeAge) > 80 ) {
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC036']) // 은퇴 희망연령은 55세부터 80세까지만 입력하여 주세요.
            return false
          }
        } else { // 그외 직업군 : 입력값 체크를 하지 않으나, 유효값이 없는경우 기본값 셋팅 (as-is 통자분 기능)
          if (this.hopeAmount === '' || this.hopeAmount === '0') { // 희망월생활비 유효값이 없는경우 0 셋팅
            this.hopeAmount = '0'
          }
          if (this.earnings === '' || this.earnings === '0') { // 연소득 유효값이 없는경우 0 셋팅
            this.earnings = '0'
          }
          if (this.startAge === '' || this.startAge === '0') { // 입사나이 유효값이 없는경우 0 셋팅
            this.startAge = '0'
          }
          if (this.endAge === '' || this.endAge === '0') { // 예상퇴직연령 유효값이 없는경우 기본 55세 셋팅
            this.endAge = '55'
          }
          if (Number(this.endAge) < 55 || Number(this.endAge) > 70 ) {
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC031'])
            return false
          }
          if (this.hopeAge === '' || this.hopeAge === '0') { // 은퇴희망연령 유효값이 없는경우 기본 55세 셋팅
            this.hopeAge = '65'
          }
          if (Number(this.hopeAge) < 55 || Number(this.hopeAge) > 80 ) {
            this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC036'])
            return false
          }
        }
        this.fn_PAddmInfoRegDang()
      },

      /************************************************************************************************
      * Function명 : fn_PAddmInfoRegKook
      * 설명       : 국민연금 데이터 셋팅
      ************************************************************************************************/
      fn_PAddmInfoRegKook () {
        let lv_Vm = this
        let custInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
        let toYear = moment(new Date().toISOString()).format('YYYY')
        let birthYear = toYear - custInfo.age // 출생년도
        let anutBgnYmd = lv_Vm.$bizUtil.ctUtils.fn_CalAnutBgnAge(birthYear) // 국민연금 개시나이
        // 입력값 (만단위) 를 계산을 위해 원단위 처리
        let lv_earnings = this.earnings2 * 10000
        let anutBgnMoney65 = 0
        let anutBgnMoney70 = 0
        let anutBgnMoney75 = 0
        let anutBgnMoney80 = 0
        switch (Number(anutBgnYmd)) {
          case 61:
            anutBgnMoney65 = Math.round(Number(lv_earnings) * Math.pow(1.02, 4), 0)
            break
          case 62:
            anutBgnMoney65 = Math.round(Number(lv_earnings) * Math.pow(1.02, 3), 0)
            break
          case 63:
            anutBgnMoney65 = Math.round(Number(lv_earnings) * Math.pow(1.02, 2), 0)
            break
          case 64:
            anutBgnMoney65 = Math.round(Number(lv_earnings) * Math.pow(1.02, 1), 0)
            break
          default:
            anutBgnMoney65 = Math.round(Number(lv_earnings), 0)
            break
        }

        anutBgnMoney70 = Math.round(Number(lv_earnings) * Math.pow(1.02, 5 + Number(65 - anutBgnYmd)), 0)
        anutBgnMoney75 = Math.round(Number(lv_earnings) * Math.pow(1.02, 10 + Number(65 - anutBgnYmd)), 0)
        anutBgnMoney80 = Math.round(Number(lv_earnings) * Math.pow(1.02, 20 + Number(65 - anutBgnYmd)), 0)

        let mockDataKook = {
          anpsn: [0, 0, anutBgnMoney65 * 12, anutBgnMoney70 * 12, anutBgnMoney75 * 12, anutBgnMoney80 * 12], // 연금연액(6개구간 55,60,65,70,75,80 세 고정 : 국민연금은 65세부터 수령을 기준으로 만들어짐)
          anpsn01: [0, 0, anutBgnMoney65, anutBgnMoney70, anutBgnMoney75, anutBgnMoney80], // 연금월액(6개구간 55,60,65,70,75,80 세 고정 : 국민연금은 65세부터 수령을 기준으로 만들어짐)
          rcvPrd: '종신' // 수령기간(종신 : 99)
        }

        this.getStore('ctStore').dispatch('NPENSREG', {'data': mockDataKook})
        console.log('연금추가 국민연금 확인>>>', this.getStore('ctStore').getters.getNpens)
      },

      /************************************************************************************************
      * Function명 : fn_PAddmInfoRegDang
      * 설명       : 
      ************************************************************************************************/
      fn_PAddmInfoRegDang () {
        let lv_Vm = this
        let custInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
        let toYear = moment(new Date().toISOString()).format('YYYY')
        let params = {}
        params = {
          'chnlCustId': custInfo.chnlCustId,
          'jobNm': this.jobKind, // 직업명
          'jobpsNm': '', // 직위명
          'pubsvCopFgr': 0, // 공무원직급수
          'pubsvOrdyr': 0, // 공무원연차
          'ecmpYr': String(toYear - Number(custInfo.age) + Number(this.startAge)), // 입사년도 : 금년 - 나이 + 입사나이
          'exptRtmAge': Number(this.endAge), // 예상퇴직연령
          'hopRtmAge': Number(this.hopeAge), // 희망퇴직연령
          'rtmAftLvgct': this.hopeAmount, // 은퇴후희망월생활비
          'yincmRegAmt': this.earnings, // 연소득등록금액
          'mntIncmAmt': Number(this.earnings) / 12, // 월소득금액
          'mntLvgct': 0, // 저축투자현황입력 월생활비
          'cmprcRsngRat': '2', // 물가상승률(2% 계산필요)
          'trtrEno': '', // 처리자/로그인 사번 (FC사번->서버에서 처리)
          'mode': this.tab_index === '2' ? 1 : 2 // 입력모드 (1 연금액 계산하기  2 직접입력하기)
        }
        console.log(' 개인추가 정보 params>>>', params)
        let trnstId = 'txTSSCT55I1'
        let auth = 'U'
        window.vue.getStore('progress').dispatch('UPDATE', true)
        this.post(lv_Vm, params, trnstId, auth)
          .then(function (response) {
            //   console.log('fn_PAddmInfoReg response :: ', response)
            window.vue.getStore('progress').dispatch('UPDATE', false)
            let successYn = response.body.trtRslt
            console.log('successYn>>>', response.body)
            if (parseInt(successYn) === 1) {
              lv_Vm.getStore('ctStore').dispatch('MEMBERINFOREG', {'data': params}) // 개인정보 변경 후 Vuex에 저장
              console.log('getMeminfoData>>>', lv_Vm.getStore('ctStore').getters.getMeminfoData.data)
              //lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('ct')['ECTC028']) // 입력완료 확인
              lv_Vm.$emit('confirmMSPCT305P', true)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('ct')['ECTC025'])
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /************************************************************************************************
      * Function명 : fn_PAddmInfoRegTa
      * 설명       : 타사 예상연금 데이터 셋팅
      ************************************************************************************************/
      fn_PAddmInfoRegTa () {
        let lv_Vm = this
        let custInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
        let otcomAnut = lv_Vm.getStore('ctStore').getters.getOtcomAnut.data === undefined ? [] : lv_Vm.getStore('ctStore').getters.getOtcomAnut.data
        let otcomAnutList = [].concat(otcomAnut)

        // 연금계산기에서 직접입력하기를 통해서 타사연금입력시, 기존에 직접입력하기에서 입력한 타사연금은 삭제하고 새로 넣는다.
        let pensIdx = otcomAnutList.findIndex((item, idx) => { return item.acomtYn === 'N' }) // 기계약 여부 'N' 일 경우 (직접입력 타사)

        if ( pensIdx > -1 ) {
          otcomAnutList.splice(pensIdx, 1)
        }
        // let longAmount = [ 'longAmount' ]
        let smtotPrm = {}
        smtotPrm['longAmount'] = 0
        let alpadTotPrm = {}
        alpadTotPrm['longAmount'] = 0
        // let anpsnFlag = false // 연금액 표시 flag
        // 수령 기간에 65세가 포함되어있지 않으면 연금액 표시 flag = false (연금액 0원 처리)
        // if ( this.endage2 >= 65 && this.endage1 <= 65 ) { anpsnFlag = true }
        let mockDataTa = {
          'insrCoNm': '타사', // '-', // 회사명(타사명)
          'contNo': '', // 계약번호
          'prdtNm': '타사연금', // '-', // 상품명
          'contYmd': '0', // 계약일자 (일자 필요하지 않으면 YYYYMM만 넣자)
          'mnisdNm': custInfo.custNm, // 주피보험자명
          'aisdNm': '', // 종피보험자명
          'padCylCd': '', // 납입주기코드
          'smtotPrm': smtotPrm, // 보험료
          'alpadTotPrm': alpadTotPrm, // 기납입총보험료
          'anpsn': Number(this.earnings1) * 10000 * 12, // 연금연액
          'anpsn01': Number(this.earnings1) * 10000, // 연금월액
          'rcvPrd': Number( (this.endAge2 - this.endAge1) + 1) + ' 년', // 수령기간 (수령연령 종료 나이 - 수령연령 시작나이 + 1)
          'prpslStatCd': '01', // 제안상태코드 (01:포함 02:제외)
          'acomtYn': 'N', // 기계약 여부
          'otcomAnutBgnAge': Number(this.endAge1), // 타사연금 개시 시작나이
          'otcomAnutEndAge': Number(this.endAge2) // 타사연금 종료나이
        }
        console.log('this.endAge2>>>', this.endAge2)
        console.log('this.endAge1>>>', this.endAge1)
        console.log('mockDataTa>>>', mockDataTa)
        otcomAnutList.push(mockDataTa)
        this.getStore('ctStore').dispatch('OTCOMANUTREG', {'data': otcomAnutList})
        console.log('otcomAnut>>>', this.getStore('ctStore').getters.getOtcomAnut)
      },
      
      /************************************************************************************************
      * Function명 : fn_PAddmInfoRegToei
      * 설명       : 퇴직연금 예상연금 데이터 셋팅
      ************************************************************************************************/
      fn_PAddmInfoRegToei () {
        let anutBgnMoney55 = 0
        let anutBgnMoney60 = 0
        let anutBgnMoney65 = 0
        let anutBgnMoney70 = 0
        let anutBgnMoney75 = 0
        let anutBgnMoney80 = 0
        let exptRtmAge = Number(this.endAge4) // 퇴직연금 수령연령
        // 퇴직연금 월연금액 입력 필드가 만단위 임 (계산을 위해 원단위 처리 * 10000)
        let exptRtmMoney = Number(this.earnings3 * 10000) // 퇴직연금 연금월액

        for (let i = 55; i <= 80; i += 5) {
          if ((exptRtmAge <= 55)) {
            anutBgnMoney55 = exptRtmMoney
            anutBgnMoney60 = exptRtmMoney
            anutBgnMoney65 = exptRtmMoney
            anutBgnMoney70 = exptRtmMoney
            anutBgnMoney75 = exptRtmMoney
            anutBgnMoney80 = exptRtmMoney
          }
          if ((exptRtmAge >= 56 && exptRtmAge <= 60)) {
            anutBgnMoney55 = 0
            anutBgnMoney60 = exptRtmMoney
            anutBgnMoney65 = exptRtmMoney
            anutBgnMoney70 = exptRtmMoney
            anutBgnMoney75 = exptRtmMoney
            anutBgnMoney80 = exptRtmMoney
          }
          if ((exptRtmAge >= 61 && exptRtmAge <= 65)) {
            anutBgnMoney55 = 0
            anutBgnMoney60 = 0
            anutBgnMoney65 = exptRtmMoney
            anutBgnMoney70 = exptRtmMoney
            anutBgnMoney75 = exptRtmMoney
            anutBgnMoney80 = exptRtmMoney
          }
          if ((exptRtmAge >= 66 && exptRtmAge <= 70)) {
            anutBgnMoney55 = 0
            anutBgnMoney60 = 0
            anutBgnMoney65 = 0
            anutBgnMoney70 = exptRtmMoney
            anutBgnMoney75 = exptRtmMoney
            anutBgnMoney80 = exptRtmMoney
          }
          if ((exptRtmAge >= 71 && exptRtmAge <= 75)) {
            anutBgnMoney55 = 0
            anutBgnMoney60 = 0
            anutBgnMoney65 = 0
            anutBgnMoney70 = 0
            anutBgnMoney75 = exptRtmMoney
            anutBgnMoney80 = exptRtmMoney
          }
          if ((exptRtmAge >= 76 && exptRtmAge <= 80)) {
            anutBgnMoney55 = 0
            anutBgnMoney60 = 0
            anutBgnMoney65 = 0
            anutBgnMoney70 = 0
            anutBgnMoney75 = 0
            anutBgnMoney80 = exptRtmMoney
          }
          if ((exptRtmAge >= 80)) {
            anutBgnMoney55 = 0
            anutBgnMoney60 = 0
            anutBgnMoney65 = 0
            anutBgnMoney70 = 0
            anutBgnMoney75 = 0
            anutBgnMoney80 = 0
          }
        }
        let mockDataToei = {
          anpsn: [anutBgnMoney55 * 12, anutBgnMoney60 * 12, anutBgnMoney65 * 12, anutBgnMoney70 * 12, anutBgnMoney75 * 12, anutBgnMoney80 * 12], // 퇴직연금 연금연액(나이와 상관없이 동일)
          anpsn01: [anutBgnMoney55, anutBgnMoney60, anutBgnMoney65, anutBgnMoney70, anutBgnMoney75, anutBgnMoney80], // 퇴직연금 연금월액(나이와 상관없이 동일)
          rcvPrd: '종신' // 수령기간(99:종신)
        }

        this.getStore('ctStore').dispatch('RTPSREG', {'data': mockDataToei})
        console.log('퇴직연금 스토어 등록 결과>>>', this.getStore('ctStore').getters.getRtps)
      },
    },
  };
</script>
<style scoped>
</style>